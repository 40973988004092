import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Comment]';

export const commentActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
  REMOVE_FAILED: `${ACTION_PREFIX} Remove Failed`,
};

export class CommentAction {
  static load(): Action {
    return {
      type: commentActionType.LOAD,
    };
  }

  static loadSuccess(comment): Action {
    return {
      type: commentActionType.LOAD_SUCCESS,
      payload: comment,
    };
  }

  static loadFailed(err): Action {
    return {
      type: commentActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(newsItemId, comment): Action {
    return {
      type: commentActionType.ADD,
      payload: {
        newsItemId,
        comment,
      },
    };
  }

  static addSuccess(newsItemId, response): Action {
    return {
      type: commentActionType.ADD_SUCCESS,
      payload: {
        newsItemId,
        response,
      },
    };
  }

  static addFailed(newsItemId, err): Action {
    return {
      type: commentActionType.ADD_FAILED,
      payload: {
        newsItemId,
        err,
      },
    };
  }

  static update(newsItemId, comment): Action {
    return {
      type: commentActionType.UPDATE,
      payload: {
        newsItemId,
        comment,
      },
    };
  }

  static updateSuccess(newsItemId, response): Action {
    return {
      type: commentActionType.UPDATE_SUCCESS,
      payload: {
        newsItemId,
        response,
      },
    };
  }

  static updateFailed(newsItemId, err): Action {
    return {
      type: commentActionType.UPDATE_FAILED,
      payload: {
        newsItemId,
        err,
      },
    };
  }

  static fetch(id: any) {
    return {
      type: commentActionType.FETCH,
      payload: id,
    };
  }

  static fetchSuccess(response) {
    return {
      type: commentActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  static fetchFailed(id: string, err) {
    return {
      type: commentActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  static remove(newsItemId, comment) {
    return {
      type: commentActionType.REMOVE,
      payload: {
        newsItemId,
        comment,
      },
    };
  }

  static removeSuccess(newsItemId, commentId) {
    return {
      type: commentActionType.REMOVE_SUCCESS,
      payload: {
        newsItemId,
        commentId,
      },
    };
  }

  static removeFailed(newsItemId: string, err) {
    return {
      type: commentActionType.REMOVE_FAILED,
      payload: {
        newsItemId,
        err,
      },
    };
  }
}
