import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import { DepartmentService } from '../department/department.service';
import { HolidayGroupsActions } from './holiday-groups.action';
import { HolidayGroupsApi } from './holiday-groups.api';
import { HolidayGroupModel } from './holiday-groups.model';

const holidayGroupsCache = new Map<
  string,
  {
    holidayGroups: HolidayGroupModel[];
  }
>();

@Injectable()
export class HolidayGroupsEffect {
  public addRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidayGroupsActions.addAndLinkDepartmentsRequest),
      exhaustMap(({ holidayGroup, departmentIds }) =>
        this.api.add(holidayGroup).pipe(
          switchMap((response) => {
            if (!departmentIds.length) {
              return of(response);
            }
            const departments = departmentIds.map((departmentId) => ({
              id: departmentId,
              holiday_group_id: response.id,
            }));

            return this.departmentService.batchUpdate(departments).pipe(map(() => response));
          }),
          switchMap((response) => of(HolidayGroupsActions.addAndLinkDepartmentsSuccess({ holidayGroup: response }))),
          catchError(() => of(HolidayGroupsActions.addAndLinkDepartmentsFailed())),
        ),
      ),
    ),
  );

  public addSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HolidayGroupsActions.addAndLinkDepartmentsSuccess),
        tap(({ holidayGroup }) => {
          // needed for closing the outlet first before navigating to the new holiday group
          this.router.navigate(['', { outlets: { modal: null } }]).then(() => {
            this.router.navigate(['/account/settings/modules/employees/holiday-groups', holidayGroup.id]);
          });
        }),
      ),
    { dispatch: false },
  );

  public loadAllRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidayGroupsActions.loadAllRequest),
      switchMap((action) => {
        const serialAction = JSON.stringify(action);
        if (!holidayGroupsCache.has(serialAction)) {
          return this.api.loadAll().pipe(
            map((holidayGroups) => {
              holidayGroupsCache.set(serialAction, { holidayGroups });
              return HolidayGroupsActions.loadAllSuccess({ holidayGroups });
            }),
            catchError(() => of(HolidayGroupsActions.loadAllFailed())),
          );
        }
        // not loading in anything extra in the case of a cache hit
        return of(HolidayGroupsActions.loadAllSuccess({ holidayGroups: [] }));
      }),
    ),
  );

  public deleteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidayGroupsActions.deleteRequest),
      switchMap(({ groupId }) =>
        this.api.delete(groupId).pipe(
          map(() => HolidayGroupsActions.deleteSuccess({ groupId })),
          catchError(() => of(HolidayGroupsActions.deleteFailed({ groupId }))),
        ),
      ),
    ),
  );

  public editRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HolidayGroupsActions.editAndLinkDepartmentRequest),
      exhaustMap(({ holidayGroup, departments }) =>
        this.api.edit(holidayGroup).pipe(
          switchMap((res) => {
            if (departments.length) {
              return this.departmentService.batchUpdate(departments).pipe(map(() => res));
            }
            return of(res);
          }),
          map((res) => HolidayGroupsActions.editAndLinkDepartmentSuccess({ holidayGroup: res })),
          catchError(() => of(HolidayGroupsActions.editAndLinkDepartmentFailed({ holidayGroupId: holidayGroup.id }))),
        ),
      ),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly api: HolidayGroupsApi,
    private readonly router: Router,
    private readonly departmentService: DepartmentService,
  ) {}
}
