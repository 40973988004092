<div [formGroup]="form" [ngClass]="{ 'has-danger': hasDanger }">
  <dl-select
    [items]="items"
    [config]="{
      hasAllCheckBox: false,
      hasFilter: true,
      hasCollapseExpand: true,
      maxHeight: 450
    }"
    [itemTemplate]="employeeItem"
    [formControlName]="name"
  ></dl-select>

  <control-messages [control]="form.get(name)"></control-messages>

  <ng-template #employeeItem let-item="item">
    <div
      [ngClass]="{
        'select-employee': !item.children && employees[item.value],
        'select-team': item.children && teams[item.value]
      }"
    >
      <div *ngIf="item.children && teams[item.value]; let team">
        <strong>{{ team.name }}</strong>
      </div>

      <div
        class="avatar-item"
        [ngClass]="{ selected: item.checked }"
        *ngIf="!item.children && employees[item.value]; let employee"
        (click)="select(item)"
      >
        <div class="avatar-item__container">
          <sb-avatar [employee]="employee" [size]="30"></sb-avatar>
        </div>

        <div class="avatar-item__details">
          <span class="avatar-item__title">{{ employee.name }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
