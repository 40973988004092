import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { getAccountSubscription } from '../../account/account.service';
import { AppState } from '../../index';
import { getPermissionState } from '../permission/permission.service';
import { getIntegrationAppByTitle } from './integration-app.selector';

@Injectable()
export class IntegrationAppGuard {
  public constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.store.select(getAccountSubscription),
      this.store.select(getIntegrationAppByTitle(route.data && route.data['appTitle'])),
      this.store.select(getPermissionState),
    ]).pipe(
      map(([subscription, integrationApp, permissionState]) => {
        if (!subscription || !integrationApp || !permissionState) {
          return false;
        }
        if (!integrationApp.enabled) {
          this.router.navigate(['/dashboard/my-overview']);
          return false;
        }

        return true;
      }),
    );
  }
}
