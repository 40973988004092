import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { mergeEntities, resetEntities, updateEntitiesById } from '../orm';
import { AbsenteeOptionState } from './absentee-option.model';
import { absenteeOptionActionType } from './absentee-option.action';

const entityType = 'absenteeOption';

const initialState: AbsenteeOptionState = {
  items: [],
  itemsById: {},
};

export function AbsenteeOptionReducer(state: AbsenteeOptionState = initialState, action: Action): AbsenteeOptionState {
  let payload = action.payload;

  switch (action.type) {
    case absenteeOptionActionType.LOAD_SUCCESS:
      let entities = getEntities(action, entityType);
      return resetEntities(state, entities);

    case absenteeOptionActionType.DELETE_SUCCESS:
      return updateEntitiesById(state, { deleted: true }, payload);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}
