import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TeamDataNModel } from './team-data.n.model';
import { ScheduleFilterShowOptions, ScheduleFilterShowTypes } from '../../page-filters/page-filters.model';

export interface TeamDataNState extends EntityState<TeamDataNModel> {
  isLoading?: boolean;
  error?: any;
  minDate: string;
  maxDate: string;
  shiftFilters?: string[];
  showTypes?: ScheduleFilterShowTypes;
  showOptions?: ScheduleFilterShowOptions;
}

export const teamDataNAdapter: EntityAdapter<TeamDataNModel> = createEntityAdapter<TeamDataNModel>();

export const initialState: TeamDataNState = teamDataNAdapter.getInitialState({
  isLoading: false,
  error: null,
  minDate: '',
  maxDate: '',
  shiftFilters: [],
  showTypes: undefined,
  showOptions: undefined,
});
