import { Pipe, PipeTransform } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { toDate, isValid } from 'date-fns';
import { format } from '../shared/date.helper';
import { EmployeeModel } from '../reducers/orm/employee/employee.model';
import { calculateTimelineHeight } from '../+authenticated/+schedule/shared/timeline.service';

const CELL_HEIGHT = 36;

@Pipe({
  name: 'calculateTimesheetDayHeight',
  pure: true,
})
export class CalculateTimesheetDayHeightPipe implements PipeTransform {
  transform(rows, defaultValue: number = CELL_HEIGHT): number {
    if (!rows || rows.length === 0) {
      return CELL_HEIGHT;
    }

    return rows.length * 36;
  }
}
