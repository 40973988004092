import { UnsafeAction as Action } from '../../interfaces';
import { SurchargeState } from './surcharge.model';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { resetEntities, mergeEntities } from '../orm';
import { surchargeActionType } from './surcharge.action';

const entityType = 'surcharge';

const initialState: SurchargeState = {
  items: [],
  itemsById: {},
};

export function SurchargeReducer(state: SurchargeState = initialState, action: Action): SurchargeState {
  let payload = action.payload;

  switch (action.type) {
    case surchargeActionType.LOAD_SUCCESS:
      let entities = getEntities(action, 'surcharge');
      return resetEntities(state, entities);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, 'surcharge');
        return mergeEntities(state, entities);
      }

      return state;
  }
}
