import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SBRadioSelectorModel } from './sb-radio-selector.model';

const CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SBRadioSelectorComponent),
  multi: true,
};

@Component({
  selector: 'sb-radio-selector',
  templateUrl: 'sb-radio-selector.component.html',
  styleUrls: ['sb-radio-selector.component.scss'],
  providers: [CONTROL_VALUE_ACCESSOR],
})
export class SBRadioSelectorComponent implements ControlValueAccessor {
  @Input()
  public label: string;

  @Input()
  public items: SBRadioSelectorModel[] = [];

  value: string;

  onTouched: Function;
  onModelChange: Function;

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  public onChange(event: string) {
    this.onModelChange(event);
  }
}
