import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { BreakRuleSchema, DaylogSchema } from '../../shared/schemas';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';

@Injectable()
export class BreakRuleApi {
  private endpoint = 'break_rules/';

  constructor(private gateway: ApiGateway) {}

  load(): Observable<any> {
    return this.gateway.get(this.endpoint).pipe(
      map((res) => reformatListResponse('BreakRule', res)),
      map((data) => normalize(data, arrayOf(BreakRuleSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  add(breakRuleData): Observable<any> {
    return this.gateway.post(this.endpoint, breakRuleData).pipe(
      map((res) => reformatEntityResponse('BreakRule', res)),
      map((data) => normalize(data, BreakRuleSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  batch(breakRuleData): Observable<any> {
    return this.gateway.post(this.endpoint + 'batch', breakRuleData).pipe(
      map((res) => reformatListResponse('BreakRule', res)),
      map((data) => normalize(data, arrayOf(BreakRuleSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  update(id, data): Observable<any> {
    return this.gateway.put(this.endpoint + id, data).pipe(
      map((res) => reformatEntityResponse('BreakRule', res)),
      map((data) => normalize(data, BreakRuleSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  fetch(breakRuleId): Observable<any> {
    return this.gateway.get(this.endpoint + breakRuleId).pipe(
      map((res) => reformatEntityResponse('BreakRule', res)),
      map((data) => normalize(data, BreakRuleSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  remove(breakRuleId): Observable<any> {
    return this.gateway.delete(this.endpoint + breakRuleId);
  }
}
