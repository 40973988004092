import { UnsafeAction as Action } from '../../interfaces';
import {
  absenceRestrictionModelEntityAdapter,
  AbsenceRestrictionState,
  initialState,
} from './absence-restriction.state';
import { absenceRestrictionActionType } from './absence-restriction.action';
import { customFieldsActionType } from '../custom-fields/custom-fields.action';

export function AbsenceRestrictionReducer(
  state: AbsenceRestrictionState = initialState,
  action: Action
): AbsenceRestrictionState {
  const payload = action.payload;

  switch (action.type) {
    case absenceRestrictionActionType.LOAD:
    case absenceRestrictionActionType.UPDATE:
    case absenceRestrictionActionType.DELETE:
      return { ...state, isLoading: true, error: null };
    case absenceRestrictionActionType.LOAD_SUCCESS:
      return absenceRestrictionModelEntityAdapter.setAll(payload, { ...state, isLoading: false, error: null });
    case absenceRestrictionActionType.LOAD_FAILED:
    case customFieldsActionType.ADD_FAILED:
    case absenceRestrictionActionType.UPDATE_FAILED:
    case absenceRestrictionActionType.DELETE_FAILED:
      return { ...state, isLoading: false, error: payload };
    case absenceRestrictionActionType.ADD_SUCCESS:
      return absenceRestrictionModelEntityAdapter.addOne(payload, { ...state, isLoading: false });
    case absenceRestrictionActionType.DELETE_SUCCESS:
      return absenceRestrictionModelEntityAdapter.removeOne(payload, { ...state, isLoading: false });
    case absenceRestrictionActionType.UPDATE_SUCCESS:
      return absenceRestrictionModelEntityAdapter.updateOne(payload, { ...state, isLoading: false });
    default:
      return state;
  }
}
