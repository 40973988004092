import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { addEntity, mergeEntities, mergeEntity, removeEntity, updateEntitiesByField, updateEntitiesById } from '../orm';
import { DaylogModel, DaylogRequiredData, DaylogState } from './daylog.model';
import { daylogActionType } from './daylog.action';
import { defaultDayLog, getDaylogKey } from './daylog.helper';
import { scheduleActionType } from '../schedule/schedule.action';

const entityType = 'daylog';

const initialState: DaylogState = {
  items: [],
  itemsById: {},
};

export function DaylogReducer(state: DaylogState = initialState, action: Action): DaylogState {
  const payload = action.payload;

  switch (action.type) {
    case daylogActionType.LOAD_SUCCESS:
    case scheduleActionType.LOAD_SCHEDULE_DATASET_SUCCESS:
      const entities = getEntities(action, entityType);
      return mergeEntities(state, entities);

    case daylogActionType.SAVE:
      return saveDaylog(state, payload);

    case daylogActionType.SAVE_FAILED:
      return updateEntitiesById(state, { saving: false }, payload.id);

    case daylogActionType.SAVE_SUCCESS:
      return updateEntitiesByField(
        mergeEntities(state, getEntities(action, entityType)),
        { saving: false },
        getDaylogKey,
        payload.result
      );

    default:
      if (containsEntity(action, entityType)) {
        return mergeEntities(state, getEntities(action, entityType));
      }

      return state;
  }
}

const saveDaylog = (state, daylogData: DaylogRequiredData) => {
  const daylogKey = getDaylogKey(daylogData);
  const exists = !!state.itemsById[daylogKey];
  const mergeData = state.itemsById[daylogKey] || defaultDayLog(daylogData.date, daylogData.department_id);

  const newDayLog = {
    ...mergeData,
    saving: true,
  };

  if (!exists) {
    return addEntity(state, newDayLog, getDaylogKey);
  }

  return mergeEntity(state, newDayLog, getDaylogKey);
};
