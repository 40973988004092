import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../../index';
import { getEntity } from '../orm';
import { DepartmentService, getDepartment, getDepartmentIds, getDepartmentState } from './department.service';

@Injectable()
export class DepartmentExistsGuard {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private departmentService: DepartmentService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.waitForCollectionToLoad().pipe(
      switchMap(() => this.hasItem(route.params['id'])),
      tap((hasItem) => {
        if (!hasItem) {
          this.router.navigate(['/404']);
        }
      }),
    );
  }

  private waitForCollectionToLoad(): Observable<boolean> {
    return this.store.select(getDepartmentIds).pipe(
      map((itemIds) => itemIds.length > 0),
      filter((loaded) => !!loaded),
      take(1),
    );
  }

  private hasItem(itemId: string): Observable<boolean> {
    return this.hasItemInStore(itemId).pipe(
      switchMap((inStore) => {
        if (inStore) {
          return observableOf(inStore);
        }

        return this.hasItemInApi(itemId);
      }),
    );
  }

  private hasItemInStore(itemId: string): Observable<boolean> {
    return this.store.select(getDepartment(itemId)).pipe(
      map((entity) => !!entity),
      take(1),
    );
  }

  private hasItemInApi(itemId: string): Observable<boolean> {
    return this.departmentService.fetch(itemId).pipe(
      map((response) => !!response),
      catchError((err) => observableOf(false)),
    );
  }
}
