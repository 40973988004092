import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { AbsencePolicyModel } from './absence-policy.model';

@Injectable()
export class AbsencePolicyApi {
  private endpoint = 'absence/policies';

  public constructor(private gateway: ApiGateway) {}

  public add(policy: AbsencePolicyModel): Observable<AbsencePolicyModel> {
    return this.gateway.post(this.endpoint, policy);
  }

  public load(policyId: string): Observable<AbsencePolicyModel> {
    return this.gateway.get(`${this.endpoint}/${policyId}`);
  }

  public update(policy: AbsencePolicyModel): Observable<AbsencePolicyModel> {
    return this.gateway.put(`${this.endpoint}/${policy.id}`, policy);
  }

  public updateMany(policies: AbsencePolicyModel[]): Observable<AbsencePolicyModel[]> {
    const requests = policies.map((policy) => this.gateway.put(`${this.endpoint}/${policy.id}`, policy));
    return forkJoin(requests);
  }

  public loadAll(): Observable<AbsencePolicyModel[]> {
    return this.gateway.get(`${this.endpoint}`);
  }

  public delete(policyId: string): Observable<void> {
    return this.gateway.delete(`${this.endpoint}/${policyId}`);
  }
}
