import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SubscriptionModel } from '../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { getAccountSubscription } from '../../reducers/account/account.service';
import { AppState } from './../../reducers/index';
import { hasReachedQuota } from './subscription-quota.directive';

@Injectable()
export class SubscriptionQuotaGuard {
  public constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(getAccountSubscription),
      map((accountSubscription: SubscriptionModel) => {
        if (!route.data || !route.data['quotaType']) {
          return false;
        }

        const hasAccess = !hasReachedQuota(route.data['quotaType'], accountSubscription);

        if (!hasAccess) {
          this.router.navigate(['/dashboard/my-overview']);
        }

        return true;
      }),
    );
  }
}
