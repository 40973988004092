import { UnsafeAction as Action } from '../../interfaces';
import { SkillGroupState } from './skill-group.model';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { resetEntities, mergeEntities, updateEntitiesById } from '../orm';
import { SkillGroupActionType } from './skill-group.action';

const entityType = 'skillGroup';

const initialState: SkillGroupState = {
  items: [],
  itemsById: {},
};

export function SkillGroupReducer(state: SkillGroupState = initialState, action: Action): SkillGroupState {
  let payload = action.payload;

  switch (action.type) {
    case SkillGroupActionType.LOAD_SUCCESS:
      let entities = getEntities(action, entityType);
      return resetEntities(state, entities);

    case SkillGroupActionType.DELETE:
      return updateEntitiesById(state, { loading: true }, payload);

    case SkillGroupActionType.DELETE_FAILED:
      return updateEntitiesById(state, { loading: false }, payload.id);

    case SkillGroupActionType.DELETE_SUCCESS:
      return updateEntitiesById(state, { loading: false, deleted: true }, payload);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}
