import filter from 'lodash-es/filter';

import { format, parseDate } from '../../../shared/date.helper';
import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { mergeEntities, removeEntities, removeEntity, resetEntities } from '../orm';
import { holidayActionType } from './holiday.action';
import { HolidayState } from './holiday.model';

const entityType = 'holiday';

const initialState: HolidayState = {
  items: [],
  itemsById: {},
};

export function HolidayReducer(state: HolidayState = initialState, action: Action): HolidayState {
  const payload = action.payload;

  switch (action.type) {
    case holidayActionType.LOAD_SUCCESS: {
      const entities = getEntities(action, 'holiday');
      return resetEntities(state, entities);
    }

    case holidayActionType.REMOVE: {
      return removeEntity(state, payload);
    }

    case holidayActionType.IMPORT_SELECTED_SUCCESS: {
      const entities = payload.holidays.entities['holiday'];

      const ids = getIdsInYearAndHolidayGroup(payload.year, payload.holidayGroupId, state);
      return mergeEntities(removeEntities(state, ids), entities);
    }

    default:
      if (containsEntity(action, entityType)) {
        const entities = getEntities(action, 'holiday');
        return mergeEntities(state, entities);
      }

      return state;
  }
}

function getIdsInYear(year, state: HolidayState) {
  const holidays = filter(state.itemsById, (holiday) => format(parseDate(holiday.date), 'yyyy') === year);

  return holidays.map((holiday) => holiday.id);
}

function getIdsInYearAndHolidayGroup(year: string, holidayGroupId: string, state: HolidayState): string[] {
  return state.items.reduce((acc: string[], curr) => {
    const holiday = state.itemsById[curr];
    if (holiday && format(parseDate(holiday.date), 'yyyy') === year && holiday.holiday_group_id === holidayGroupId) {
      acc.push(holiday.id);
    }
    return acc;
  }, []);
}
