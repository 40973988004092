export interface PageParamsState {
  schedule: SchedulePageState;
  timesheet: TimesheetPageState;
  scheduleViewOpen: boolean;
}

export interface SchedulePageState {
  period: string;
  mode: string;
  date: string;
}

export interface TimesheetPageState {
  period: string;
  mode: string;
  date: string;
}

export enum PageStateType {
  SCHEDULE,
  TIMESHEET,
}
