import { createAction, props } from '@ngrx/store';

import { DepartmentModel } from '../department/department.model';
import { DepartmentVariationSection, DepartmentVariationSettingModel } from './department-variation.model';

const ACTION_PREFIX = '[Department variation]';

export const departmentVariationPutRequest = createAction(
  `${ACTION_PREFIX} Put Request`,
  props<{
    departmentVariation: Partial<DepartmentVariationSettingModel>;
    departmentId: string;
    departmentSection: DepartmentVariationSection;
  }>(),
);

export const departmentVariationPutSuccess = createAction(
  `${ACTION_PREFIX} Put Success`,
  props<{
    departmentVariation: DepartmentVariationSettingModel;
    departmentsWithoutVariations?: DepartmentModel[];
  }>(),
);

export const departmentVariationPutFailed = createAction(`${ACTION_PREFIX} Put Failed`);

export const departmentVariationDeleteRequest = createAction(
  `${ACTION_PREFIX} Delete Request`,
  props<{
    section: DepartmentVariationSection;
    departmentId: string;
  }>(),
);

export const departmentVariationDeleteSuccess = createAction(
  `${ACTION_PREFIX} Delete Success`,
  props<{ departmentId: string }>(),
);

export const departmentVariationDeleteFailed = createAction(`${ACTION_PREFIX} Delete Failed`);
export const departmentVariationLoadRequest = createAction(
  `${ACTION_PREFIX} Load Request`,
  props<{ section: DepartmentVariationSection }>(),
);

export const departmentVariationLoadSuccess = createAction(
  `${ACTION_PREFIX} Load Success`,
  props<{
    departmentVariations: DepartmentVariationSettingModel[];
  }>(),
);

export const departmentVariationLoadFailed = createAction(
  `${ACTION_PREFIX} Load Failed`,
  props<{ section: DepartmentVariationSection }>(),
);
