import groupBy from 'lodash-es/groupBy';
import isString from 'lodash-es/isString';
import omit from 'lodash-es/omit';

import { SCHEDULE_ROW_HEIGHT } from '../../../+authenticated/+schedule/employee/schedule-employee-n-period/schedule-constants';
import { getDayListWithoutTotals } from '../../../+authenticated/+schedule/employee/schedule-employee-period/schedule-employee-period.helper';
import { parseDate } from '../../../shared/date.helper';
import { UnsafeAction as Action } from '../../interfaces';
import { AbsenceActionType } from '../../orm/absence/absence.action';
import { eventActionType } from '../../orm/event/event.action';
import { EventModel } from '../../orm/event/event.model';
import { exchangeActionType } from '../../orm/exchange/exchange.action';
import { openShiftActionType } from '../../orm/open-shift/open-shift.action';
import { requiredShiftActionType } from '../../orm/required-shift/required-shift.action';
import { RequiredShiftModel } from '../../orm/required-shift/required-shift.model';
import { scheduleActionType } from '../../orm/schedule/schedule.action';
import { ScheduleModel } from '../../orm/schedule/schedule.model';
import { pageFilterActionType } from '../../page-filters/page-filters.action';
import { PageFiltersState } from '../../page-filters/page-filters.model';
import { convertArrayToObject, getArrayFromActionPayload, PayloadKeyType } from '../../shared/entity.helper';
import { calculateSingleItemRowHeight } from '../schedule-shared.helper';
import {
  addRequiredShift,
  addRequiredShifts,
  addSchedules,
  exchangeAccepted,
  getFilteredSchedules,
  getFilteredShifts,
  removeRequiredShift,
  removeSchedule,
  updateRequiredShift,
} from '../schedule-store.helpers';
import { scheduleNActionType } from '../schedule/schedule.n.action';
import { departmentDataNActionType } from './department-data.n.action';
import { DepartmentDataNModel } from './department-data.n.model';
import { departmentDataNAdapter, DepartmentDataNState, initialState } from './department-data.n.state';

// eslint-disable-next-line max-lines-per-function
export function departmentDataReducer(state: DepartmentDataNState = initialState, action: Action) {
  if (
    action.type !== pageFilterActionType.LOAD_FILTERS &&
    action.type !== scheduleNActionType.SET_PERIOD &&
    !state.minDate
  ) {
    return state;
  }

  const payload = action.payload;
  switch (action.type) {
    case pageFilterActionType.LOAD_FILTERS: {
      const filters: PageFiltersState = isString(payload) ? JSON.parse(payload) : payload;
      return {
        ...state,
        showTypes: filters?.schedule?.showTypes,
        showOptions: filters?.schedule?.showOptions,
      };
    }
    case pageFilterActionType.SET_FILTER:
    case pageFilterActionType.SET_FILTERS: {
      return processScheduleFilters(payload, state);
    }
    case scheduleNActionType.SET_PERIOD: {
      return {
        ...state,
        minDate: action.payload.minDate,
        maxDate: action.payload.maxDate,
      };
    }
    case scheduleNActionType.LOAD_SUCCESS: {
      if (!payload.departmentData) {
        return state;
      }
      const updatedState = departmentDataNAdapter.upsertMany(payload.departmentData, state);
      return reprocessData(updatedState);
    }
    case departmentDataNActionType.LOAD_SUCCESS: {
      const updatedState = departmentDataNAdapter.upsertMany(action.payload, state);
      return reprocessData(updatedState);
    }
    case scheduleActionType.ADD_SUCCESS:
    case openShiftActionType.TAKE_SHIFT_SUCCESS:
    case scheduleActionType.UPDATE_SUCCESS:
    case openShiftActionType.ASSIGN_SUCCESS: {
      const schedules: ScheduleModel[] = Object.values(
        getArrayFromActionPayload(PayloadKeyType.SCHEDULES, action.payload.entities),
      );
      return addSchedules(
        schedules,
        schedules[0]?.department_id ?? schedules[0]?.Department?.id,
        departmentDataNAdapter,
        state,
        createDefaultDepartmentData,
      );
    }
    case scheduleActionType.UPDATE: {
      return removeSchedule(
        payload.occurrenceId,
        payload.scheduleData.department_id,
        departmentDataNAdapter,
        state,
        payload.recurring,
      );
    }
    case requiredShiftActionType.REMOVE: {
      if (payload.optimizedData?.teamId) {
        return state;
      }
      return removeRequiredShift(
        payload.occurrenceId,
        payload.optimizedData.departmentId,
        state,
        departmentDataNAdapter,
        payload.optimizedData.recurring,
      );
    }
    case scheduleActionType.REMOVE: {
      return removeSchedule(payload.occurrenceId, payload.optimizedData.departmentId, departmentDataNAdapter, state);
    }
    case scheduleNActionType.RESET: {
      return departmentDataNAdapter.removeAll({ ...state, minDate: '', maxDate: '' });
    }
    case requiredShiftActionType.ADD: {
      if (payload.requiredShiftData.team_id) {
        return state;
      }
      return addRequiredShift(
        payload.loadingId,
        payload.requiredShiftData,
        true,
        state,
        departmentDataNAdapter,
        createDefaultDepartmentData,
      );
    }
    case requiredShiftActionType.UPDATE: {
      if (payload.requiredShiftData?.team_id) {
        return removeRequiredShift(
          payload.occurrenceId,
          payload.requiredShiftData.department_id,
          state,
          departmentDataNAdapter,
          false,
        );
      }
      return updateRequiredShift(
        payload.loadingId,
        payload.occurrenceId,
        payload.requiredShiftData,
        state,
        true,
        departmentDataNAdapter,
        createDefaultDepartmentData,
      );
    }
    case requiredShiftActionType.ADD_SUCCESS:
    case requiredShiftActionType.UPDATE_SUCCESS: {
      return handleDepartmentRequiredShiftChanges(payload, state);
    }
    case eventActionType.UPDATE_SUCCESS:
    case eventActionType.ADD_SUCCESS: {
      return addEvents(payload, state);
    }
    case eventActionType.REMOVE_SUCCESS: {
      return removeEvent(payload, state);
    }
    case AbsenceActionType.ADD_SUCCESS:
    case AbsenceActionType.UPDATE_SUCCESS: {
      return addAbsences(payload, state);
    }
    case AbsenceActionType.REMOVE: {
      let updatedState = state;
      payload?.departmentIds?.forEach((departmentId: string) => {
        updatedState = removeAbsence(payload.id, departmentId, updatedState);
      });
      return updatedState;
    }

    case exchangeActionType.ACCEPT_INCOMING_SUCCESS:
    case exchangeActionType.ACCEPTED_BY_SUPERVISOR_SUCCESS: {
      const exchange = payload.entities.exchanges[payload.result];
      const departmentId = exchange.NewRoster?.Department?.id;
      return exchangeAccepted(exchange, departmentId, state, departmentDataNAdapter, createDefaultDepartmentData);
    }

    default:
      return state;
  }
}

const processScheduleFilters = (payload, state: DepartmentDataNState): DepartmentDataNState => {
  if (payload.page !== 'schedule') {
    return state;
  }

  let departmentFilteredShiftIds = state.shiftFilters ?? [];
  let departmentFilteredTeamIds = state.teamFilters ?? [];

  if (payload?.filter?.includes('shifts')) {
    const filter = payload.filter.split('.');
    departmentFilteredShiftIds = {
      ...state.shiftFilters,
      [filter[1]]: payload.filterValue,
    };
  }

  if (payload?.filter?.includes('teams')) {
    const filter = payload.filter.split('.');
    departmentFilteredTeamIds = {
      ...state.teamFilters,
      [filter[1]]: payload.filterValue,
    };
  }

  const showTypes = payload?.filters?.showTypes;
  const showOptions = payload?.filters?.showOptions;
  const updatedState = {
    ...state,
    shiftFilters: departmentFilteredShiftIds,
    teamFilters: departmentFilteredTeamIds,
    showTypes: showTypes || state.showTypes,
    showOptions: showOptions || state.showOptions,
  };

  return reprocessData(updatedState);
};

const reprocessData = (state: DepartmentDataNState): DepartmentDataNState => {
  const data = Object.values(state.entities);
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const departmentsToBeUpdated: DepartmentDataNModel[] = [];
  data.forEach((scheduleData: DepartmentDataNModel) => {
    const requiredShifts = getFilteredShifts(scheduleData.requiredShifts, state.shiftFilters);
    const schedules = getFilteredSchedules(scheduleData.schedules, state, {});

    departmentsToBeUpdated.push({
      ...scheduleData,
      filteredRequiredShifts: requiredShifts,
      filteredSchedules: schedules,
      requiredShiftsRowHeight: calculateSingleItemRowHeight(requiredShifts, totals),
    });
  });
  return departmentDataNAdapter.setAll(departmentsToBeUpdated, state);
};

const addAbsences = (payload, state: DepartmentDataNState): DepartmentDataNState => {
  const absences = getArrayFromActionPayload(PayloadKeyType.ABSENCE, payload.entities);
  const updatedAbsences = [];
  absences.forEach((absence) => {
    const departmentIds = [...new Set(Object.values(absence.AbsenteeDay).map((day: any) => day.department_id))];
    departmentIds.forEach((departmentId: string) => {
      updatedAbsences.push({ ...absence, departmentId });
    });
  });

  const groupedAbsences = groupBy(updatedAbsences, 'departmentId');
  let updatedState = state;
  for (const [key, value] of Object.entries(groupedAbsences)) {
    const department: DepartmentDataNModel = state.entities[key];
    const updatedData: DepartmentDataNModel = {
      ...department,
      id: key,
      absence: {
        ...department?.absence,
        ...convertArrayToObject('id', value),
      },
    };
    updatedState = departmentDataNAdapter.setOne(updatedData, state);
  }

  return updatedState;
};

const removeAbsence = (id: string, departmentId: string, state: DepartmentDataNState): DepartmentDataNState => {
  const department: DepartmentDataNModel = state.entities[departmentId];

  if (!department) {
    return;
  }

  const updatedData: DepartmentDataNModel = {
    ...department,
    absence: {
      ...omit(department.absence, [id]),
    },
  };

  return departmentDataNAdapter.setOne(updatedData, state);
};

const handleDepartmentRequiredShiftChanges = (payload, state: DepartmentDataNState) => {
  const requiredShifts: RequiredShiftModel[] = getArrayFromActionPayload(
    PayloadKeyType.REQUIRED_SHIFTS,
    payload.entities,
  );

  if (requiredShifts[0].team_id) {
    return state;
  }

  let updatedState = state;
  if (payload.loadingId) {
    updatedState = <DepartmentDataNState>(
      removeRequiredShift(
        payload.loadingId,
        requiredShifts[0].department_id,
        state,
        departmentDataNAdapter,
        payload.recurring,
      )
    );
  }

  return addRequiredShifts(
    requiredShifts[0].department_id,
    requiredShifts,
    updatedState,
    departmentDataNAdapter,
    createDefaultDepartmentData,
  );
};

const addEvents = (payload, state: DepartmentDataNState): DepartmentDataNState => {
  const events: EventModel[] = getArrayFromActionPayload(PayloadKeyType.EVENTS, payload.entities);
  if (!events?.length) {
    return state;
  }
  const department: DepartmentDataNModel = state.entities[events[0].department_id];
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const updatedData: DepartmentDataNModel = {
    ...department,
    id: events[0].department_id,
    events: {
      ...department?.events,
      ...convertArrayToObject('id', events),
    },
  };
  updatedData.eventsRowHeight = calculateSingleItemRowHeight(updatedData.events, totals);

  return departmentDataNAdapter.setOne(updatedData, state);
};

const removeEvent = (payload, state: DepartmentDataNState): DepartmentDataNState => {
  const department: DepartmentDataNModel = state.entities[payload.departmentId];
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  if (!department) {
    return;
  }

  const event = department.events[payload.id];

  if (!event) {
    return;
  }

  let idsToDelete = [payload.id];
  if (event.sequence_id && payload.sequence) {
    idsToDelete = getEventIdsToRemoveForSequence(event, Object.values(department.events ?? {}));
  }

  const updatedData: DepartmentDataNModel = {
    ...department,
    events: {
      ...omit(department.events, idsToDelete),
    },
  };

  updatedData.eventsRowHeight = calculateSingleItemRowHeight(updatedData.events, totals);

  return departmentDataNAdapter.setOne(updatedData, state);
};

const getEventIdsToRemoveForSequence = (originalEvent: EventModel, events: EventModel[]): string[] =>
  events
    .filter((event: EventModel) => {
      if (event.date < originalEvent.date) {
        return false;
      }
      return event.sequence_id === originalEvent.sequence_id;
    })
    .map((event) => event.id);

export const createDefaultDepartmentData = (departmentId: string): DepartmentDataNModel => ({
  id: departmentId,
  requiredShifts: {},
  filteredRequiredShifts: [],
  schedules: {},
  filteredSchedules: [],
  events: {},
  eventsRowHeight: SCHEDULE_ROW_HEIGHT,
  requiredShiftsRowHeight: SCHEDULE_ROW_HEIGHT,
});
