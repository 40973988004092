import mapValues from 'lodash-es/mapValues';

import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { locationActionType } from '../location/location.action';
import { mergeEntities, removeEntity, updateEntitiesById } from '../orm';
import { teamActionType } from './team.action';
import { TeamModel, TeamState } from './team.model';

const entityType = 'teams';

const initialState: TeamState = {
  items: [],
  itemsById: {},
};

export function TeamReducer(state: TeamState = initialState, action: Action): TeamState {
  const payload = action.payload;
  switch (action.type) {
    case locationActionType.LOAD_SUCCESS: {
      const entities = getEntities(action, entityType);
      const mappedEntities = mapValues(entities, (team) => mapTeams(team));
      return mergeEntities(state, mappedEntities);
    }

    case teamActionType.REMOVE: {
      return updateEntitiesById(state, { deleted: true }, payload);
    }

    case teamActionType.REMOVE_SUCCESS: {
      return removeEntity(state, payload);
    }

    case teamActionType.REMOVE_FAILED: {
      return updateEntitiesById(state, { deleted: false }, payload.id);
    }

    default:
      if (containsEntity(action, entityType)) {
        const entities = getEntities(action, entityType);
        const mappedEntities = mapValues(entities, (team) => mapTeams(team));
        return mergeEntities(state, mappedEntities);
      }

      return state;
  }
}

const mapTeams = (team: TeamModel) => {
  if (!team.hasOwnProperty('type')) {
    const type = team.hidden ? 'hidden' : 'default';
    return { ...team, type };
  }
  return team;
};
