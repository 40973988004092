import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EmployeeModel } from '../../reducers/orm/employee/employee.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers/index';
import { getEmployeeEntities } from '../../reducers/orm/employee/employee.service';
import { SelectComponent } from '../select/select.component';
import { UntypedFormGroup } from '@angular/forms';
import { TeamModel } from '../../reducers/orm/team/team.model';
import { getTeamEntities } from '../../reducers/orm/team/team.service';
import { ControlMessages } from '../../forms/control-messages.component';
import { TreeviewItem } from '../ngx-treeview/treeview-item';
import { RouteModalComponent } from '../../+modals/route-modal.component';

@Component({
  selector: 'dl-select-employee',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './select-employee.component.html',
})
export class SelectEmployeeComponent implements OnInit, OnDestroy {
  @ViewChild(SelectComponent, { static: true })
  selectComponent: SelectComponent;
  @ViewChild(ControlMessages, { static: true })
  controlMessages: ControlMessages;

  @Input()
  items: TreeviewItem[];
  @Input()
  name: string;
  @Input()
  form: UntypedFormGroup;

  public employees: { [id: string]: EmployeeModel } = {};
  private employeesSubscription;

  public teams: { [id: string]: TeamModel } = {};
  private teamsSubscription;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.employeesSubscription = this.store.select(getEmployeeEntities).subscribe((employees) => {
      this.employees = employees;
    });

    this.teamsSubscription = this.store.select(getTeamEntities).subscribe((teams) => (this.teams = teams));
  }

  ngOnDestroy() {
    this.employeesSubscription.unsubscribe();
    this.teamsSubscription.unsubscribe();
  }

  get hasDanger() {
    return this.controlMessages.showError;
  }

  select(item: TreeviewItem) {
    this.selectComponent.select(item);
  }
}
