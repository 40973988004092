<div *ngIf="showBanner" class="smartbanner smartbanner--${platform} js_smartbanner">
  <a (click)="closeBanner()" class="smartbanner__exit js_smartbanner__exit"></a>
  <div class="smartbanner__icon" [style.backgroundImage]="'url(' + icon + ')'"></div>
  <div class="smartbanner__info">
    <div>
      <div class="smartbanner__info__title">{{ title }}</div>
      <div class="smartbanner__info__author">{{ company }}</div>
      <div class="smartbanner__info__price" translate>Free</div>
    </div>
  </div>
  <a href="{{ url }}" target="_blank" class="smartbanner__button"
    ><span class="smartbanner__button__label" translate>View app</span></a
  >
</div>
