import fastDeepEqual from 'fast-deep-equal';
import omitBy from 'lodash-es/omitBy';
import u from 'updeep';

import { ObjectMap } from './index';
import { SharedModelState } from './shared.model.state';

export function getEntities<T extends SharedModelState<any>>(state: T) {
  return state.itemsById;
}

export function getEntity<T extends SharedModelState<any>>(state: T, entityId) {
  const entities = getEntities(state);
  return entities[entityId];
}

type ExtractValueFn = (entity: any) => string;
type ExtractValue = string | ExtractValueFn;

export const extractValue = (entity, extract: ExtractValue) => {
  if (typeof extract === 'string') {
    return entity[extract];
  }
  return extract(entity);
};

/**
 * Reset entire entity state
 * @param state
 * @param entities
 * @param ids
 * @returns {any}
 */
export function resetEntities<T extends SharedModelState<any>>(state: T, entities, ids?: string[]): T {
  entities = entities || {};

  if (ids === undefined) {
    ids = Object.keys(entities);
  }

  return decideState(
    state,
    u(
      // @ts-expect-error types of updeep are horribly broken [SHB-6171] Fixed by getting rid of updeep altogether rid of updeep altogether.
      {
        items: u.constant(ids),
        itemsById: u.constant(entities),
      },
      state,
    ),
  );
}

function _add(...newItems: string[]) {
  return (items) => [...items, ...newItems];
}

function _remove(...removeItems: string[]) {
  return u.reject((id) => removeItems.indexOf(id) !== -1);
}

export function addEntity<T extends SharedModelState<any>>(state: T, entity, idField: ExtractValue = 'id'): T {
  const entityId = extractValue(entity, idField);

  // @ts-expect-error types of updeep are horribly broken [SHB-6171] Fixed by getting rid of updeep altogether rid of updeep altogether.
  return decideState(
    state,
    // @ts-expect-error types of updeep are horribly broken [SHB-6171] Fixed by getting rid of updeep altogether rid of updeep altogether.
    u(
      {
        items: _add(entityId),
        itemsById: {
          [entityId]: entity,
        },
      },
      state,
    ),
  );
}

export function mergeEntity<T extends SharedModelState<any>>(state: T, entity, idField: ExtractValue = 'id'): T {
  if (!entity) {
    return state;
  }

  const entityId = extractValue(entity, idField);

  return decideState(state, u.updateIn('itemsById.' + entityId, entity, state));
}

export function mergeEntities<T extends SharedModelState<Y>, Y>(state: T, entities: ObjectMap<Y>): T {
  if (!entities) {
    return state;
  }

  // when a relation has an id of null ( usually due to a bug in the backend).. skip it
  const entitiesToMerge = omitBy(entities, (entity, id) => id === null || id === 'null');

  //array of new ids
  const newIds = Object.keys(entitiesToMerge).filter((id) => state.items.indexOf(id) === -1);

  return decideState(
    state,
    // @ts-expect-error types of updeep are horribly broken [SHB-6171] Fixed by getting rid of updeep altogether rid of updeep altogether.
    u(
      {
        items: _add(...newIds),
        itemsById: entitiesToMerge,
      },
      state,
    ),
  );
}

export function replaceEntity<T extends SharedModelState<any>>(state: T, entity, idField: ExtractValue = 'id'): T {
  if (!entity) {
    return state;
  }

  const entityId = extractValue(entity, idField);

  return decideState(state, u.updateIn('itemsById.' + entityId, u.constant(entity), state));
}

export function removeEntity<T extends SharedModelState<any>>(state: T, entityId): T {
  // @ts-expect-error types of updeep are horribly broken [SHB-6171] Fixed by getting rid of updeep altogether rid of updeep altogether.
  return decideState(
    state,
    // @ts-expect-error types of updeep are horribly broken [SHB-6171] Fixed by getting rid of updeep altogether rid of updeep altogether.
    u(
      {
        items: _remove(entityId),
        itemsById: u.omit(entityId),
      },
      state,
    ),
  );
}

export function removeEntities<T extends SharedModelState<any>>(state: T, entityIds): T {
  // @ts-expect-error types of updeep are horribly broken [SHB-6171] Fixed by getting rid of updeep altogether rid of updeep altogether.
  return decideState(
    state,
    // @ts-expect-error types of updeep are horribly broken [SHB-6171] Fixed by getting rid of updeep altogether rid of updeep altogether.
    u(
      {
        items: _remove(...entityIds),
        itemsById: u.omit(entityIds),
      },
      state,
    ),
  );
}

export function addChildId<T extends SharedModelState<any>>(state: T, entityId, child, childId): T {
  return u.updateIn('itemsById.' + entityId + '.' + child, _add(childId), state);
}

export function updateBelongsTo<T extends SharedModelState<any>>(state: T, belongsToId, child, childId): T {
  return decideState(
    state,
    u(
      // @ts-expect-error types of updeep are horribly broken [SHB-6171] Fixed by getting rid of updeep altogether rid of updeep altogether.
      {
        itemsById: u.map((entity) => {
          //update belongsTo entity
          if (entity.id === belongsToId) {
            if (entity[child].indexOf(childId) === -1) {
              return u.updateIn(child, _add(childId), entity);
            }
            return entity;
          }

          //remove from all other entities
          if (entity[child].indexOf(childId) !== -1) {
            return u.updateIn(child, u.reject(_matchValue(childId)), entity);
          }

          return entity;
        }),
      },
      state,
    ),
  );
}

function _matchValue(valueToMatch) {
  return (value) => value === valueToMatch;
}

export function updateEntitiesById<T extends SharedModelState<any>>(
  state: T,
  updateWith: {},
  ...entityIds: string[]
): T {
  return decideState(state, updateEntitiesByField(state, updateWith, 'id', ...entityIds));
}

export function updateEntitiesByField<T extends SharedModelState<any>>(
  state: T,
  updateWith: {},
  checkField: ExtractValue,
  ...entityIds: string[]
): T {
  return decideState(
    state,
    u(
      // @ts-expect-error types of updeep are horribly broken [SHB-6171] Fixed by getting rid of updeep altogether rid of updeep altogether.
      {
        itemsById: u.map((entity) => {
          const checkFieldValue = extractValue(entity, checkField);

          if (checkFieldValue === undefined || entityIds.indexOf(checkFieldValue) === -1) {
            return entity;
          }

          return u(updateWith, entity);
        }),
      },
      state,
    ),
  );
}

function decideState<T extends SharedModelState<any>>(prevState: T, nextState: T): T {
  if (fastDeepEqual(prevState, nextState)) {
    return prevState;
  } else {
    return nextState;
  }
}
