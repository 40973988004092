import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AppState } from '../../index';
import { getEmployeeIds } from './employee.service';

@Injectable()
export class EmployeesLoadedGuard {
  public constructor(private store: Store<AppState>) {}

  public canActivate(): Observable<boolean> | boolean {
    return this.waitForCollectionToLoad();
  }

  private waitForCollectionToLoad(): Observable<boolean> {
    return this.store.select(getEmployeeIds).pipe(
      map((itemIds) => itemIds.length > 0),
      filter((loaded) => !!loaded),
      take(1),
    );
  }
}
