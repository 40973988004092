import { UnsafeAction as Action } from '../../interfaces';
import { initialState, timeOffBalanceAdapter, TimeOffBalanceState } from './time-off-balance.state';
import { timeOffActionType } from './time-off-balance.action';
import { getArrayFromActionPayload, PayloadKeyType } from '../../shared/entity.helper';

export function TimeOffBalanceReducer(state: TimeOffBalanceState = initialState, action: Action): TimeOffBalanceState {
  const payload = action.payload;

  switch (action.type) {
    case timeOffActionType.LOAD:
    case timeOffActionType.ADD:
    case timeOffActionType.UPDATE:
      return { ...state, isLoading: true };
    case timeOffActionType.LOAD_SUCCESS:
      return timeOffBalanceAdapter.setAll(payload, {
        ...state,
        isLoading: false,
      });
    case timeOffActionType.ADD_SUCCESS:
      return timeOffBalanceAdapter.addOne(payload, {
        ...state,
        isLoading: false,
      });
    case timeOffActionType.UPDATE_SUCCESS:
      return timeOffBalanceAdapter.updateOne(
        {
          id: payload.id,
          changes: payload.changes,
        },
        { ...state, isLoading: false }
      );
    case timeOffActionType.ACTIVATE:
    case timeOffActionType.DEACTIVATE:
      return setLoading(payload, state);
    case timeOffActionType.ACTIVATE_SUCCESS:
      return setActiveState(payload, true, state);
    case timeOffActionType.DEACTIVATE_SUCCESS:
      return setActiveState(payload, false, state);
    case timeOffActionType.ADD_FAILED:
    case timeOffActionType.UPDATE_FAILED:
      return { ...state, isLoading: false, error: payload.err };
    default:
      return state;
  }
}

const setActiveState = (balanceId: string, active: boolean, state: TimeOffBalanceState) => timeOffBalanceAdapter.updateOne(
    {
      id: balanceId,
      changes: {
        active,
        loading: false,
      },
    },
    { ...state, isLoading: false }
  );

const setLoading = (balanceId: string, state: TimeOffBalanceState) => timeOffBalanceAdapter.updateOne(
    {
      id: balanceId,
      changes: {
        loading: true,
      },
    },
    { ...state, isLoading: false }
  );
