import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import {
  getEmployeeTeamDepartmentsWithoutFlexpool,
  getPermissionState,
  hasPermission,
} from '../../auth/permission.helper';
import { AppState } from '../../index';

@Injectable()
export class EmployeeDetailGuard {
  public constructor(
    private router: Router,
    private store: Store<AppState>,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return combineLatest([
      this.store.select(getEmployeeTeamDepartmentsWithoutFlexpool),
      this.store.select(getPermissionState),
    ]).pipe(
      first(),
      map(([departments, permissionState]) => {
        if (!departments[route.params.id]?.length) {
          return false;
        }
        return hasPermission(
          {
            permissions: ['View all users'],
            departments: departments[route.params.id],
            userId: route.params.id,
          },
          permissionState,
        );
      }),
      tap((canViewUser) => {
        if (!canViewUser) {
          this.router.navigate(['dashboard/my-overview']);
        }
      }),
    );
  }
}
