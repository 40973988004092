import { UnsafeAction as Action } from '../../interfaces';
import { initialState, kioskAdapter, KioskState } from './kiosk.state';
import { KioskActionType } from './kiosk.action';

export function KioskReducer(state: KioskState = initialState, action: Action): KioskState {
  const payload = action.payload;

  switch (action.type) {
    case KioskActionType.LOAD:
    case KioskActionType.ADD:
    case KioskActionType.UPDATE:
    case KioskActionType.DELETE:
      return { ...state, isLoading: true };
    case KioskActionType.LOAD_SUCCESS:
      return kioskAdapter.setAll(payload, { ...state, isLoading: false, error: null });
    case KioskActionType.ADD_SUCCESS:
      return kioskAdapter.addOne(payload, { ...state, isLoading: false });
    case KioskActionType.UPDATE_SUCCESS:
      return kioskAdapter.updateOne(payload, { ...state, isLoading: false });
    case KioskActionType.DELETE_SUCCESS:
      return kioskAdapter.removeOne(payload, { ...state, isLoading: false });
    case KioskActionType.ADD_FAILED:
    case KioskActionType.UPDATE_FAILED:
    case KioskActionType.DELETE_FAILED:
      return { ...state, isLoading: false, error: payload.err };
    default:
      return state;
  }
}
