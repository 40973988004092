import { UnsafeAction as Action } from '../../interfaces';
import { EventState } from './event.model';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { addEntity, getEntity, mergeEntities, removeEntity, resetEntities } from '../orm';
import { eventActionType } from './event.action';
import { scheduleActionType } from '../schedule/schedule.action';

const entityType = 'events';

const initialState: EventState = {
  items: [],
  itemsById: {},
  loading: false,
};

export function EventReducer(state: EventState = initialState, action: Action): EventState {
  let payload = action.payload;

  switch (action.type) {
    case eventActionType.UPDATE:
      return optimisticHandleUpdate(state, payload);

    case eventActionType.UPDATE_SUCCESS:
      state = removeEntity(state, payload.loadingId);

      return mergeEntities(state, payload.entities[entityType]);

    case eventActionType.LOAD: {
      return {
        ...state,
        loading: true,
      };
    }

    case eventActionType.LOAD_FAILED:
      return {
        ...state,
        loading: false,
      };

    case eventActionType.LOAD_SUCCESS:
    case scheduleActionType.LOAD_SCHEDULE_DATASET_SUCCESS:
      if (!containsEntity(action, entityType)) {
        return {
          ...state,
          loading: false,
        };
      }

      return {
        ...mergeEntities(state, getEntities(action, entityType)),
        loading: false,
      };

    case eventActionType.REMOVE_SUCCESS: {
      return removeEntity(state, payload.id);
    }

    default:
      if (containsEntity(action, entityType)) {
        const entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}

function optimisticHandleUpdate(state, payload) {
  const { eventData, id, loadingId } = payload;

  // Create a new event which is in the loading state.
  let event = getEntity(state, id);
  event = {
    ...event,
    ...eventData,
    id: loadingId,
    loading: true,
  };

  // Finally remove the dragged event
  state = removeEntity(state, id);

  // And add the new 'loading' event.
  return addEntity(state, event, 'id');
}
