import { UnsafeAction as Action } from '../../interfaces';
import {
  initialState,
  requiredShiftFulfillmentAdapter,
  RequiredShiftFulfillmentState,
} from './required-shift-fulfillment.state';
import { requiredShiftFulfillmentActionType } from './required-shift-fulfillment.action';

export function RequiredShiftFulfillmentReducer(
  state: RequiredShiftFulfillmentState = initialState,
  action: Action
): RequiredShiftFulfillmentState {
  const payload = action.payload;

  switch (action.type) {
    case requiredShiftFulfillmentActionType.UPDATE: {
      return {
        ...state,
        isLoading: true,
        error: null,
        timestamp: action.timestamp,
      };
    }
    case requiredShiftFulfillmentActionType.LOAD: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case requiredShiftFulfillmentActionType.UPDATE_SUCCESS: {
      if (state.timestamp && state.timestamp !== action.timestamp) {
        return state;
      }

      return requiredShiftFulfillmentAdapter.upsertMany(payload, { ...state, isLoading: false, error: null });
    }
    case requiredShiftFulfillmentActionType.LOAD_SUCCESS: {
      return requiredShiftFulfillmentAdapter.setAll(payload, { ...state, isLoading: false, error: null });
    }
    case requiredShiftFulfillmentActionType.UPDATE_FAILED:
    case requiredShiftFulfillmentActionType.LOAD_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    default:
      return state;
  }
}
