import { isBefore, isEqual, isValid } from 'date-fns';
import filter from 'lodash-es/filter';
import reduce from 'lodash-es/reduce';

import { format, parseDate, periodFilter } from '../../../shared/date.helper';
import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { addEntity, getEntity, mergeEntities, removeEntities, removeEntity } from '../orm';
import { scheduleActionType } from '../schedule/schedule.action';
import { requiredShiftActionType } from './required-shift.action';
import {
  LoadRequiredShiftsSuccessAction,
  RequiredShiftModel,
  RequiredShiftsLoadRequest,
  RequiredShiftState,
} from './required-shift.model';

const entityType = 'requiredShifts';

const initialState: RequiredShiftState = {
  items: [],
  itemsById: {},
};

export function RequiredShiftReducer(
  state: RequiredShiftState = initialState,
  action: Action | LoadRequiredShiftsSuccessAction,
): RequiredShiftState {
  const payload = action.payload;

  switch (action.type) {
    case requiredShiftActionType.LOAD_SUCCESS:
    case scheduleActionType.LOAD_SCHEDULE_DATASET_SUCCESS:
      return handleLoadSuccess(state, action);

    case requiredShiftActionType.ADD:
      return optimisticHandleAdd(state, payload);

    case requiredShiftActionType.UPDATE:
      return optimisticHandleUpdate(state, payload);

    case requiredShiftActionType.REMOVE:
      return optimisticHandleDelete(state, payload);

    case requiredShiftActionType.UPDATE_SUCCESS:
    case requiredShiftActionType.ADD_SUCCESS: {
      state = removeEntity(state, payload.loadingId);

      return mergeEntities(state, payload.entities[entityType]);
    }

    default:
      if (containsEntity(action, entityType)) {
        return mergeEntities(state, getEntities(action, entityType));
      }

      return state;
  }
}

function optimisticHandleAdd(state, payload) {
  const { requiredShiftData, loadingId } = payload;

  // Create a new open shift which is in the loading state.
  const requiredShift = {
    ...requiredShiftData,
    occurrence_id: loadingId,
    loading: true,
  };

  // And add the new 'loading' schedule.
  return addEntity(state, requiredShift, 'occurrence_id');
}

function optimisticHandleUpdate(state, payload) {
  const { requiredShiftData, occurrenceId, scope, loadingId } = payload;

  // Create a new required shift which is in the loading state.
  const requiredShift = {
    ...getEntity(state, occurrenceId),
    ...requiredShiftData,
    occurrence_id: loadingId,
    loading: true,
  };

  /*
    When 'original' and 'sequence' remove all future occurrences, but
    keep the past ones intact.

    The back-end will respond with the new future occurrences eventually.
  */
  if (scope !== 'occurrence') {
    state = removeFutureOccurrences(state, requiredShift);
  }

  // Finally remove the dragged required shift
  state = removeEntity(state, occurrenceId);

  // And add the new 'loading' required shift.
  return addEntity(state, requiredShift, 'occurrence_id');
}

function optimisticHandleDelete(state, payload) {
  const { occurrenceId, scope } = payload;

  const requiredShift = getEntity(state, occurrenceId);
  if (!requiredShift) {
    return state;
  }

  if (scope !== 'occurrence') {
    return removeFutureOccurrences(state, requiredShift);
  } else {
    return removeEntity(state, occurrenceId);
  }
}

function removeFutureOccurrences(state, requiredShift: RequiredShiftModel) {
  const date = parseDate(requiredShift.date);

  const ids = reduce(
    state.itemsById,
    (ids, requiredShiftCheck: RequiredShiftModel) => {
      const checkDate = parseDate(requiredShiftCheck.date);
      const isSameOrBefore = isValid(checkDate) ? isBefore(date, checkDate) || isEqual(date, checkDate) : false;

      if (requiredShiftCheck.id === requiredShift.id && isSameOrBefore) {
        ids.push(requiredShiftCheck.occurrence_id);
      }
      return ids;
    },
    [],
  );

  return removeEntities(state, ids);
}

/**
 * if schedules are deleted outside of the users tab,
 * we need to remove deleted schedules.
 * We do this by removing everything that matches the requested data
 * after this we merge in the loaded schedules
 * @param state
 * @param {UnsafeAction | LoadSchedulesSuccessAction} action
 * @returns {SharedModelState<any>}
 */
function handleLoadSuccess(state: RequiredShiftState, action: Action | LoadRequiredShiftsSuccessAction) {
  const requestData = action.requestData || ({} as RequiredShiftsLoadRequest);
  const today = format(new Date(), 'yyyy-MM-dd');
  const minDate = requestData.minDate || today;
  const maxDate = requestData.maxDate || today;
  const inPeriod = periodFilter(minDate, maxDate);

  const filterFn = (requiredShift: RequiredShiftModel) => {
    //only remove existing schedule if it's not in the payload
    if (
      action.payload.result &&
      Array.isArray(action.payload.result) &&
      action.payload.result.includes(requiredShift.occurrence_id)
    ) {
      return false;
    }

    //only filter the schedules for the given department
    const departmentId = requiredShift.department_id;
    if (requestData.departmentId && departmentId && departmentId !== requestData.departmentId) {
      return false;
    }

    return inPeriod(requiredShift);
  };

  const removeIds = filter(state.itemsById, filterFn).map(
    (requiredShift: RequiredShiftModel) => requiredShift.occurrence_id,
  );

  return mergeEntities(removeEntities(state, removeIds), getEntities(action, entityType));
}
