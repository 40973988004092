import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { DepartmentVariationSection, DepartmentVariationSettingModel } from './department-variation.model';

@Injectable()
export class DepartmentVariationApi {
  private endpoint = 'departments/';

  public constructor(private gateway: ApiGateway) {}

  public add(
    departmentVariation: Partial<DepartmentVariationSettingModel>,
    departmentId: string,
    departmentSection: DepartmentVariationSection
  ): Observable<DepartmentVariationSettingModel> {
    if (!departmentId) {
      return this.gateway.put(`${this.endpoint}0/settings/${departmentSection}`, departmentVariation);
    }
    return this.gateway.put(`${this.endpoint}${departmentId}/settings/${departmentSection}`, departmentVariation);
  }

  public delete(departmentSection: DepartmentVariationSection, departmentId: string): Observable<void> {
    return this.gateway.delete(`${this.endpoint}${departmentId}/settings/${departmentSection}`);
  }

  public load(departmentSection: DepartmentVariationSection): Observable<DepartmentVariationSettingModel[]> {
    return this.gateway.get(`${this.endpoint}settings/${departmentSection}`);
  }
}
