import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../../index';
import { getLocation, getLocationIds, LocationService } from './location.service';

@Injectable()
export class LocationExistsGuard {
  public constructor(
    private router: Router,
    private store: Store<AppState>,
    private locationService: LocationService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.waitForCollectionToLoad().pipe(
      switchMap(() => this.hasItem(route.params['id'])),
      tap((hasItem) => {
        if (!hasItem) {
          this.router.navigate(['/404']);
        }
      }),
    );
  }

  private waitForCollectionToLoad(): Observable<boolean> {
    return this.store.select(getLocationIds).pipe(
      map((itemIds) => itemIds.length > 0),
      filter((loaded) => !!loaded),
      take(1),
    );
  }

  private hasItem(itemId: string): Observable<boolean> {
    return this.hasItemInStore(itemId).pipe(
      switchMap((inStore) => {
        if (inStore) {
          return observableOf(inStore);
        }

        return this.hasItemInApi(itemId);
      }),
    );
  }

  private hasItemInStore(itemId: string): Observable<boolean> {
    return this.store.select(getLocation(itemId)).pipe(
      map((entity) => !!entity),
      take(1),
    );
  }

  private hasItemInApi(itemId: string): Observable<boolean> {
    return this.locationService.fetch(itemId).pipe(
      map((response) => !!response),
      catchError(() => observableOf(false)),
    );
  }
}
