import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DepartmentService } from './department.service';

@Injectable()
export class DepartmentDeactivationGuard {
  public constructor(
    private departmentService: DepartmentService,
    private router: Router,
  ) {}

  public canActivate(): boolean {
    const hasTargetTeamId = this.departmentService.deactivateFields?.hasTargetTeamId;
    const hasTargetDepartmentId = this.departmentService.deactivateFields?.hasTargetDepartmentId;

    if (hasTargetTeamId || hasTargetDepartmentId) {
      return true; // Allow access to the route
    }

    this.router.navigate(['account/settings/locations']);
    return false;
  }
}
