import { createReducer, on } from '@ngrx/store';

import * as Actions from './absence-policy.action';
import { AbsencePolicyState, adapter, initialState } from './absence-policy.state';

export const reducer = createReducer<AbsencePolicyState>(
  initialState,
  on(
    Actions.absencePolicyAddRequest,
    (state): AbsencePolicyState => ({
      ...state,
      isAdding: true,
    })
  ),
  on(Actions.absencePolicyAddSuccess, (state, { policy }) =>
    adapter.addOne<AbsencePolicyState>(policy, {
      ...state,
      isAdding: false,
    })
  ),
  on(
    Actions.absencePolicyAddFailed,
    (state): AbsencePolicyState => ({
      ...state,
      isAdding: false,
    })
  ),
  on(
    Actions.absencePolicyLoadRequest,
    (state, { policyId }): AbsencePolicyState => ({
      ...state,
      loading: { ...state.loading, [policyId]: true },
    })
  ),
  on(Actions.absencePolicyLoadSuccess, (state, { policy }) =>
    adapter.addOne<AbsencePolicyState>(policy, {
      ...state,
      loading: { ...state.loading, [policy.id]: false },
    })
  ),
  on(
    Actions.absencePolicyLoadFailed,
    (state, { policyId }): AbsencePolicyState => ({
      ...state,
      loading: { ...state.loading, [policyId]: false },
    })
  ),
  on(
    Actions.absencePolicyUpdateRequest,
    (state, { policy }): AbsencePolicyState => ({
      ...state,
      updating: { ...state.updating, [policy.id]: true },
    })
  ),
  on(
    Actions.absencePolicyUpdateConfigurationRequest,
    Actions.absencePolicyRemoveConfigurationRequest,
    (state, { policyId }): AbsencePolicyState => ({
      ...state,
      updating: { ...state.updating, [policyId]: true },
    })
  ),
  on(Actions.absencePolicyUpdateSuccess, (state, { policy }) =>
    adapter.upsertOne<AbsencePolicyState>(policy, {
      ...state,
      updating: { ...state.updating, [policy.id]: false },
    })
  ),
  on(Actions.absencePolicyUpdateManySuccess, (state, { policies }) =>
    adapter.upsertMany<AbsencePolicyState>(policies, {
      ...state,
    })
  ),
  on(
    Actions.absencePolicyUpdateFailed,
    (state, { policyId }): AbsencePolicyState => ({
      ...state,
      updating: { ...state.updating, [policyId]: false },
    })
  ),
  on(Actions.absencePolicyLoadAllRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(Actions.absencePolicyLoadAllSuccess, (state, { policies }) =>
    adapter.addMany(policies, {
      ...state,
      isLoading: false,
    })
  ),
  on(Actions.absencePolicyLoadAllFailed, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(Actions.absencePolicyDeleteSuccess, (state, { policyId }) => adapter.removeOne(policyId, state))
);
