import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { decimalHoursToDurationParts } from '@shiftbase-com/utilities';

@Component({
  selector: 'balance',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<span>{{ sign }}{{ value | decimalToTime }}</span>`,
})
export class BalanceComponent {
  public sign = '';

  @HostBinding('class')
  get balanceClass() {
    if (this.sign === '-') {
      return 'negative-balance';
    }

    if (this.sign === '+') {
      return 'positive-balance';
    }

    return '';
  }

  private _value: number;

  @Input()
  set value(value) {
    if (!value) {
      value = 0;
    }

    const time = decimalHoursToDurationParts(value);
    this.sign = time.sign;

    this._value = Math.abs(value);
  }

  get value() {
    return this._value;
  }
}
