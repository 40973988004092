import { TrackingEffect } from '@app/services/tracking.effect';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { ReportSidebarGuard } from '../+authenticated/+reports/shared/report-sidebar/report-sidebar.guard';
import { SubscriptionService } from '../+authenticated/+reports/shared/subscriptions/subscription.service';
import { AccountApi } from './account/account.api';
import { AccountEffect } from './account/account.effect';
import { AccountState } from './account/account.model';
import { AccountReducer } from './account/account.reducer';
import { AccountService } from './account/account.service';
import { AuthApi } from './auth/auth.api';
import { AuthEffect } from './auth/auth.effect';
import { AuthState } from './auth/auth.model';
import { AuthReducer } from './auth/auth.reducer';
import { AuthService } from './auth/auth.service';
import { CurrencyApi } from './currency/currency.api';
import { CurrencyState } from './currency/currency.model';
import { CurrencyReducer } from './currency/currency.reducer';
import { MessageEffect } from './message/message.effect';
import { Message } from './message/message.model';
import { MessageReducer } from './message/message.reducer';
import { MessageService } from './message/message.service';
import { MfaApi } from './mfa/mfa.api';
import { MfaState } from './mfa/mfa.model';
import { MfaReducer } from './mfa/mfa.reducer';
import { MfaService } from './mfa/mfa.service';
import { AbsenceExistsGuard } from './orm/absence/absence-exists.guard';
import { AvailabilityExistsGuard } from './orm/availability/availability-exists.guard';
import { ContractTypeExistsGuard } from './orm/contract-type/contract-type-exists.guard';
import { CorrectionExistsGuard } from './orm/correction/correction-exists.guard';
import { DepartmentExistsGuard } from './orm/department/department-exists.guard';
import { EmployeeDetailGuard } from './orm/employee/employee-detail.guard';
import { EmployeesLoadedGuard } from './orm/employee/employees-loaded.guard';
import { EventLoadGuard } from './orm/event/event-load.guard';
import { ExchangeLoadGuard } from './orm/exchange/exchange-load.guard';
import { HolidayExistsGuard } from './orm/holiday/holiday-exists.guard';
import { ormEffects, OrmProviders, OrmState } from './orm/index';
import { LocationExistsGuard } from './orm/location/location-exists.guard';
import { OpenShiftExistsGuard } from './orm/open-shift/open-shift-exists.quard';
import { PermissionGroupExistsGuard } from './orm/permission-group/permission-group-exists.guard';
import { RateCardExistsGuard } from './orm/rate-card/rate-card-exists.guard';
import { RequiredShiftExistsGuard } from './orm/required-shift/required-shift-exists.quard';
import { ScheduleExchangeLoadGuard } from './orm/schedule/schedule-exchange-load.guard';
import { ScheduleExistsGuard } from './orm/schedule/schedule-exists.guard';
import { ScheduleUpdateEffect } from './orm/schedule/schedule-update.effect';
import { SkillGroupExistsGuard } from './orm/skill-group/skill-group-exists.guard';
import { SkillExistsGuard } from './orm/skill/skill-exists.guard';
import { SurchargeExistsGuard } from './orm/surcharge/surcharge-exists.guard';
import { TimesheetConflictEffect } from './orm/timesheet-conflict/timesheet-conflict.effect';
import { TimesheetExistsGuard } from './orm/timesheet/timesheet-exists.guard';
import { PageFiltersEffect } from './page-filters/page-filters.effect';
import { PageFiltersState } from './page-filters/page-filters.model';
import { PageFiltersReducer } from './page-filters/page-filters.reducer';
import { PageFiltersService } from './page-filters/page-filters.service';
import { PageParamsState } from './page-params-state/page-params-state.model';
import { PageParamsReducer } from './page-params-state/page-params.reducer';
import { PageParamsService } from './page-params-state/page-params.service';
import { reset } from './reset.reducer';
import { SelectedDepartmentsReducer } from './selected-departments/selected-departments.reducer';
import { SelectedDepartmentsService } from './selected-departments/selected-departments.service';
import { handleUndo } from './shared/undo/handleUndo';

export const API_PROVIDERS = [
  AuthApi,
  AuthService,
  SelectedDepartmentsService,
  PageFiltersService,
  PageParamsService,
  MessageService,
  AccountService,
  SubscriptionService,
  AccountApi,
  CurrencyApi,
  MfaApi,
  MfaService,
  ...OrmProviders,
];

export const GUARDS = [
  EmployeesLoadedGuard,
  LocationExistsGuard,
  DepartmentExistsGuard,
  ContractTypeExistsGuard,
  SkillExistsGuard,
  SkillGroupExistsGuard,
  RateCardExistsGuard,
  ScheduleExistsGuard,
  ScheduleExchangeLoadGuard,
  ExchangeLoadGuard,
  ReportSidebarGuard,
  EventLoadGuard,
  HolidayExistsGuard,
  SurchargeExistsGuard,
  PermissionGroupExistsGuard,
  CorrectionExistsGuard,
  TimesheetExistsGuard,
  OpenShiftExistsGuard,
  RequiredShiftExistsGuard,
  AbsenceExistsGuard,
  AvailabilityExistsGuard,
  EmployeeDetailGuard,
];

export interface State {
  auth: AuthState;
  messages: Message[];
  mfa: MfaState;
  account: AccountState;
  currency: CurrencyState;
  selectedDepartments: string[];
  pageFilters: PageFiltersState;
  pageParamsState: PageParamsState;
}

export interface AppState extends State {
  orm: OrmState;
}

export const AppReducers: ActionReducerMap<State> = {
  auth: AuthReducer,
  messages: MessageReducer,
  mfa: MfaReducer,
  account: AccountReducer,
  currency: CurrencyReducer,
  selectedDepartments: SelectedDepartmentsReducer,
  pageFilters: PageFiltersReducer,
  pageParamsState: PageParamsReducer,
};

export const AppEffects = [
  AuthEffect,
  MessageEffect,
  AccountEffect,
  PageFiltersEffect,
  ScheduleUpdateEffect,
  TimesheetConflictEffect,
  TrackingEffect,
  ...ormEffects,
];

export const StoreProviders = [...API_PROVIDERS, ...GUARDS];

export const metaReducers: Array<MetaReducer<any, any>> = [reset, handleUndo];
