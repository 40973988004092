import { Injectable } from '@angular/core';
import { AppState } from '@app/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';

import {
  departmentVariationDeleteFailed,
  departmentVariationDeleteRequest,
  departmentVariationDeleteSuccess,
  departmentVariationLoadFailed,
  departmentVariationLoadRequest,
  departmentVariationLoadSuccess,
  departmentVariationPutFailed,
  departmentVariationPutRequest,
  departmentVariationPutSuccess,
} from './department-variation.action';
import { DepartmentVariationApi } from './department-variation.api';
import { getActiveDepartmentsWithoutVariations } from './department-variation.selectors';

@Injectable()
export class DepartmentVariationEffect {
  public putRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentVariationPutRequest),
      switchMap(({ departmentVariation, departmentId, departmentSection }) =>
        this.api.add(departmentVariation, departmentId, departmentSection).pipe(
          concatLatestFrom(() =>
            !departmentId || departmentId === '0'
              ? this.store.select(getActiveDepartmentsWithoutVariations)
              : of(undefined),
          ),
          map(([departmentVariation, departmentsWithoutVariations]) =>
            departmentVariationPutSuccess({ departmentVariation, departmentsWithoutVariations }),
          ),
          catchError(() => of(departmentVariationPutFailed())),
        ),
      ),
    ),
  );

  public deleteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentVariationDeleteRequest),
      switchMap(({ section, departmentId }) =>
        this.api.delete(section, departmentId).pipe(
          map(() => departmentVariationDeleteSuccess({ departmentId })),
          catchError(() => of(departmentVariationDeleteFailed())),
        ),
      ),
    ),
  );

  public loadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentVariationLoadRequest),
      switchMap(({ section }) =>
        this.api.load(section).pipe(
          map((departmentVariations) => departmentVariationLoadSuccess({ departmentVariations })),
          catchError(() => of(departmentVariationLoadFailed({ section }))),
        ),
      ),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly api: DepartmentVariationApi,
    private store: Store<AppState>,
  ) {}
}
