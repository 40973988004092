import { createReducer, on } from '@ngrx/store';

import { HolidayGroupsActions } from './holiday-groups.action';
import { adapter, HolidayGroupsState, initialState } from './holiday-groups.state';

export const holidayGroupsReducer = createReducer<HolidayGroupsState>(
  initialState,
  on(HolidayGroupsActions.addAndLinkDepartmentsRequest, (state) => ({ ...state, adding: true })),
  on(HolidayGroupsActions.addAndLinkDepartmentsSuccess, (state, { holidayGroup }) =>
    adapter.addOne<HolidayGroupsState>(holidayGroup, { ...state, adding: false }),
  ),
  on(HolidayGroupsActions.addAndLinkDepartmentsFailed, (state) => ({ ...state, adding: false })),

  on(HolidayGroupsActions.loadAllRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(HolidayGroupsActions.loadAllSuccess, (state, { holidayGroups }) =>
    adapter.addMany(holidayGroups, {
      ...state,
      isLoading: false,
    }),
  ),
  on(HolidayGroupsActions.loadAllFailed, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(HolidayGroupsActions.editAndLinkDepartmentRequest, (state, { holidayGroup }) => ({
    ...state,
    updating: { ...state.updating, [holidayGroup.id]: true },
  })),
  on(HolidayGroupsActions.editAndLinkDepartmentSuccess, (state, { holidayGroup }) =>
    adapter.updateOne(
      { id: holidayGroup.id, changes: holidayGroup },
      { ...state, updating: { ...state.updating, [holidayGroup.id]: false } },
    ),
  ),
  on(HolidayGroupsActions.editAndLinkDepartmentFailed, (state, { holidayGroupId }) => ({
    ...state,
    updating: { ...state.updating, [holidayGroupId]: false },
  })),

  on(HolidayGroupsActions.deleteSuccess, (state, { groupId }) => adapter.removeOne(groupId, state)),
);
