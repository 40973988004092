import { SchedulesLoadRequest } from '../../orm/schedule/schedule.model';

const ACTION_PREFIX = '[DepartmentData]';

export const departmentDataNActionType = {
  LOAD_SUCCESS: `${ACTION_PREFIX} LOAD SUCCESS`,
};

export class DepartmentDataNAction {
  static loadSuccess(payload, requestData: SchedulesLoadRequest) {
    return {
      type: departmentDataNActionType.LOAD_SUCCESS,
      payload,
    };
  }
}
