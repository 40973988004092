import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SbChartData, ChartType, SbChartOptions } from './chart.model';

@Component({
  selector: 'sb-chart',
  templateUrl: './chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbChartComponent {
  @Input()
  public type: ChartType;

  @Input()
  public data: SbChartData;

  @Input()
  public options: SbChartOptions;

  @Input()
  public height: string;
}
