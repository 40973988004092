import { UnsafeAction as Action } from '../../interfaces';
import { CorrectionState } from './correction.model';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { mergeEntities, removeEntity, resetEntities, updateEntitiesById } from '../orm';
import { correctionActionType } from './correction.action';

const entityType = 'corrections';

const initialState: CorrectionState = {
  items: [],
  itemsById: {},
};

export function CorrectionReducer(state: CorrectionState = initialState, action: Action): CorrectionState {
  let payload = action.payload;

  switch (action.type) {
    case correctionActionType.LOAD_SUCCESS:
      let entities = action.payload.corrections.entities[entityType];
      return resetEntities(state, entities);

    case correctionActionType.REMOVE:
      return updateEntitiesById(state, { deleted: true }, payload);

    case correctionActionType.REMOVE_FAILED:
      return updateEntitiesById(state, { deleted: false }, payload.id);

    case correctionActionType.REMOVE_SUCCESS:
      return removeEntity(state, payload);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}
