import { Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { getEmployeeTeamDepartments, getPermissionState } from '../../reducers/auth/permission.helper';
import { AppState } from '../../reducers/index';
import { AbsenteePermission, EnhancedAbsenceModel } from '../../reducers/orm/absence/absence.model';
import { checkAbsenteePermission } from '../../reducers/orm/absence/absence.permission.helper';

@Directive({
  selector: '[absenteePermission]',
  standalone: true,
})
export class AbsenteePermissionDirective implements OnInit, OnDestroy {
  private absence$: BehaviorSubject<EnhancedAbsenceModel> = new BehaviorSubject(null);

  private check$: Subscription;
  private show: boolean;

  private _context: AbsenteePermissionContext = new AbsenteePermissionContext();
  private _thenTemplateRef: TemplateRef<AbsenteePermissionContext> = null;
  private _elseTemplateRef: TemplateRef<AbsenteePermissionContext> = null;
  private _thenViewRef: EmbeddedViewRef<AbsenteePermissionContext> = null;
  private _elseViewRef: EmbeddedViewRef<AbsenteePermissionContext> = null;

  // tslint:disable-next-line:no-input-rename
  @Input('absenteePermissionType')
  public type: AbsenteePermission = 'edit';

  public constructor(
    private _viewContainer: ViewContainerRef,
    private store: Store<AppState>,
    templateRef: TemplateRef<AbsenteePermissionContext>,
  ) {
    this._thenTemplateRef = templateRef;
  }

  @Input('absenteePermission')
  public set absence(absence: EnhancedAbsenceModel) {
    this.absence$.next(absence);
  }

  @Input()
  public set absenteePermissionThen(templateRef: TemplateRef<AbsenteePermissionContext>) {
    this._thenTemplateRef = templateRef;
    this._thenViewRef = null; // clear previous view if any.
    this._updateView();
  }

  @Input()
  public set absenteePermissionElse(templateRef: TemplateRef<AbsenteePermissionContext>) {
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null; // clear previous view if any.
    this._updateView();
  }

  public ngOnInit() {
    // When guard or permissionState changes, check permissions
    this.check$ = observableCombineLatest(
      this.absence$.pipe(distinctUntilChanged()),
      this.store.select(getEmployeeTeamDepartments),
      this.store.select(getPermissionState),
      (absence, employeeTeamDepartments, permissionState) =>
        checkAbsenteePermission(absence, employeeTeamDepartments, permissionState, this.type),
    )
      .pipe(distinctUntilChanged())
      .subscribe((show) => {
        this._context.$implicit = show;
        this._updateView();
      });
  }

  public ngOnDestroy() {
    this.check$.unsubscribe();
  }

  private _updateView() {
    if (this._context.$implicit) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._viewContainer.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
        }
      }
    }
  }
}

export class AbsenteePermissionContext {
  public $implicit: any = null;
}
