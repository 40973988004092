import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Notification]';

export const notificationActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  BATCH_UPDATE: `${ACTION_PREFIX} Batch Update`,
  BATCH_UPDATE_SUCCESS: `${ACTION_PREFIX} Batch Update Success`,
  BATCH_UPDATE_FAILED: `${ACTION_PREFIX} Batch Update Failed`,
};

export class NotificationAction {
  static load(): Action {
    return {
      type: notificationActionType.LOAD,
    };
  }

  static loadSuccess(surcharge): Action {
    return {
      type: notificationActionType.LOAD_SUCCESS,
      payload: surcharge,
    };
  }

  static loadFailed(err): Action {
    return {
      type: notificationActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static batchUpdate(batch): Action {
    return {
      type: notificationActionType.BATCH_UPDATE,
      payload: batch,
    };
  }

  static batchUpdateSuccess(batch): Action {
    return {
      type: notificationActionType.BATCH_UPDATE_SUCCESS,
      payload: batch,
    };
  }

  static batchUpdateFailed(err): Action {
    return {
      type: notificationActionType.BATCH_UPDATE_FAILED,
      payload: err,
    };
  }
}
