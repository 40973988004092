import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../../index';
import { getOpenShift } from './open-shift.selector';
import { OpenShiftService } from './open-shift.service';

@Injectable()
export class OpenShiftExistsGuard {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private openShiftService: OpenShiftService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.hasItem(route.params['id']).pipe(
      tap((hasItem) => {
        if (!hasItem) {
          this.router.navigate(['/404']);
        }
      }),
    );
  }

  private hasItem(itemId: string): Observable<boolean> {
    return this.hasItemInStore(itemId).pipe(
      switchMap((inStore) => {
        if (inStore) {
          return observableOf(inStore);
        }

        //check for occurrenceId
        if (!itemId.includes(':')) {
          return observableOf(false);
        }

        return this.hasItemInApi(itemId);
      }),
    );
  }

  private hasItemInStore(itemId: string): Observable<boolean> {
    return this.store.select(getOpenShift(itemId)).pipe(
      map((entity) => !!entity),
      take(1),
    );
  }

  private hasItemInApi(itemId: string): Observable<boolean> {
    return this.openShiftService.fetch(itemId).pipe(
      map((response) => !!response),
      catchError((err) => {
        const date = itemId.split(':')[1];
        this.router.navigate(['/schedule/employee/week', { date: date }]);
        return observableOf(false);
      }),
    );
  }
}
