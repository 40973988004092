import { UnsafeAction as Action } from '../../interfaces';
import { getArrayFromActionPayload, PayloadKeyType } from '../../shared/entity.helper';
import { weatherActionType } from './weather.action';
import { initialState, weatherAdapter, WeatherState } from './weather-state';
import { WeatherTimeBlock, WeatherForecast } from './weather.model';
import { addDays, parse, toDate } from 'date-fns';
import { scheduleActionType } from '../schedule/schedule.action';
import sortBy from 'lodash-es/sortBy';
import { parseDate } from '../../../shared/date.helper';

export function WeatherReducer(state: WeatherState = initialState, action: Action) {
  const payload = action.payload;
  switch (action.type) {
    case scheduleActionType.LOAD_SCHEDULE_DATASET_SUCCESS:
    case weatherActionType.LOAD_SUCCESS:
      const entities = getArrayFromActionPayload(PayloadKeyType.WEATHER, payload.entities);
      const updatedEntities = enhanceWeatherForecasts(entities);
      return weatherAdapter.upsertMany(updatedEntities, state);
    default:
      return state;
  }
}

const enhanceWeatherForecasts = (weatherForecasts: WeatherForecast[]): WeatherForecast[] => weatherForecasts.map((forecast: WeatherForecast) => {
    const timeBlocks = sortBy(
      forecast.TimeBlocks.map((timeBlock: WeatherTimeBlock) => {
        const timeBlockStartDateString = forecast.date + ' ' + timeBlock.time_from;
        const timeBlockEndDateString = forecast.date + ' ' + timeBlock.time_till;
        const startDateTime = parse(timeBlockStartDateString, 'yyyy-MM-dd HH:mm', new Date());

        let endDateTime: Date;
        if (timeBlock.time_till < timeBlock.time_from) {
          endDateTime = addDays(parse(timeBlockEndDateString, 'yyyy-MM-dd HH:mm', new Date()), 1);
        } else {
          endDateTime = parse(timeBlockEndDateString, 'yyyy-MM-dd HH:mm', new Date());
        }

        return {
          ...timeBlock,
          endDateTime,
          startDateTime,
        };
      }),
      ['startDateTime']
    );

    const dateString = forecast.date + ' 00:00';
    const date = parse(dateString, 'yyyy-MM-dd HH:mm', new Date());
    const startDateTime = parseDate(date);
    const endDateTime = addDays(date, 1);

    return {
      ...forecast,
      TimeBlocks: timeBlocks,
      endDateTime,
      startDateTime,
    };
  });
