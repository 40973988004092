import {Component} from "@angular/core";


/**
 * Center an element on the page.
 */
@Component({
  selector: 'grid-center',
  template: `
    <div class="grid-center">
        <ng-content></ng-content>
    </div>
  `
})
export class GridCenterComponent {
}