import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../../index';
import { ClockIpAddressService, getClockIpAddress } from './clock-ip-address.service';

@Injectable()
export class ClockIpAddressExistsGuard {
  public constructor(
    private router: Router,
    private store: Store<AppState>,
    private clockIpAddressService: ClockIpAddressService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.hasItem(route.params['id']).pipe(
      tap((hasItem) => {
        if (!hasItem) {
          this.router.navigate(['/404']);
        }
      }),
    );
  }

  private hasItem(itemId: string): Observable<boolean> {
    return this.hasItemInStore(itemId).pipe(
      switchMap((inStore) => {
        if (inStore) {
          return observableOf(inStore);
        }

        return this.hasItemInApi(itemId);
      }),
    );
  }

  private hasItemInStore(itemId: string): Observable<boolean> {
    return this.store.select(getClockIpAddress(itemId)).pipe(
      map((entity) => !!entity),
      take(1),
    );
  }

  private hasItemInApi(itemId: string): Observable<boolean> {
    return this.clockIpAddressService.fetch(itemId).pipe(
      map((response) => !!response),
      catchError(() => observableOf(false)),
    );
  }
}
