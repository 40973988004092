import { UnsafeAction as Action } from '../../interfaces';

import { mergeEntities, updateEntitiesById } from '../orm';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { AppTokenState } from './app-token.model';
import { AppTokenActionType } from './app-token.action';
import { IntegrationActionType } from '../integration/integration.action';

const entityType = 'appToken';

const initialState: AppTokenState = {
  items: [],
  itemsById: {},
};

export function AppTokenReducer(state: AppTokenState = initialState, action: Action): AppTokenState {
  const { type, payload } = action;

  switch (type) {
    case AppTokenActionType.LOAD_SUCCESS:
      return mergeEntities(state, getEntities(action, entityType));

    case AppTokenActionType.DEACTIVATE_SUCCESS:
      return updateEntitiesById(state, { deleted: true }, payload);

    case AppTokenActionType.ACTIVATE_SUCCESS:
      return updateEntitiesById(state, { deleted: false }, payload);

    default:
      if (containsEntity(action, entityType)) {
        return mergeEntities(state, getEntities(action, entityType));
      }

      return state;
  }
}
