import { Injectable, OnDestroy } from '@angular/core';
import { AppState } from '@app/reducers';
import { AccountModel } from '@app/reducers/account/account.model';
import { getAccount } from '@app/reducers/account/account.service';
import { PermissionState } from '@app/reducers/auth/auth.model';
import { getRefinerSignature } from '@app/reducers/auth/auth.service';
import { getPermissionState } from '@app/reducers/auth/permission.helper';
import { EmployeeModel } from '@app/reducers/orm/employee/employee.model';
import { getAuthenticatedUser } from '@app/reducers/orm/employee/employee.service';
import { Store } from '@ngrx/store';
import { TrackingEvent } from '@shiftbase-com/models';
import { combineLatest, distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

interface RefinerUser {
  id: string;
  name: string;
  account: {
    user_id: string;
    user_role: 'Account Manager' | 'Employee';
    account_id: string;
    locale: string;
    country: string;
  };
}

interface RefinerOptions {
  locale: string;
  country: string;
  signature: string;
}

interface RefinerPayload {
  user: RefinerUser;
  options: RefinerOptions;
}

@Injectable()
export class RefinerService implements OnDestroy {
  private readonly projectId = environment.refinerProjectId;

  private destroyed$ = new Subject<void>();
  private userIdentified = false;

  public constructor(private store: Store<AppState>) {}

  public async init() {
    if (!this.projectId) {
      return;
    }

    await import('refiner-js');
    window._refiner('setProject', this.projectId);
    this.trackIdentity();
  }

  public ngOnDestroy(): void {
    this.unidentify();

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public trackEvent(eventName: TrackingEvent) {
    if (this.userIdentified && window._refiner) {
      window._refiner('trackEvent', eventName);
    }
  }

  private trackIdentity(): void {
    void combineLatest([
      this.store.select(getAuthenticatedUser),
      this.store.select(getAccount),
      this.store.select(getPermissionState),
      this.store.select(getRefinerSignature),
    ])
      .pipe(
        map(([user, account, permissionState, signature]) => {
          if (!user || !account || !permissionState || !signature) {
            return undefined;
          }
          return this.stateToRefinerPayload(user, account, permissionState, signature);
        }),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.destroyed$),
      )
      .subscribe((payload) => {
        if (payload) {
          this.identify(payload);
        } else {
          this.unidentify;
        }
      });
  }

  private identify(payload: RefinerPayload): void {
    if (this.userIdentified) {
      return;
    }

    window._refiner('identifyUser', payload.user, payload.options);
    this.userIdentified = true;
  }

  private unidentify() {
    if (!this.userIdentified) {
      return;
    }
    window._refiner('resetUser');
    this.userIdentified = false;
  }

  private stateToRefinerPayload(
    user: EmployeeModel,
    account: AccountModel,
    permissionState: PermissionState,
    signature: string,
  ): RefinerPayload {
    return {
      user: {
        id: user.id,
        name: user.id,
        account: {
          user_id: user.id,
          user_role: permissionState.isAccountManager ? 'Account Manager' : 'Employee',
          account_id: account.id,
          locale: user.locale?.substring(0, 2).toLowerCase(),
          country: account.country?.toLowerCase(),
        },
      },
      options: {
        locale: user.locale?.substring(0, 2).toLowerCase(),
        country: account.country?.toLowerCase(),
        signature,
      },
    };
  }
}
