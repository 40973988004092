import { UnsafeAction as Action } from '../../interfaces';
import { IntegrationActionType } from './integration.action';
import { mergeEntities, updateEntitiesById, removeEntity, addEntity, getEntity } from '../orm';
import { containsEntity, getEntities, getResultEntity } from '../../shared/entity.helper';
import { IntegrationState } from './integration.model';

const entityType = 'integration';

const initialState: IntegrationState = {
  items: [],
  itemsById: {},
  activeIntegration: '',
  loading: {},
};

export function IntegrationReducer(state: IntegrationState = initialState, action: Action): IntegrationState {
  const { type, payload } = action;

  switch (type) {
    case IntegrationActionType.LOAD_SUCCESS:
      return mergeEntities(state, getEntities(action, entityType));

    case IntegrationActionType.FETCH_ENHANCED_INTEGRATION:
    case IntegrationActionType.SAVE_INTEGRATION_MAPPING:
      const loadingStarted = state.loading;

      return {
        ...state,
        activeIntegration: payload,
        loading: {
          ...loadingStarted,
          [payload]: true,
        },
      };

    case IntegrationActionType.FETCH_ENHANCED_INTEGRATION_SUCCESS:
    case IntegrationActionType.SAVE_INTEGRATION_MAPPING_SUCCESS:
      if (getEntity(state, payload.result)) {
        state = removeEntity(state, payload.result);
      }

      const loadingFinished = state.loading;

      return {
        ...addEntity(state, getResultEntity(action, entityType)),
        loading: {
          ...loadingFinished,
          [payload.result]: false,
        },
      };

    case IntegrationActionType.FETCH_ENHANCED_INTEGRATION_FAILED:
    case IntegrationActionType.SAVE_INTEGRATION_MAPPING_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.id]: false,
        },
      };

    case IntegrationActionType.DEACTIVATE_SUCCESS:
      return updateEntitiesById(state, { deleted: true }, payload);

    case IntegrationActionType.ACTIVATE_SUCCESS:
      return updateEntitiesById(state, { deleted: false }, payload);

    default:
      if (containsEntity(action, entityType)) {
        return mergeEntities(state, getEntities(action, entityType));
      }

      return state;
  }
}
