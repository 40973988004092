import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DepartmentVariationSettingModel } from './department-variation.model';

export interface DepartmentVariationState extends EntityState<DepartmentVariationSettingModel> {
  isAdding: boolean;
  isLoading?: boolean;
  error?: any;
}

export const departmentVariationStateModelEntityAdapter: EntityAdapter<DepartmentVariationSettingModel> =
  createEntityAdapter<DepartmentVariationSettingModel>({ selectId: (department) => department.department_id });

export const initialState: DepartmentVariationState = departmentVariationStateModelEntityAdapter.getInitialState({
  isAdding: false,
  isLoading: false,
  error: null,
});
