import {
  AfterContentChecked,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { trackByIdFn, trackByOccurrenceIdFn } from '../../../shared/trackby';

@Component({
  selector: 'awesome-grid-row-container',
  templateUrl: './awesome-grid-row-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AwesomeGridRowContainerComponent implements OnChanges, AfterViewChecked {
  @Input()
  height = 36;

  @Input()
  templateRef: TemplateRef<any>;

  public trackById = trackByIdFn;
  public trackByOccurrenceId = trackByOccurrenceIdFn;

  public inViewPort = false;

  constructor(private cd: ChangeDetectorRef) {
    this.cd.detach();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cd.detectChanges();
  }

  public onIntersection({ target, visible }) {
    if (this.inViewPort === visible) {
      return;
    }

    this.inViewPort = visible;
    this.cd.detectChanges();
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }
}
