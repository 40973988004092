import { UnsafeAction as Action } from '../../interfaces';
import { CommentState } from './comment.model';

const entityType = 'comment';

const initialState: CommentState = {
  items: [],
  itemsById: {},
};

export function CommentReducer(state: CommentState = initialState, action: Action): CommentState {
  return state;
}
