import find from 'lodash-es/find';
import { UnsafeAction as Action } from '../../interfaces';
import { LocationState } from './location.model';
import { locationActionType } from './location.action';
import { containsEntity, getEntities, getResultEntity } from '../../shared/entity.helper';
import { resetEntities, mergeEntities, addChildId, updateBelongsTo, updateEntitiesById } from '../orm';
import { departmentActionType } from '../department/department.action';

const entityType = 'locations';

const initialState: LocationState = {
  items: [],
  itemsById: {},
};

export function LocationReducer(state: LocationState = initialState, action: Action): LocationState {
  const payload = action.payload;

  switch (action.type) {
    //add department.id to list of departments
    case departmentActionType.ADD_SUCCESS:
      return addDepartment(action, state);

    case departmentActionType.UPDATE_SUCCESS:
      return updateDepartment(action, state);

    case departmentActionType.ACTIVATE_SUCCESS:
      return handleActivateDepartment(action, state);

    //reset all locations
    case locationActionType.LOAD_SUCCESS:
      const entities = getEntities(action, 'locations');
      const ids = payload.result;

      return resetEntities(state, entities, ids);

    case locationActionType.DEACTIVATE:
    case locationActionType.ACTIVATE:
      return updateEntitiesById(state, { loading: true }, payload);

    case locationActionType.DEACTIVATE_FAILED:
    case locationActionType.ACTIVATE_FAILED:
      return updateEntitiesById(state, { loading: false }, payload);

    case locationActionType.DEACTIVATE_SUCCESS:
      return updateEntitiesById(state, { loading: false, deleted: true }, payload);

    case locationActionType.ACTIVATE_SUCCESS:
      return updateEntitiesById(state, { loading: false, deleted: false }, payload);

    default:
      if (containsEntity(action, entityType)) {
        const entities = getEntities(action, 'locations');
        return mergeEntities(state, entities);
      }

      return state;
  }
}

function addDepartment(action: Action, state: LocationState) {
  const department = getResultEntity(action, 'departments');
  return addChildId(state, department.location_id, 'Department', department.id);
}

function updateDepartment(action: Action, state: LocationState) {
  const department = getResultEntity(action, 'departments');
  return updateBelongsTo(state, department.location_id, 'Department', department.id);
}

function handleActivateDepartment(action: Action, state: LocationState) {
  //search for location where Departments contains action.payload ( deparmentId ) and location is not active
  const location = find(state.itemsById, (entity) => {
    if (!entity.deleted) {
      return false;
    }

    return entity.Department.indexOf(action.payload) !== -1;
  });

  if (!location) {
    return state;
  }

  return updateEntitiesById(state, { deleted: false }, location.id);
}
