import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DepartmentDataNModel } from './department-data.n.model';
import { ScheduleFilterShowOptions, ScheduleFilterShowTypes } from '../../page-filters/page-filters.model';

export interface DepartmentDataNState extends EntityState<DepartmentDataNModel> {
  isLoading?: boolean;
  error?: any;
  minDate: string;
  maxDate: string;
  shiftFilters: string[];
  teamFilters: string[];
  showTypes?: ScheduleFilterShowTypes;
  showOptions?: ScheduleFilterShowOptions;
}

export const departmentDataNAdapter: EntityAdapter<DepartmentDataNModel> = createEntityAdapter<DepartmentDataNModel>();

export const initialState: DepartmentDataNState = departmentDataNAdapter.getInitialState({
  isLoading: false,
  error: null,
  minDate: '',
  maxDate: '',
  shiftFilters: [],
  teamFilters: [],
  showTypes: undefined,
  showOptions: undefined,
});
