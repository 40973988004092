<div
  inViewport
  (inViewportAction)="onIntersection($event)"
  [inViewportOptions]="{ threshold: 0, rootMargin: '100%' }"
  [ngStyle]="{ 'height.px': height }"
>
  <div *ngIf="inViewPort">
    <ng-container [ngTemplateOutlet]="templateRef"></ng-container>
  </div>
</div>
