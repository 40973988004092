import { UnsafeAction as Action } from '../../interfaces';
import { customFieldsActionType } from './custom-fields.action';
import { CustomFieldsAppliesTo, CustomFieldsModel } from './custom-fields.model';
import { customFieldsAdapter, CustomFieldsState, initialState } from './custom-fields.state';

export function CustomFieldsReducer(state: CustomFieldsState = initialState, action: Action): CustomFieldsState {
  const payload = action.payload;

  switch (action.type) {
    case customFieldsActionType.LOAD:
    case customFieldsActionType.ADD:
    case customFieldsActionType.UPDATE:
    case customFieldsActionType.DELETE:
      return { ...state, isLoading: true };
    case customFieldsActionType.LOAD_SUCCESS:
      return customFieldsAdapter.setAll(enhanceCustomFields(payload), { ...state, isLoading: false });
    case customFieldsActionType.ADD_SUCCESS:
      return customFieldsAdapter.addOne(enhanceCustomField(payload), { ...state, isLoading: false });
    case customFieldsActionType.UPDATE_SUCCESS:
      return customFieldsAdapter.updateOne(
        {
          id: payload.id,
          changes: enhanceCustomField(payload.changes),
        },
        { ...state, isLoading: false },
      );
    case customFieldsActionType.DELETE_SUCCESS:
      return customFieldsAdapter.removeOne(payload, { ...state, isLoading: false });
    case customFieldsActionType.FETCH_FAILED:
    case customFieldsActionType.ADD_FAILED:
    case customFieldsActionType.UPDATE_FAILED:
    case customFieldsActionType.DELETE_FAILED:
      return { ...state, isLoading: false, error: payload.err };
    default:
      return state;
  }
}

const enhanceCustomFields = (customFields: CustomFieldsModel[]) =>
  customFields.map((customField) => enhanceCustomField(customField));

const enhanceCustomField = (customField: CustomFieldsModel): CustomFieldsModel => {
  let appliesTo = CustomFieldsAppliesTo.USER;
  const width = 150;

  if (customField.hasOwnProperty('applies_to')) {
    appliesTo = customField.applies_to;
  }
  return {
    ...customField,
    applies_to: appliesTo,
    width,
  };
};
