import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ReportFavoriteModel } from './report-favorite.model';

export interface ReportFavoriteState extends EntityState<ReportFavoriteModel> {
  isLoading?: boolean;
  error?: any;
}

export const reportFavoriteEntityAdapter: EntityAdapter<ReportFavoriteModel> = createEntityAdapter<
  ReportFavoriteModel
>();

export const initialState: ReportFavoriteState = reportFavoriteEntityAdapter.getInitialState({
  isLoading: false,
  error: null,
});
