import { Pipe, PipeTransform } from '@angular/core';
import { decimalHoursToDurationParts } from '@shiftbase-com/utilities';
import { timeToDecimal } from '../shared/date.helper';
import padStart from 'lodash-es/padStart';

@Pipe({
  name: 'calculateAbsenteeDayEndtime',
  pure: true,
})
export class CalculateAbsenteeDayEndtimePipe implements PipeTransform {
  transform(hours: string, startTime: string, defaultValue = '0:00'): string {
    if (!hours || !startTime) {
      return defaultValue;
    }
    const startDecimal = timeToDecimal(startTime);
    const hoursDecimal = parseFloat(hours);
    let total = hoursDecimal + startDecimal;
    if (total > 24) {
      return '-';
    }

    if (total >= 24) {
      total -= 24;
    }

    const parts = decimalHoursToDurationParts(total);
    return `${parts.hours}:${padStart(parts.minutes.toString(), 2, '0')}`;
  }
}
