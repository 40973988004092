import { UnsafeAction as Action } from '../../interfaces';
import { integrationAppActionType } from './integration-app.action';
import { IntegrationAppModel, IntegrationAppTitle, IntegrationAppTooltip } from './integration-app.model';
import { initialState, integrationAppAdapter, IntegrationAppState } from './integration-app.state';

export function IntegrationAppReducer(state: IntegrationAppState = initialState, action: Action) {
  const payload = action.payload;
  switch (action.type) {
    case integrationAppActionType.LOAD:
      return { ...state, isLoading: true };
    case integrationAppActionType.LOAD_SUCCESS:
      return integrationAppAdapter.setAll(enhanceApps(payload), { ...state, isLoading: false });
    case integrationAppActionType.LOAD_FAILED:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}

// eslint-disable-next-line max-lines-per-function
const enhanceApps = (apps: IntegrationAppModel[]) => {
  const availableApps = Object.values(IntegrationAppTitle);

  return (
    apps
      .filter((app) => availableApps.includes(app.title))
      // eslint-disable-next-line max-lines-per-function
      .map((app: IntegrationAppModel) => {
        let url = app.title.toLocaleLowerCase();
        let logoVersion: number = undefined;
        const tooltipsToTranslate: IntegrationAppTooltip[] = [];

        switch (app.title) {
          case IntegrationAppTitle.CONNEXIE: {
            tooltipsToTranslate.push(IntegrationAppTooltip.REGISTERED_HOURS_MANUAL);
            break;
          }
          case IntegrationAppTitle.EXACT:
          case IntegrationAppTitle.LOKET_V2:
          case IntegrationAppTitle.NMBRS:
          case IntegrationAppTitle.HOORAY: {
            tooltipsToTranslate.push(IntegrationAppTooltip.EMPLOYEE_INFORMATION_NIGHTLY);
            break;
          }
          case IntegrationAppTitle.DATAFOX: {
            tooltipsToTranslate.push(IntegrationAppTooltip.CLOCK_REGISTRATION_IMMEDIATELY);
            break;
          }
          case IntegrationAppTitle.GASTROFIX: {
            tooltipsToTranslate.push(
              IntegrationAppTooltip.TURNOVER_NIGHTLY,
              IntegrationAppTooltip.CLOCK_REGISTRATION_3H,
            );
            break;
          }
          case IntegrationAppTitle.UNTILL: {
            tooltipsToTranslate.push(
              IntegrationAppTooltip.TURNOVER_NIGHTLY,
              IntegrationAppTooltip.CLOCK_REGISTRATION_3H,
            );
            logoVersion = 2;
            break;
          }
          case IntegrationAppTitle.LIGHTSPEED_RESTAURANT_V2: {
            url = 'lightspeed-restaurant-v2';
            tooltipsToTranslate.push(IntegrationAppTooltip.TURNOVER_NIGHTLY);
            break;
          }
          case IntegrationAppTitle.HIOPOS:
          case IntegrationAppTitle.WINSTON: {
            if (app.title === IntegrationAppTitle.WINSTON) {
              url = 'mrwinston';
            }
            tooltipsToTranslate.push(IntegrationAppTooltip.TURNOVER_NIGHTLY);
            break;
          }
          case IntegrationAppTitle.LIGHTSPEED_RETAIL: {
            if (app.title === IntegrationAppTitle.LIGHTSPEED_RETAIL) {
              url = 'lightspeed-retail';
            }
            tooltipsToTranslate.push(IntegrationAppTooltip.TURNOVER_NIGHTLY);
            break;
          }
          case IntegrationAppTitle.LIGHTSPEED_K_SERIES: {
            if (app.title === IntegrationAppTitle.LIGHTSPEED_K_SERIES) {
              url = 'lightspeed-k-series';
            }
            tooltipsToTranslate.push(IntegrationAppTooltip.TURNOVER_NIGHTLY);
            break;
          }
          case IntegrationAppTitle.MPLUS: {
            tooltipsToTranslate.push(
              IntegrationAppTooltip.TURNOVER_NIGHTLY,
              IntegrationAppTooltip.CLOCK_REGISTRATION_5M,
            );
            break;
          }
          case IntegrationAppTitle.TOMTOM: {
            tooltipsToTranslate.push(IntegrationAppTooltip.CLOCK_REGISTRATION_5M);
            break;
          }
          case IntegrationAppTitle.VECTRON: {
            tooltipsToTranslate.push(IntegrationAppTooltip.VECTRON_COLLAB);
            break;
          }
          case IntegrationAppTitle.HR_EN_SALARIS_GEMAK: {
            url = 'hr-salaris-gemak';
            break;
          }
          case IntegrationAppTitle.LOON_NL: {
            url = 'loon';
            break;
          }
          case IntegrationAppTitle.TWELVE:
          case IntegrationAppTitle.PILOGA:
          case IntegrationAppTitle.BCS:
          case IntegrationAppTitle.TOP_CHEFS:
          case IntegrationAppTitle.BUDDEE:
          case IntegrationAppTitle.PERSONIO:
          case IntegrationAppTitle.BORK:
          case IntegrationAppTitle.JIXBEE:
          case IntegrationAppTitle.NEXTUP: {
            url = app.title;
            break;
          }
        }
        return {
          ...app,
          url,
          tooltipsToTranslate,
          logoVersion,
        };
      })
  );
};
