<div class="dropdown d-inline-block">
  <button
    (click)="isOpen = !isOpen"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [ngClass]="{
      'btn btn-default btn-large': true
    }"
    class="flex h-[34px] items-center text-word-mark-800"
  >
    <icon *ngIf="icon !== undefined" [svgIcon]="icon"></icon>&nbsp; {{ buttonText | translate }}&nbsp;
    <icon svgIcon="chevron-down-solid" class="ml-[10px] align-[0px]"></icon>
  </button>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    (backdropClick)="isOpen = !isOpen"
  >
    <div class="column-dropdown-container">
      <div style="margin: 5px 10px">
        <search-input [(ngModel)]="filterText" (ngModelChange)="onSearchChange($event)"></search-input>
      </div>
      <label class="checkbox report-select-all-check">
        <input type="checkbox" class="checkbox-input" [(ngModel)]="allSelected" (ngModelChange)="selectAll()" />
        <span class="checkbox-indicator"></span>
        <span class="checkbox-description ml-[3px] flex items-center text-[14px] text-black">{{
          'Select all' | translate
        }}</span>
      </label>
      <div
        cdkDropList
        class="columns-container-drop-list"
        (cdkDropListDropped)="drop($event)"
        style="background-color: white"
      >
        <!--      <column-row cdkDrag *ngFor="let column of columns" [column]="column"></column-row>-->
        <div
          cdkDrag
          [cdkDragDisabled]="!column.checked || !!filterText"
          class="col-row"
          [ngClass]="{ 'row-checked': column.checked }"
          *ngFor="let column of filteredColumns"
        >
          <label class="checkbox">
            <input
              type="checkbox"
              class="checkbox-input"
              [(ngModel)]="column.checked"
              (ngModelChange)="checkedStateChanged()"
            />
            <span class="checkbox-indicator"></span>
            <span
              class="checkbox-description column-label"
              [ngStyle]="{
                color: column.checked ? 'black' : '#E3E3E3'
              }"
              >{{ column.label }}</span
            >
          </label>
          <ng-container *ngIf="!filterText">
            <icon cdkDragHandle svgIcon="bars-solid"></icon>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</div>
