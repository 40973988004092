import { createSelector } from 'reselect';

import { DepartmentModel } from '../department/department.model';
import { getDepartmentOptions } from '../department/department.selectors';
import { getActiveDepartments, getDepartmentEntities } from '../department/department.service';
import { selectOrm } from '../orm.selectors';
import { DepartmentVariationSettingModel } from './department-variation.model';
import { departmentVariationStateModelEntityAdapter } from './department-variation.state';

export const featureKey = 'departmentVariations';

export const selectDepartmentVariations = createSelector(selectOrm, (state) => state[featureKey]);

export const selectIsAddingDepartmentVariations = createSelector(selectDepartmentVariations, (state) => state.isAdding);
export const selectIsLoading = createSelector(selectDepartmentVariations, (state) => state.isLoading);

export const { selectAll, selectEntities } =
  departmentVariationStateModelEntityAdapter.getSelectors(selectDepartmentVariations);

export const getDepartmentVariations = createSelector(selectAll, (departmentVariations) => departmentVariations);

export const selectDepartmentVariationsForSelection = createSelector(
  selectAll,
  getDepartmentEntities,
  (departmentVariations, departments): Partial<DepartmentModel[]> =>
    departmentVariations.map(
      (departmentVariation): Partial<DepartmentModel> => ({
        name: departments[departmentVariation.department_id]?.name ?? '',
        location_id: departments[departmentVariation.department_id]?.location_id ?? '',
        id: departmentVariation.department_id,
      }),
    ) as Partial<DepartmentModel[]>,
);

export const getAvailableDepartmentVariationSelectOptions = createSelector(
  selectAll,
  getDepartmentOptions({ userId: 'me', permissions: ['Manage account'] }, true),
  (departmentVariations, departmentOptions) => {
    const variationIds = departmentVariations.map((departmentVariation) => departmentVariation.department_id);
    return departmentOptions
      ?.map((departmentOption) => ({
        ...departmentOption,
        departments: departmentOption.departments.filter((department) => !variationIds.includes(department.id)),
      }))
      .filter((departmentOption) => departmentOption.departments?.length);
  },
);

export const selectDepartmentVariationById = (departmentVariationId: string) =>
  createSelector(
    selectEntities,
    (departmentVariations): DepartmentVariationSettingModel => departmentVariations[departmentVariationId],
  );

export const getActiveDepartmentsWithoutVariations = createSelector(
  getActiveDepartments,
  getDepartmentVariations,
  (departments, variations): DepartmentModel[] => {
    const variationIds = variations.map((variation) => variation.department_id);
    return departments.filter((department) => !variationIds.includes(department.id));
  },
);
