import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule as PrimeNGSharedModule } from 'primeng/api';
import { SbChartComponent } from './chart.component';
import { ChartModule } from 'primeng/chart';
import { IconComponent } from '../../../+authenticated/shared/icon.component';

const components = [SbChartComponent];
@NgModule({
  imports: [CommonModule, IconComponent, PrimeNGSharedModule, ChartModule],
  declarations: components,
  exports: components,
})
export class SbChartModule {
  public static forRoot(): ModuleWithProviders<SbChartModule> {
    return {
      ngModule: ChartModule,
    };
  }
}
