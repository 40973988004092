import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { ModuleSchema } from '../../shared/schemas';
import { reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';

@Injectable()
export class ModuleApi {
  private endpoint = 'modules/';

  constructor(private gateway: ApiGateway) {}

  load(): Observable<any> {
    return this.gateway.get(this.endpoint).pipe(
      map((res) => reformatListResponse('Module', res)),
      map((data) => normalize(data, arrayOf(ModuleSchema), { assignEntity: assignSchemaEntity }))
    );
  }
}
