import { timer as observableTimer, Observable } from 'rxjs';

import { delayWhen, filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageAction, messageActionType } from './message.action';
import { UnsafeAction } from '../interfaces';

@Injectable()
export class MessageEffect {
  dismissMessage = createEffect(() =>
    this.actions.pipe(
      ofType(messageActionType.ADD_MESSAGE),
      map((action: any) => action.payload),
      filter((message) => message.dismissAfter > 0),
      delayWhen((message) => observableTimer(message.dismissAfter)),
      map((payload) => MessageAction.remove(payload.id))
    )
  );

  public constructor(private actions: Actions<UnsafeAction>) {}
}
