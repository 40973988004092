import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { resetEntities, mergeEntities, mergeEntity, removeEntity } from '../orm';
import { ClockLocationState } from './clock-location.model';
import { clockLocationActionType } from './clock-location.action';

const entityType = 'clockLocations';

const initialState: ClockLocationState = {
  items: [],
  itemsById: {},
};

export function ClockLocationReducer(state: ClockLocationState = initialState, action: Action): ClockLocationState {
  let payload = action.payload;

  switch (action.type) {
    case clockLocationActionType.LOAD_SUCCESS:
      let entities = getEntities(action, entityType);
      return resetEntities(state, entities);

    case clockLocationActionType.CHANGE_LOCATION:
      return mergeEntity(state, {
        id: payload.id,
        latitudeDrag: payload.latitude,
        longitudeDrag: payload.longitude,
      });

    case clockLocationActionType.CHANGE_LOCATION_SUCCESS:
    case clockLocationActionType.CHANGE_RADIUS_SUCCESS:
    case clockLocationActionType.UPDATE_LOCATION_SUCCESS:
      let updatedEntities = getEntities(action, entityType);
      return mergeEntities(state, updatedEntities);

    case clockLocationActionType.REMOVE:
      return removeEntity(state, payload);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}
