import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppState } from '../../../../../src/app/reducers';
import { getClockState, TimesheetService } from './../../../reducers/orm/timesheet/timesheet.service';
import {
  getMyOpenRequestsCounterWithoutRejections,
  getSupervisorOpenRequestsCounter,
  showClockSwitch,
} from './sb-header.selectors';

@Injectable({
  providedIn: 'root',
})
export class SBHeaderService {
  public supervisorRequestsSidebarOpen$ = new BehaviorSubject<boolean>(false);

  public myRequestsSidebarOpen$ = new BehaviorSubject<boolean>(false);

  public profileDropdownOpen$ = new BehaviorSubject<boolean>(false);

  public supervisorOpenRequests$ = this.store.select(getSupervisorOpenRequestsCounter);
  public myOpenRequests$ = this.store.select(getMyOpenRequestsCounterWithoutRejections);

  public constructor(
    private store: Store<AppState>,
    private timesheetService: TimesheetService,
  ) {}

  public toggleProfileDropdownOpen() {
    const current = this.profileDropdownOpen$.getValue();
    this.profileDropdownOpen$.next(!current);
  }

  public clockState$ = this.timesheetService.loadClockState().pipe(switchMap(() => this.store.select(getClockState)));

  public showSwitchButton$ = this.store.select(showClockSwitch);
}
