import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { combineLatest, filter, map, tap } from 'rxjs/operators';

import { accountLoaded } from '../../../reducers/account/account.service';
import { authState } from '../../../reducers/auth/auth.service';
import {
  getEmployeeTeamDepartmentsWithoutHiddenTeams,
  getPermissionState,
  hasPermission,
  PermissionCheck,
} from '../../../reducers/auth/permission.helper';
import { AppState } from '../../../reducers/index';
import { MessageAction } from '../../../reducers/message/message.action';

@Injectable()
export class AvatarPermissionGuard {
  public constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.checkRoute(route);
  }

  public canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.canActivate(route);
  }

  private checkRoute(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return observableCombineLatest(this.store.select(authState), this.store.select(accountLoaded)).pipe(
      filter(([authState, accountLoaded]) => !authState.loading && accountLoaded),
      combineLatest(
        this.store.select(getEmployeeTeamDepartmentsWithoutHiddenTeams),
        this.store.select(getPermissionState),
      ),
      map(([, employeeDepartments, permissionState]) => {
        const userId = route.params.id;

        const ownPermissionCheck: PermissionCheck = {
          permissions: ['Edit own picture'],
          userId: userId,
          departments: 'any',
        };
        const editPermissionCheck: PermissionCheck = {
          permissions: ['Edit users'],
          userId: userId,
          departments: employeeDepartments[userId],
        };

        return (
          hasPermission(ownPermissionCheck, permissionState) || hasPermission(editPermissionCheck, permissionState)
        );
      }),
      tap((isAllowed) => {
        if (!isAllowed) {
          this.store.dispatch(
            MessageAction.error(this.translate.instant('You do not have permission to open that page')),
          );
        }
      }),
    );
  }
}
