import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ContractTypeService } from '../contract-type/contract-type.service';
import { LocationService } from '../location/location.service';
import { rateCardActionType } from './rate-card.action';

@Injectable()
export class RateCardEffect {
  // refresh contract types and shifts after updating a rate card
  // this is due to that rate cards create a new copy and attaches it to the contract type and shifts
  public readonly updateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rateCardActionType.UPDATE_SUCCESS),
        switchMap(() => forkJoin([this.contractTypeService.load(), this.locationService.load()])),
      ),
    { dispatch: false },
  );

  public constructor(
    private readonly actions$: Actions,
    private contractTypeService: ContractTypeService,
    private locationService: LocationService,
  ) {}
}
