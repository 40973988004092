import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { resetEntities, mergeEntities } from '../orm';
import { PermissionGroupState } from './permission-group.model';
import { permissionGroupActionType } from './permission-group.action';

const entityType = 'permissionGroups';

const initialState: PermissionGroupState = {
  items: [],
  itemsById: {},
};

export function PermissionGroupReducer(
  state: PermissionGroupState = initialState,
  action: Action
): PermissionGroupState {
  let payload = action.payload;

  switch (action.type) {
    case permissionGroupActionType.LOAD_SUCCESS:
      return resetEntities(state, getEntities(action, 'permissionGroups'));

    case permissionGroupActionType.ADD_SUCCESS:
    case permissionGroupActionType.UPDATE_SUCCESS:
      return mergeEntities(state, getEntities(action, 'permissionGroups'));

    default:
      return state;
  }
}
