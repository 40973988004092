import u from 'updeep';

import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { mergeEntities, removeEntities, removeEntity, resetEntities, updateEntitiesById } from '../orm';
import { breakRuleActionType } from './break-rule.action';
import { BreakRuleState } from './break-rule.model';

const entityType = 'breakRule';

const initialState: BreakRuleState = {
  loaded: false,
  items: [],
  itemsById: {},
};

export function BreakRuleReducer(state: BreakRuleState = initialState, action: Action): BreakRuleState {
  const payload = action.payload;

  switch (action.type) {
    case breakRuleActionType.LOAD_SUCCESS:
      const entities = getEntities(action, 'breakRule');
      // @ts-expect-error types of updeep are horribly broken [SHB-6171] Fixed by getting rid of updeep altogether rid of updeep altogether.
      return u({ loaded: true }, resetEntities(state, entities));

    case breakRuleActionType.REMOVE_SUCCESS: {
      return removeEntity(state, payload);
    }

    case breakRuleActionType.BATCH_SUCCESS: {
      // Replace entities that were sent with the ones in the response
      return mergeEntities(
        removeEntities(state, payload.replaces),
        getEntities({ ...action, payload: payload.breakRuleResponse }, 'breakRule'),
      );
    }

    default:
      if (containsEntity(action, entityType)) {
        const entities = getEntities(action, 'breakRule');
        return mergeEntities(state, entities);
      }

      return state;
  }
}
