import { CommonModule, TitleCasePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@sb/tooltip';
import { FileUploadModule } from 'ng2-file-upload';

import { AnonymousGuard } from '../+anonymous/anonymous-guard.service';
import { ScheduleHelperService } from '../+authenticated/+schedule/shared/schedule-helper.service';
import { TimesheetHelperService } from '../+authenticated/+timesheet/shared/timesheet-helper.service';
import { PermissionGuard } from '../+authenticated/permission-guard.service';
import { ActionConfirmComponent } from '../+authenticated/shared/action-confirm.component';
import { PermissionDirective } from '../+authenticated/shared/permission.directive';
import { SidebarLeftComponent } from '../+authenticated/shared/sidebar/sidebar-left.component';
import { SidebarComponent } from '../+authenticated/shared/sidebar/sidebar.component';
import { SupportService } from '../+modals/support/support.service';
import { TimesheetClockInfoComponent } from '../+modals/timesheet/timesheet-hours/clock-location/timesheet-clock-info.component';
import { SignupGuard } from '../+signup/signup-guard.service';
import { ApiGateway } from '../api/ApiGateway.service';
import { ValidationMessageService } from '../forms/validation-message.service';
import { DecimalToTimePipe } from '../pipes/decimal-to-time.pipe';
import { DefaultPipe } from '../pipes/default.pipe';
import { FileSizePipe } from '../pipes/file-size.pipe';
import { JoinPipe } from '../pipes/join.pipe';
import { KeysPipe } from '../pipes/keys.pipe';
import { MultiselectPipe } from '../pipes/multiselect.pipe';
import { ParseFloatPipe } from '../pipes/parse-float.pipe';
import { SearchPipe } from '../pipes/search.pipe';
import { TimePipe } from '../pipes/time.pipe';
import { ToArrayPipe } from '../pipes/to-array.pipe';
import { ToStringPipe } from '../pipes/to-string.pipe';
import { StoreProviders } from '../reducers/index';
import { AuthenticatedGuard } from '../startup/authenticated-guard.service';
import { DataStartup } from '../startup/data-startup.service';
import { LanguageStartup } from '../startup/language-startup.service';
import { DecimalPipe } from './../pipes/decimal.pipe';
import { PhonePipe } from './../pipes/phone.pipe';
import { TruncatePipe } from './../pipes/truncate.pipe';
import { ClockIpAddressExistsGuard } from './../reducers/orm/clock-ip-address/clock-ip-address-exists.guard';
import { BalanceComponent } from './balance.component';
import { ColorInputComponent } from './color-input.component';
import { ContentStateComponent } from './content-state/content-state.component';
import { DecimalTimeInputComponent } from './decimal-time-input.component';
import { FixedHeaderDirective } from './fixed/fixed-header.directive';
import { ScrollWindowDirective } from './fixed/scroll-window.directive';
import { WindowRef } from './fixed/window';
import { HesitateDirective } from './hesitate.directive';
import { HorizontalScrollService } from './horizontal-scroll/horizontal-scroll.service';
import { HorizontalScrollableDirective } from './horizontal-scroll/horizontal-scrollable.directive';
import { HorizontalScrollbarComponent } from './horizontal-scroll/horizontal-scrollbar.component';
import { LoadingBarComponent } from './loading-bar.component';
import { ConfigureAuthenticatorComponent } from './mfa/configure-authenticator/configure-authenticator.component';
import { ConfigureBackupCodeComponent } from './mfa/configure-backup-code/configure-backup-code.component';
import { ConfigureBackupEmailComponent } from './mfa/configure-backup-email/configure-backup-email.component';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { TreeviewModule } from './ngx-treeview/treeview.module';
import { DatePipe } from './sb-lib/calendar/pipes/date.pipe';
import { SelectEmployeeComponent } from './select-employee/select-employee.component';
import { SelectComponent } from './select/select.component';
import { TimeInputComponent } from './time-input.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
// Adds the lazy-select operator to Rx.Observable.
import './lazy-select.observable';

import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { ActionConfirmDirective } from '@app/+authenticated/shared/directives/action-confirm.directive';
import { TimeOffAccrualTableComponent } from '@app/+authenticated/shared/time-off-accrual-table/time-off-accrual-table.component';
import { DecimalToDurationFormatPipe } from '@app/pipes/decimal-to-duration-format.pipe';
import { HasOwnPipe } from '@app/pipes/has-own-pipe.pipe';
import { MoneyPipe } from '@app/pipes/money.pipe';
import { ReplacePipe } from '@app/pipes/replace.pipe';
import { YesNoPipe } from '@app/pipes/yesno.pipe';
import { DepartmentDeactivationGuard } from '@app/reducers/orm/department/department-deactivation.guard';
import { MapsModule } from '@sb/maps';
import {
  AnchorButtonComponent,
  ButtonComponent,
  IconButtonComponent,
  LegacyDialogModule,
  SplitButtonComponent,
  SelectComponent as UiSelectComponent,
} from '@sb/ui';
import { InViewportModule } from 'ng-in-viewport';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';

import { ChangeDetectorModule } from '../+authenticated/+schedule/employee/schedule-employee-n-period/change-detector-module';
import { AbsenteePermissionDirective } from '../+authenticated/shared/absentee-permission.directive';
import { AvatarPermissionGuard } from '../+authenticated/shared/avatar/avatar-permission-guard.service';
import { AwesomeGridRowContainerComponent } from '../+authenticated/shared/awesome-grid/awesome-grid-row-container.component';
import { CustomFieldsTypesComponent } from '../+authenticated/shared/custom-fields/custom-fields-types.component';
import { SbDropdownModule } from '../+authenticated/shared/dropdown/sb-dropdown.module';
import { SBRadioSelectorModule } from '../+authenticated/shared/form-input/radio-selector/sb-radio-selector.module';
import { IconComponent } from '../+authenticated/shared/icon.component';
import { CustomFieldListComponent } from '../+authenticated/shared/modal-custom-fields/custom-field-list.component';
import { TimeOffAccrualCalculationInputComponent } from '../+authenticated/shared/time-off-accrual/time-off-accrual-calculation-input.component';
import { RouteModalComponent } from '../+modals/route-modal.component';
import { ControlMessages } from '../forms/control-messages.component';
import { ModalFieldComponent } from '../forms/modal-field.component';
import { CalculateAbsenteeDayEndtimePipe } from '../pipes/calculate-absentee-day-endtime.pipe';
import { CalculateHeightPipe } from '../pipes/calculate-height.pipe';
import { CalculateTimesheetDayHeightPipe } from '../pipes/calculate-timesheet-day-height.pipe';
import { CalculateTimesheetHeightPipe } from '../pipes/calculate-timesheet-height.pipe';
import { CalculateTimesheetTotalBreakTimePipe } from '../pipes/calculate-timesheet-total-break-time.pipe';
import { CurrencySymbolPipe } from '../pipes/currency-symbol.pipe';
import { DateEndOfYearPipe } from '../pipes/date-end-of-year.pipe';
import { DecodeHtmlStringPipe } from '../pipes/decode-html-string.pipe';
import { FeatureFlagPipe } from '../pipes/feature-flag.pipe';
import { KnowledgeBaseArticleLinkModule } from '../pipes/knowledge-base-article-link.module';
import { PlanCheckPipe } from '../pipes/plan-check.pipe';
import { SharedPipeModule } from '../pipes/shared-pipe.module';
import { TranslationParamsPipe } from '../pipes/translation-params.pipe';
import { IntegrationAppParamGuard } from '../reducers/orm/integration-apps/integration-app-param.guard';
import { IntegrationAppGuard } from '../reducers/orm/integration-apps/integration-app.guard';
import { FeatureService } from '../startup/feature.service';
import { DecimalInputComponent } from './decimal-input/decimal-input.component';
import { ElementInViewportService } from './element-in-viewport.service';
import { FixedContentDirective } from './fixed/fixed-content.directive';
import { SearchInputComponent } from './form-inputs/search/search-input.component';
import { GridCenterComponent } from './grid-center.component';
import { FeatureFlagModalRedirectGuard } from './guards/feature-flag-modal-redirect/feature-flag-modal-redirect.guard';
import { FeatureFlagGuard } from './guards/feature-flag/feature-flag.guard';
import { IsColorDarkPipe } from './helpers/is-color-dark.pipe';
import { LightenHexPipe } from './helpers/lighten-hex.pipe';
import { InputFocusDirective } from './input-focus/input-focus.directive';
import { MoneyInputComponent } from './money-input.component';
import { IntegrationContractMultiSelectComponent } from './multiselect/integration-contract-multi-select/integration-contract-multi-select.component';
import { IntegrationEmployeeMultiSelectComponent } from './multiselect/integration-employee-multi-select/integration-employee-multi-select.component';
import { RateTableComponent } from './rate-table/rate-table.component';
import { ReportColumnsComponent } from './report-columns/report-columns.component';
import { ReportFilterGroupbyComponent } from './report-filter-groupby/report-filter-groupby.component';
import { SBAvatarComponent } from './sb-lib/avatar/sb-avatar.component';
import { SbCalendarModule } from './sb-lib/calendar/sb-calendar.module';
import { SbChartModule } from './sb-lib/chart/chart.module';
import { SbFormFieldComponent } from './sb-lib/forms/sb-form-field.component';
import { SbStepsModule } from './sb-lib/steps/sb-steps.module';
import { SbSpinnerComponent } from './sb-spinner.component';
import { ScheduleEmployeeComponent } from './schedule-employee/schedule-employee.component';
import { ScheduleEmployeesComponent } from './schedule-employee/schedule-employees.component';
import { SelectEmployeesComponent } from './select-employees/select-employees.component';
import { SharedLaddaModule } from './shared-ladda.module';
import { SmartBannerComponent } from './smartbanner/smart-banner.component';
import { SubscriptionPlanDirective } from './subscription-plan/subscription-plan.directive';
import { SubscriptionPlanGuard } from './subscription-plan/subscription-plan.guard';
import { SubscriptionQuotaDirective } from './subscription-plan/subscription-quota.directive';
import { SubscriptionQuotaGuard } from './subscription-plan/subscription-quota.guard';

const primeNG = [InputTextModule, ToastModule, ProgressSpinnerModule, SbCalendarModule, InputNumberModule];
const standaloneComponents = [
  TranslationParamsPipe,
  DecodeHtmlStringPipe,
  SbSpinnerComponent,
  ContentStateComponent,
  RouteModalComponent,
  ModalFieldComponent,
  ControlMessages,
  ActionConfirmComponent,
  ActionConfirmDirective,
  SBAvatarComponent,
  TimePipe,
  DecimalInputComponent,
  SubscriptionQuotaDirective,
  KeysPipe,
  DatePipe,
  DecimalPipe,
  IconComponent,
  LightenHexPipe,
  IsColorDarkPipe,
  SearchInputComponent,
  InputFocusDirective,
  DecimalToTimePipe,
  MoneyPipe,
  CurrencySymbolPipe,
  DecimalTimeInputComponent,
  SearchPipe,
  PlanCheckPipe,
  FeatureFlagPipe,
  ToStringPipe,
  SbFormFieldComponent,
  ColorInputComponent,
  TimepickerComponent,
  TimeInputComponent,
  MultiselectComponent,
  SidebarLeftComponent,
  SelectComponent,
  YesNoPipe,
  DecimalToDurationFormatPipe,
  DateEndOfYearPipe,
  TimeOffAccrualTableComponent,
  TimeOffAccrualCalculationInputComponent,
  HasOwnPipe,
  ReplacePipe,
  UiSelectComponent,
  ConfigureAuthenticatorComponent,
  ConfigureBackupCodeComponent,
  ConfigureBackupEmailComponent,
  ModalInfoComponent,
  MoneyInputComponent,
  CustomFieldsTypesComponent,
  CustomFieldListComponent,
  CalculateTimesheetTotalBreakTimePipe,
  TimesheetClockInfoComponent,
  RateTableComponent,
  ToArrayPipe,
  SelectEmployeesComponent,
  ScheduleEmployeeComponent,
  ScheduleEmployeesComponent,
  IntegrationContractMultiSelectComponent,
  IntegrationEmployeeMultiSelectComponent,
  FileSizePipe,
  SplitButtonComponent,
  AnchorButtonComponent,
  JoinPipe,
];

const imports = [
  CommonModule,
  RouterModule,
  MapsModule,
  ReactiveFormsModule,
  FormsModule,
  MatIconModule,
  TranslateModule,
  TooltipModule,
  FileUploadModule,
  TreeviewModule,
  ScrollingModule,
  ExperimentalScrollingModule,
  InViewportModule,
  DragDropModule,
  CdkScrollableModule,
  ChangeDetectorModule,
  SharedLaddaModule,
  SharedPipeModule,
  ConfirmPopupModule,
  SbChartModule,
  SidebarComponent,
  PermissionDirective,
  SubscriptionPlanDirective,
  AbsenteePermissionDirective,
  ...standaloneComponents,
  SbStepsModule,
  RippleModule,
  TruncatePipe,
  ButtonComponent,
  IconButtonComponent,
  LegacyDialogModule,
];

const reports = [ReportFilterGroupbyComponent, ReportColumnsComponent];

const pipes = [CalculateAbsenteeDayEndtimePipe];
const declarations = [
  ParseFloatPipe,
  PhonePipe,
  CalculateHeightPipe,
  CalculateTimesheetHeightPipe,
  CalculateTimesheetDayHeightPipe,

  DefaultPipe,
  MultiselectPipe,

  GridCenterComponent,
  LoadingBarComponent,

  BalanceComponent,
  FixedContentDirective,
  FixedHeaderDirective,
  HesitateDirective,
  ScrollWindowDirective,
  SelectEmployeeComponent,
  HorizontalScrollbarComponent,
  HorizontalScrollableDirective,
  SmartBannerComponent,
  AwesomeGridRowContainerComponent,

  ...pipes,
  ...reports,
];

@NgModule({
  // module dependencies
  imports: [
    imports,
    primeNG,
    SBRadioSelectorModule,
    OverlayModule,
    SbDropdownModule,
    ProgressSpinnerModule,
    KnowledgeBaseArticleLinkModule,
    OverlayPanelModule,
    CalendarModule,
    DropdownModule,
  ],
  declarations: declarations,
  exports: [...imports, ...declarations, ...primeNG, ...standaloneComponents],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ApiGateway,
        ...StoreProviders,
        LanguageStartup,
        DataStartup,
        FeatureService,
        SupportService,
        AnonymousGuard,
        SignupGuard,
        PermissionGuard,
        ClockIpAddressExistsGuard,
        AuthenticatedGuard,
        AvatarPermissionGuard,
        DepartmentDeactivationGuard,

        TimesheetHelperService,
        ScheduleHelperService,
        ValidationMessageService,
        HorizontalScrollService,
        WindowRef,
        ElementInViewportService,
        CookieService,
        SubscriptionPlanGuard,
        SubscriptionQuotaGuard,
        IntegrationAppGuard,
        TitleCasePipe,
        IntegrationAppParamGuard,
        FeatureFlagGuard,
        FeatureFlagModalRedirectGuard,
        ConfirmationService,
        DatePipe,
        DecimalToTimePipe,
      ],
    };
  }
}
