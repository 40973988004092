import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiGateway } from '../../api/ApiGateway.service';
import { WindowRef } from '../../shared/fixed/window';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';

export interface SupportMessage {
  message: string;
}

@Injectable()
export class SupportService {
  private messageEndpoint = 'contact/';

  constructor(private gateway: ApiGateway, private _window: WindowRef, private store: Store<AppState>) {}

  /**
   * Service method to send support messages
   * @returns Observable<any>
   */
  sendMessage(message: SupportMessage): Observable<any> {
    return this.gateway.post(this.messageEndpoint, message);
  }

  getHubSpotIdentificationToken() {
    return this.gateway.post('support/identify_chat', undefined);
  }
}
