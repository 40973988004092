export enum ChartType {
  LINE = 'line',
}

export interface SbChartData {
  labels: string[];
  datasets: ChartDataItem[];
}

export interface ChartDataItem {
  label: string;
  data: number[];
}

export interface SbChartOptions {
  interaction: {
    intersect: boolean;
    mode: 'index';
  };
  plugins: {
    legend: {
      position: 'bottom';
      labels: {
        boxWidth: number;
        usePointStyle: boolean;
        pointStyle: 'rectRounded';
      };
    };
    tooltip: {
      position: 'nearest';
    };
  };
  responsive: boolean;
}
