import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';

import { createSelector, createStructuredSelector } from 'reselect';
import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { AppState } from '../../index';
import { mapEntities, mapEntity } from '../../shared/entity.helper';
import { ModuleApi } from './module.api';
import { ModuleAction } from './module.action';
import { ModuleState } from './module.model';
import { getAllPermissionsByModule } from '../permission/permission.service';

@Injectable()
export class ModuleService {
  constructor(private store: Store<AppState>, private api: ModuleApi) {}

  load() {
    this.store.dispatch(ModuleAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(ModuleAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(ModuleAction.loadFailed(response));
        return observableThrowError(response);
      })
    );
  }
}

export const getModuleState = (appState: AppState): ModuleState => appState.orm.modules;

export const getModuleIds = compose((state) => state.items, getModuleState);
export const getModuleEntities = createSelector(getModuleState, (state) => state.itemsById);
export const getModules = createSelector(getModuleIds, getModuleEntities, mapEntities);

export const getModule = (id: string) =>
  createSelector(getModuleEntities, (entities) => mapEntity(id, entities));

export const getModulesPermissionsAndGroups = createStructuredSelector({
  modules: getModules,
  permissions: getAllPermissionsByModule,
});
