import { UnsafeAction as Action } from '../../interfaces';
import { initialState, scheduleConflictModelEntityAdapter, ScheduleConflictState } from './schedule-conflict.state';
import { scheduleConflictActionType } from './schedule-conflict.action';
import { scheduleNActionType } from '../../store/schedule/schedule.n.action';
import { ConflictTopic, ScheduleConflictModel, ScheduleConflictStoreModel } from './schedule-conflict.model';
import { Dictionary, Update } from '@ngrx/entity';
import groupBy from 'lodash-es/groupBy';

export function ScheduleConflictReducer(
  state: ScheduleConflictState = initialState,
  action: Action
): ScheduleConflictState {
  const { payload, conflictTopic } = action;

  switch (action.type) {
    case scheduleNActionType.RESET: {
      return scheduleConflictModelEntityAdapter.removeAll({ ...state, isLoading: false });
    }
    case scheduleConflictActionType.LOAD:
      return { ...state, isLoading: true };
    case scheduleConflictActionType.UPDATE:
      const conflicts: ScheduleConflictStoreModel[] = payload.map((employeeId: string) => ({
          id: employeeId,
          timestamp: action.timestamp,
          conflicts: {},
        }));
      return scheduleConflictModelEntityAdapter.upsertMany(conflicts, { ...state, isLoading: true });
    case scheduleConflictActionType.UPDATE_SINGULAR: {
      const updatedConflicts = payload.map((employeeId: string) => {
        const existingEntity = state.entities[employeeId];

        if (!existingEntity) {
          return {
            id: employeeId,
            conflicts: {},
          };
        }

        return {
          ...existingEntity,
          conflicts: filterConflicts(existingEntity?.conflicts, conflictTopic),
        };
      });

      return scheduleConflictModelEntityAdapter.upsertMany(updatedConflicts, { ...state, isLoading: true });
    }
    case scheduleConflictActionType.LOAD_SUCCESS:
      return scheduleConflictModelEntityAdapter.setAll(payload, { ...state, isLoading: false });
    case scheduleConflictActionType.UPDATE_SUCCESS:
      const filteredConflicts: ScheduleConflictStoreModel[] = payload.filter((conflict: ScheduleConflictModel) => {
        const existingConflict = state.entities[conflict.employee_id];
        return !existingConflict || existingConflict?.timestamp === action.timestamp;
      });
      return scheduleConflictModelEntityAdapter.upsertMany(filteredConflicts, { ...state, isLoading: false });
    case scheduleConflictActionType.UPDATE_SINGULAR_SUCCESS: {
      if (!payload?.length) {
        return {
          ...state,
          isLoading: false,
        };
      }
      return scheduleConflictModelEntityAdapter.upsertMany(mergeConflicts(payload, state), {
        ...state,
        isLoading: false,
      });
    }
    case scheduleConflictActionType.LOAD_FAILED:
    case scheduleConflictActionType.UPDATE_FAILED:
      return { ...state, isLoading: false, error: payload.err };
    case scheduleConflictActionType.RESET: {
      return scheduleConflictModelEntityAdapter.removeAll({ ...state, isLoading: false });
    }
    default:
      return state;
  }
}

const mergeConflicts = (payload, state: ScheduleConflictState): ScheduleConflictStoreModel[] => {
  const data: Dictionary<ScheduleConflictModel[]> = groupBy(payload, 'employee_id');
  return Object.values(data ?? {}).map((scheduleConflicts: ScheduleConflictModel[]) => {
    const employeeId = scheduleConflicts[0].employee_id;
    const existingData = state.entities[employeeId];
    const mergedConflicts: ScheduleConflictModel[] = [
      ...Object.values(existingData?.conflicts ?? {}).flat(),
      ...scheduleConflicts,
    ];
    return {
      id: employeeId,
      conflicts: groupBy(mergedConflicts, 'occurrence_id'),
    };
  });
};

const filterConflicts = (conflictEntities: Dictionary<ScheduleConflictModel[]>, conflictTopic: ConflictTopic) => {
  const entities = Object.values(conflictEntities ?? {}).flat();
  const filteredEntities = entities.filter((entity: ScheduleConflictModel) => entity.topic !== conflictTopic);
  return groupBy(filteredEntities, 'occurrence_id');
};
