import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ParamMap, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AvailabilityService } from './availability.service';

@Injectable()
export class AvailabilityExistsGuard {
  constructor(
    private router: Router,
    private availabilityService: AvailabilityService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!route.paramMap.has('employee')) {
      return true;
    }
    return this.fetch(route.paramMap);
  }

  private fetch(paramMap: ParamMap): Observable<boolean> {
    return this.availabilityService
      .load({
        minDate: paramMap.get('start'),
        maxDate: paramMap.get('end'),
        userId: paramMap.get('employee'),
      })
      .pipe(
        map((response) => !!response),
        catchError((err) => {
          this.router.navigate(['/404']);
          return observableOf(false);
        }),
      );
  }
}
