import { Injectable } from '@angular/core';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AbsenceActionType } from '../absence/absence.action';
import { UnsafeAction } from '../../interfaces';
import { getArrayFromActionPayload, PayloadKeyType } from '../../shared/entity.helper';
import { forkJoin, of } from 'rxjs';
import { ScheduleComplianceService } from '../schedule-compliance/schedule-compliance.service';
import { RequiredShiftFulfillmentService } from '../required-shift-fulfillment/required-shift-fulfillment.service';
import { AppState } from '../../index';
import { Store } from '@ngrx/store';
import { isScheduleViewOpen } from '../../page-params-state/page-params.service';
import { openShiftActionType } from '../open-shift/open-shift.action';
import { requiredShiftActionType } from '../required-shift/required-shift.action';
import { scheduleActionType } from '../schedule/schedule.action';
import { availabilityActionType } from '../availability/availability.action';
import { getUnique } from '../../../shared/array.helper';
import { ScheduleConflictService } from '../schedule-conflict/schedule-conflict.service';
import { teamActionType } from '../team/team.action';

@Injectable()
export class ScheduleUpdateEffect {
  absenceChanged$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AbsenceActionType.ADD_SUCCESS, AbsenceActionType.UPDATE_SUCCESS, AbsenceActionType.REMOVE_SUCCESS),
        withLatestFrom(this.store.select(isScheduleViewOpen)),
        mergeMap(([action, isOpen]: [UnsafeAction, boolean]) => {
          if (!isOpen) {
            return of(null);
          }

          return this.fetchAbsenceConflicts(
            this.getUserIdsFromPayload(PayloadKeyType.ABSENCE, action.payload?.entities)
          );
        })
      ),
    { dispatch: false }
  );

  availabilityChanged$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(availabilityActionType.ADD_SUCCESS),
        withLatestFrom(this.store.select(isScheduleViewOpen)),
        mergeMap(([action, isOpen]: [UnsafeAction, boolean]) => {
          if (!isOpen) {
            return of(null);
          }
          return this.fetchAvailabilityConflicts(
            this.getUserIdsFromPayload(PayloadKeyType.AVAILABILITIES, action.payload?.entities)
          );
        })
      ),
    { dispatch: false }
  );

  teamDeleted$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(teamActionType.REMOVE_SUCCESS),
        withLatestFrom(this.store.select(isScheduleViewOpen)),
        mergeMap(([action, isOpen]: [UnsafeAction, boolean]) => {
          if (!isOpen) {
            return of(null);
          }
          return this.fetchRequiredShiftFulfillments([action.departmentId]);
        })
      ),
    { dispatch: false }
  );

  requiredShiftChanged$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          requiredShiftActionType.ADD_SUCCESS,
          requiredShiftActionType.UPDATE_SUCCESS,
          AbsenceActionType.ADD_SUCCESS,
          AbsenceActionType.UPDATE_SUCCESS
        ),
        withLatestFrom(this.store.select(isScheduleViewOpen)),
        mergeMap(([action, isOpen]: [UnsafeAction, boolean]) => {
          if (!isOpen) {
            return of(null);
          }
          const departmentIds = this.getDepartmentIdsFromPayload(
            PayloadKeyType.REQUIRED_SHIFTS,
            action.payload?.entities
          );
          return this.fetchRequiredShiftFulfillments(departmentIds);
        })
      ),
    { dispatch: false }
  );

  scheduleChanged$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          scheduleActionType.ADD_SUCCESS,
          scheduleActionType.UPDATE_SUCCESS,
          scheduleActionType.REMOVE_SUCCESS,
          openShiftActionType.ASSIGN_SUCCESS,
          openShiftActionType.TAKE_SHIFT_SUCCESS,
          openShiftActionType.MULTI_ASSIGN_SUCCESS
        ),
        withLatestFrom(this.store.select(isScheduleViewOpen)),
        mergeMap(([action, isOpen]: [UnsafeAction, boolean]) => {
          if (!isOpen || !action.updateEvent?.employeeIds?.length) {
            return of(null);
          }

          return this.triggerScheduleUpdate(
            getUnique(action.updateEvent.employeeIds),
            getUnique(action.updateEvent.departmentIds)
          );
        })
      ),
    { dispatch: false }
  );

  public constructor(
    private actions: Actions<UnsafeAction>,
    private scheduleComplianceService: ScheduleComplianceService,
    private requiredShiftFulfillmentService: RequiredShiftFulfillmentService,
    private conflictService: ScheduleConflictService,
    private store: Store<AppState>
  ) {}

  private getUserIdsFromPayload(keyType: PayloadKeyType, payload: any): string[] {
    const entities = getArrayFromActionPayload(keyType, payload);

    return getUnique(entities.map((entity) => entity.user_id));
  }

  private getDepartmentIdsFromPayload(keyType: PayloadKeyType, payload: any): string[] {
    const entities = getArrayFromActionPayload(keyType, payload);
    return getUnique(entities.map((entity) => entity.department_id || entity.Department?.id));
  }

  private fetchRequiredShiftFulfillments(departmentIds: string[]) {
    return this.requiredShiftFulfillmentService.getRequiredShiftFulfillment(
      {
        departmentIds: departmentIds,
      },
      true
    );
  }

  private fetchAllConflicts(employeeIds: string[]) {
    return this.conflictService.getAllConflicts(
      {
        employeeIds: employeeIds,
      },
      true
    );
  }

  private fetchAbsenceConflicts(employeeIds: string[]) {
    return this.conflictService.getAbsenceConflicts({
      employeeIds: employeeIds,
    });
  }

  private fetchAvailabilityConflicts(employeeIds: string[]) {
    return this.conflictService.getAvailabilityConflicts({
      employeeIds: employeeIds,
    });
  }

  private triggerScheduleUpdate(employeeIds: string[], departmentIds: string[] = []) {
    this.scheduleComplianceService.updateCompliances(employeeIds);

    return forkJoin([this.fetchRequiredShiftFulfillments(departmentIds), this.fetchAllConflicts(employeeIds)]);
  }
}
