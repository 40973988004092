import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { FeatureService } from '../../../startup/feature.service';

@Injectable()
export class FeatureFlagGuard {
  public constructor(
    private featureService: FeatureService,
    private router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.featureService.featureClientReady.pipe(
      filter((ready) => ready),
      take(1),
      map(() => {
        if (!route.data || !route.data['featureFlag']) {
          return false;
        }
        const isActivated = this.featureService.isFeatureActivated(route.data['featureFlag']);
        if (!isActivated) {
          if (route.data['featureFlagRedirect']) {
            this.router.navigateByUrl(route.data['featureFlagRedirect']);
            return true;
          }

          this.router.navigate(['/dashboard/my-overview']);
        }

        return true;
      }),
    );
  }

  public canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }
}
