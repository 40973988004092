import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../../../reducers/index';
import { EventService, getEvent } from './event.service';

@Injectable()
export class EventLoadGuard {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private eventService: EventService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.hasEventInStore(route.params['id']).pipe(
      tap((hasItem) => {
        if (!hasItem) {
          this.router.navigate(['/404']);
        }
      }),
    );
  }

  private hasEventInStore(eventId: string): Observable<boolean> {
    return this.waitForStore(eventId).pipe(
      switchMap((inStore) => {
        if (inStore) {
          return observableOf(true);
        }

        return this.hasEventInApi(eventId);
      }),
    );
  }

  private waitForStore(eventId: string): Observable<boolean> {
    return this.store.select(getEvent(eventId)).pipe(
      map((entity) => !!entity),
      take(1),
    );
  }

  private hasEventInApi(eventId: string): Observable<boolean> {
    return this.eventService.fetch(eventId).pipe(
      map((response) => !!response),
      catchError((err) => observableOf(false)),
    );
  }
}
