import { overtimePolicyEntityAdapter, initialState } from './overtime-policy.state';
import {
  overtimePolicyAddSuccess,
  overtimePolicyDeleteSuccess,
  overtimePolicyLoadSuccess,
  overtimePolicyUpdateSuccess,
} from './overtime-policy.action';
import { createReducer, on } from '@ngrx/store';

export const overtimePolicyReducer = createReducer(
  initialState,
  on(overtimePolicyLoadSuccess, (state, action) => overtimePolicyEntityAdapter.setAll(action.overtimePolicies, state)),
  on(overtimePolicyAddSuccess, (state, action) => overtimePolicyEntityAdapter.addOne(action.overtimePolicy, state)),
  on(overtimePolicyUpdateSuccess, (state, action) => overtimePolicyEntityAdapter.setOne(action.overtimePolicy, state)),
  on(overtimePolicyDeleteSuccess, (state, action) =>
    overtimePolicyEntityAdapter.removeOne(action.overtimePolicyId, state)
  )
);
