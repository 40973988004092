import { accountActionType } from '../account/account.action';
import { UnsafeAction as Action } from '../interfaces';
import { CurrencyState } from './currency.model';

const initialState: CurrencyState = {};

export function CurrencyReducer(state: CurrencyState = initialState, action: Action): CurrencyState {
  switch (action.type) {
    case accountActionType.FETCH_SUCCEEDED:
      return Object.assign({}, state, action.payload.Currency);

    default:
      return state;
  }
}
