import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[BreakRule]';

export const breakRuleActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  BATCH: `${ACTION_PREFIX} Batch`,
  BATCH_SUCCESS: `${ACTION_PREFIX} Batch Success`,
  BATCH_FAILED: `${ACTION_PREFIX} Batch Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
  REMOVE_FAILED: `${ACTION_PREFIX} Remove Failed`,
};

export class BreakRuleAction {
  static load(): Action {
    return {
      type: breakRuleActionType.LOAD,
    };
  }

  static loadSuccess(breakRule): Action {
    return {
      type: breakRuleActionType.LOAD_SUCCESS,
      payload: breakRule,
    };
  }

  static loadFailed(err): Action {
    return {
      type: breakRuleActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(breakRuleData): Action {
    return {
      type: breakRuleActionType.ADD,
      payload: breakRuleData,
    };
  }

  static addSuccess(breakRuleResponse): Action {
    return {
      type: breakRuleActionType.ADD_SUCCESS,
      payload: breakRuleResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: breakRuleActionType.ADD_FAILED,
      payload: err,
    };
  }

  static batch(breakRuleData): Action {
    return {
      type: breakRuleActionType.BATCH,
      payload: breakRuleData,
    };
  }

  static batchSuccess(breakRuleResponse, replaces: string[]): Action {
    return {
      type: breakRuleActionType.BATCH_SUCCESS,
      payload: {
        breakRuleResponse,
        replaces,
      },
    };
  }

  static batchFailed(err): Action {
    return {
      type: breakRuleActionType.BATCH_FAILED,
      payload: err,
    };
  }

  static update(breakRuleData): Action {
    return {
      type: breakRuleActionType.UPDATE,
      payload: breakRuleData,
    };
  }

  static updateSuccess(breakRuleResponse): Action {
    return {
      type: breakRuleActionType.UPDATE_SUCCESS,
      payload: breakRuleResponse,
    };
  }

  static updateFailed(err): Action {
    return {
      type: breakRuleActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static fetch(id: any) {
    return {
      type: breakRuleActionType.FETCH,
      payload: id,
    };
  }

  static fetchSuccess(response) {
    return {
      type: breakRuleActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  static fetchFailed(id: string, err) {
    return {
      type: breakRuleActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  static remove(id: string) {
    return {
      type: breakRuleActionType.REMOVE,
      payload: id,
    };
  }

  static removeSuccess(id: string) {
    return {
      type: breakRuleActionType.REMOVE_SUCCESS,
      payload: id,
    };
  }

  static removeFailed(id: string, err) {
    return {
      type: breakRuleActionType.REMOVE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }
}
