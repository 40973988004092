import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { getPermissionState, hasPermission } from '@app/reducers/auth/permission.helper';
import { Store } from '@ngrx/store';
import { Observable, combineLatest as observableCombineLatest, of as observableOf } from 'rxjs';
import { filter, mergeMap, switchMap, take } from 'rxjs/operators';

import { AuthService, authState, isAuthenticated } from '../reducers/auth/auth.service';
import { AppState } from '../reducers/index';
import { DataStartup } from './data-startup.service';
import { FeatureService } from './feature.service';

@Injectable()
export class AuthenticatedGuard {
  public constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
    private dataStartup: DataStartup,
    private featureService: FeatureService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.checkAuthenticated().pipe(
      mergeMap(([authState, authenticated, permissionState]) => {
        const hasDowngradePermission = hasPermission(
          {
            departments: 'any',
            permissions: ['Manage account', 'Edit user permissions', 'Edit contracts'],
          },
          permissionState,
        );

        if (authenticated) {
          if (state.url === '/logout' || state.url === '/login/setup-mfa-backup') {
            return observableOf(true);
          } else {
            if (authState.status === 'expired') {
              this.redirectToExpired();
              return observableOf(false);
            } else if (authState.status === 'paymentRequired') {
              this.redirectToLocked();
              return observableOf(false);
            } else if (authState.quotaActionRequired && hasDowngradePermission) {
              this.redirectToDowngradeScreen();
              return observableOf(false);
            } else {
              return this.loadApplicationData();
            }
          }
        } else {
          this.redirectToLogin(state);
          return observableOf(false);
        }
      }),
    );
  }

  private checkAuthenticated() {
    return this.store.select(authState).pipe(
      filter((state) => !state.loading),
      switchMap(() =>
        observableCombineLatest([
          this.store.select(authState),
          this.store.select(isAuthenticated),
          this.store.select(getPermissionState).pipe(
            filter((permissionState) => !!permissionState),
            take(1),
          ),
        ]),
      ),
    );
  }

  private redirectToLogin(state: RouterStateSnapshot) {
    this.authService.redirectUrl = state.url;
    this.router.navigate(['/login']);
  }

  private redirectToDowngradeScreen() {
    this.router.navigate(['/downgrade']);
  }

  private redirectToLocked() {
    this.router.navigate(['/locked']);
  }

  private redirectToExpired() {
    this.router.navigate(['/trial-expired']);
  }

  private loadApplicationData() {
    return this.dataStartup.requiredLoader();
  }
}
