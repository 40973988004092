import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { NotificationSchema } from '../../shared/schemas';
import { reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';

@Injectable()
export class NotificationApi {
  private endpoint = 'notifications/';

  public constructor(private gateway: ApiGateway) {}

  public load(): Observable<any> {
    return this.gateway.get(this.endpoint).pipe(
      map((res) => reformatListResponse('Notification', res)),
      map((data) => normalize(data, arrayOf(NotificationSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  public batchUpdate(batch, ignoreMetaMessage: boolean) {
    return this.gateway.post(this.endpoint + 'batch', batch, { headers: { ignoreMetaMessage } });
  }
}
