import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { departmentVariationPutSuccess } from '../department-variation/department-variation.action';
import { locationActionType } from '../location/location.action';
import { mergeEntities, resetEntities, updateEntitiesByField, updateEntitiesById } from '../orm';
import { DepartmentVariationSettingModel } from './../department-variation/department-variation.model';
import { departmentActionType } from './department.action';
import { DepartmentState } from './department.model';

const entityType = 'departments';

const initialState: DepartmentState = {
  items: [],
  itemsById: {},
};

export function DepartmentReducer(state: DepartmentState = initialState, action: Action): DepartmentState {
  const payload = action.payload;

  switch (action.type) {
    case locationActionType.LOAD_SUCCESS: {
      const entities = getEntities(action, 'departments');
      return resetEntities(state, entities);
    }
    case locationActionType.DEACTIVATE_SUCCESS:
      return updateEntitiesByField(state, { deleted: true }, 'location_id', payload);

    case departmentVariationPutSuccess.type: {
      const {
        department_id: departmentId,
        section,
        ...changes
      }: DepartmentVariationSettingModel = action['departmentVariation'];
      const departmentIds = Array.isArray(action['departmentsWithoutVariations'])
        ? action['departmentsWithoutVariations'].map((department) => department.id)
        : [departmentId];
      return updateEntitiesById(state, changes, ...departmentIds);
    }

    case departmentActionType.DEACTIVATE:
    case departmentActionType.ACTIVATE:
      return updateEntitiesById(state, { loading: true }, payload);

    case departmentActionType.DEACTIVATE_FAILED:
    case departmentActionType.ACTIVATE_FAILED:
      return updateEntitiesById(state, { loading: false }, payload);

    case departmentActionType.DEACTIVATE_SUCCESS:
      return updateEntitiesById(state, { loading: false, deleted: true }, payload);
    case departmentActionType.ACTIVATE_SUCCESS:
      return updateEntitiesById(state, { loading: false, deleted: false }, payload);
    case departmentActionType.BATCH_UPDATE_SUCCESS:
      return mergeEntities(state, getEntities(action, 'departments'));
    default:
      if (containsEntity(action, entityType)) {
        const entities = getEntities(action, 'departments');
        return mergeEntities(state, entities);
      }
      return state;
  }
}
