import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

export enum Platform {
  NONE,
  IOS = 'ios',
  ANDROID = 'android',
}

@Component({
  selector: 'smart-banner',
  templateUrl: './smart-banner.component.html',
  styleUrls: ['./smart-banner.component.scss'],
})
export class SmartBannerComponent implements OnInit {
  @Input()
  public title;

  @Input()
  public company = 'DifferentLab B.V.';

  @Input()
  public googleUrl;

  @Input()
  public appIcon;

  @Input()
  public appleUrl;

  public showBanner: boolean;
  public platform: Platform;
  public icon: string;
  public url: string;

  private cookieName = 'sf-smartbanner';

  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {
    let cookieValue;
    if (this.cookieService.check(this.cookieName)) {
      cookieValue = this.cookieService.get(this.cookieName);
    }

    this.icon = this.appIcon;

    this.platform = this.getPlatform();
    switch (this.platform) {
      case Platform.IOS: {
        this.url = this.appleUrl;
        break;
      }
      case Platform.ANDROID: {
        this.url = this.googleUrl;
        break;
      }
    }

    this.showBanner = this.platform !== Platform.NONE && !cookieValue;
  }

  public closeBanner() {
    this.cookieService.set(this.cookieName, 'closed', 30);
    this.showBanner = false;
  }

  private getPlatform() {
    if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
      return Platform.IOS;
    } else if (/Android/i.test(window.navigator.userAgent)) {
      return Platform.ANDROID;
    }

    return Platform.NONE;
  }
}
