import { ElementRef, Injectable } from '@angular/core';
import { addMinutes, differenceInDays, differenceInMinutes, subMinutes } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { interval as observableInterval } from 'rxjs/internal/observable/interval';
import { debounceTime, filter, map, startWith, switchMap } from 'rxjs/operators';

import { ScheduleFilterPeriod } from '../../../reducers/page-filters/page-filters.model';
import { format, roundToClosestFiveMinutes } from '../../../shared/date.helper';
import { HorizontalScrollService } from '../../../shared/horizontal-scroll/horizontal-scroll.service';
import { TimelinePeriod, TimelinePosition } from './schedule.interfaces';

export interface HeightOptions {
  forceMinHeight?: boolean;
  elements?: boolean;
  total?: boolean;
  absence?: boolean;
  availability?: boolean;
  holiday?: boolean;
  ignoreMaxPositionAddition?: boolean;
  // This should be used for any last element in a cell
  addBottomPadding?: boolean;
}

export const timelineElementHeight = 36; // Height timeline card
export const timelineElementPaddings = 5; // extra padding of timeline card
export const timelineElementTotalHeight = timelineElementHeight + timelineElementPaddings;
export const timelineTotalHeight = 18;
export const timelineAvailabilityHeight = 18;
export const timelineHolidayHeight = 18;

export const calculateTimelineHeight = (maxPosition: number, options: HeightOptions) => {
  let height = 0;
  if (options.total) {
    height += timelineTotalHeight;
  }

  if (options.availability) {
    height += timelineAvailabilityHeight;
  }
  if (options.holiday) {
    height += timelineHolidayHeight;
  }
  if (options.elements || options.absence) {
    let maxPositionAddition = 1;
    if (options.ignoreMaxPositionAddition) {
      maxPositionAddition = 0;
    }
    height += (maxPosition + maxPositionAddition) * timelineElementHeight;
  }

  // options.elements is kept here for backwards compatibility
  // TODO: use explicit addBottomPadding when needed
  // instead of relying on elements being available or not
  if (options.elements || options.addBottomPadding) {
    height += timelineElementPaddings;
  }

  if (options.forceMinHeight && height < timelineElementTotalHeight) {
    return timelineElementTotalHeight;
  }
  return height;
};

export interface Widths {
  timelineWidth: number;
  infoWidth: number;
  totalWidth: number;
  cellWidth: number;
}

@Injectable({ providedIn: 'root' })
export class TimelineService {
  private cellWidth = 84;

  public start: Date;
  public end: Date;

  private rangeType: 'hour' | 'day';

  public timelinePosition = new BehaviorSubject({ left: 0, time: '' });
  public timelineReady = new Subject();
  public timelineIndicator$ = this.timelineReady.pipe(
    filter(() => !!this.rangeType && !!this.start && !!this.end),
    debounceTime(2000),
    switchMap(() => observableInterval(30000).pipe(startWith(0))),
    map(() => {
      const now = new Date();

      const left = this.getPosition(now, now).left;
      const time = format(now, 'HH:mm');

      return { left, time };
    }),
  );

  public constructor(private scrollService: HorizontalScrollService) {}

  public calculateWidths(
    element: ElementRef,
    period: TimelinePeriod,
    sidebarOpen: boolean,
    showTotals: boolean,
  ): Widths {
    const elementWidth = element.nativeElement.getBoundingClientRect().width;

    const sidebarWidth = sidebarOpen ? 300 : 0;
    const infoWidth = 200; // Width of the info row (.timeline-info)
    const totalWidth = showTotals ? 130 : 0; // Width of the totals row (.timeline-total)
    const borderWidth = 2; // 1px border left + right
    const offset = infoWidth + totalWidth + borderWidth + sidebarWidth;

    // Must be a multiple of 12, for 5 minute drag steps.
    const minCellWidth = 84; // With 84 you can view some text and see all the buttons.

    const contentWidth = elementWidth - offset;

    this.cellWidth = Math.max(Math.floor(contentWidth / period.days.length), minCellWidth);

    this.rangeType = period.rangeType;

    const timelineWidth = period.days.length * this.cellWidth;

    // Take the extra space which is available because the contentWidth is not always the same as the width.
    const infoWidthExtraSpace = Math.max(infoWidth + (contentWidth - timelineWidth), infoWidth);

    this.scrollService.determineScrollbarWidth();
    this.timelineReady.next(null);
    return {
      timelineWidth,
      infoWidth: infoWidthExtraSpace,
      totalWidth,
      cellWidth: this.cellWidth,
    };
  }

  public getPosition(start: Date, end: Date): TimelinePosition {
    let left, width;

    if (this.rangeType === 'day') {
      left = differenceInDays(start, this.start);
      width = 1;
    }

    if (this.rangeType === 'hour') {
      start = start > this.start ? start : this.start;
      end = end < this.end ? end : this.end;

      left = differenceInMinutes(start, this.start) / 60;
      width = differenceInMinutes(end, start) / 60;
    }

    left = left * this.cellWidth;
    width = width * this.cellWidth;

    return {
      left,
      width,
    };
  }

  public getStartDateTime(left: number, start: Date) {
    let startDate = new Date(start);

    const offset = left / this.cellWidth;
    const originalOffset = differenceInMinutes(startDate, this.start) / 60;

    if (offset > originalOffset) {
      const amount = Math.round((offset - originalOffset) * 60);
      startDate = addMinutes(startDate, amount);
    } else {
      const amount = Math.round((originalOffset - offset) * 60);
      startDate = subMinutes(startDate, amount);
    }

    return roundToClosestFiveMinutes(startDate);
  }

  public getEndDateTime(width: number, start: Date, end: Date) {
    let endDate = new Date(end);

    const duration = width / this.cellWidth;
    const originalDuration = differenceInMinutes(endDate, start) / 60;

    if (duration > originalDuration) {
      const amount = Math.round((duration - originalDuration) * 60);
      endDate = addMinutes(endDate, amount);
    } else {
      const amount = Math.round((originalDuration - duration) * 60);
      endDate = subMinutes(endDate, amount);
    }

    return roundToClosestFiveMinutes(endDate);
  }

  public setPeriod(schedulePeriod: ScheduleFilterPeriod) {
    if (!schedulePeriod) {
      return;
    }
    this.start = schedulePeriod.range.start;
    this.end = schedulePeriod.range.end;
    this.timelineReady.next(null);
  }
}
