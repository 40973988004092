import { differenceInDays, startOfDay } from 'date-fns';
import omit from 'lodash-es/omit';

import { parseDate } from '../../shared/date.helper';
import { UnsafeAction as Action } from '../interfaces';
import { convertArrayToObject } from '../shared/entity.helper';
import { accountActionType } from './account.action';
import { AccountModel, AccountState } from './account.model';

const initialState: AccountState = {
  loading: false,
};

function assignState(state, ...newState) {
  return Object.assign({}, state, ...newState);
}

export function AccountReducer(state: AccountState = initialState, action: Action): AccountState {
  const payload = action.payload;

  switch (action.type) {
    case accountActionType.FETCH:
      return Object.assign({}, state, { loading: true });

    case accountActionType.FETCH_SUCCEEDED:
      const today = startOfDay(new Date());

      const startDate = startOfDay(parseDate(payload.Account.start_date));
      const trialDays = differenceInDays(startDate, today);
      const hasTrial = trialDays > 0;
      return Object.assign(
        {},
        state,
        {},
        {
          loading: false,
          account: {
            ...payload.Account,
            trialDays: trialDays < 0 ? 0 : trialDays,
            hasTrial,

            AccountType: {
              ...payload.AccountType,
            },
          },
        },
      );

    case accountActionType.FETCH_INVOICE_SETTINGS_SUCCEEDED:
    case accountActionType.PATCH_INVOICE_SETTINGS_SUCCEEDED:
      return Object.assign({}, state, {
        loading: false,
        account: {
          ...state.account,
          ...payload.Account,
        },
      });

    case accountActionType.FETCH_SUBSCRIPTION_PLAN_SUCCEEDED:
      const subscription = action.payload;
      const quotas = subscription.plan.quotas;
      return Object.assign({}, state, {
        loading: false,
        subscription: {
          ...subscription,
          plan: {
            ...subscription.plan,
            quotas: convertArrayToObject('type', quotas),
          },
        },
      });

    case accountActionType.FETCH_FAILED:
      return Object.assign({}, state, { loading: false });

    case accountActionType.PATCH:
      return Object.assign({}, state, { loading: true });

    case accountActionType.PATCH_SUCCEEDED:
    case accountActionType.TOGGLE_SUPPORT_ACCESS_SUCCESS:
      const updatedAccount: AccountModel = { ...state.account, ...payload.Account };
      return { ...state, loading: false, account: updatedAccount };

    case accountActionType.PATCH_FAILED:
      return Object.assign({}, state, { loading: false });

    case accountActionType.LOAD_FILES:
    case accountActionType.LOAD_FILES_SUCCESS:
    case accountActionType.LOAD_FILES_FAILED:

    case accountActionType.DELETE_FILE_SUCCESS:

    case accountActionType.ADD_FILE_SUCCESS:
    case accountActionType.EDIT_FILE_SUCCESS:
    case accountActionType.SET_DEFAULT_PERMISSION_GROUP:
      return {
        ...state,
        account: AccountModelReducer(state.account, action),
      };

    case accountActionType.UPDATE_ACCOUNT_MANAGER:
      return {
        ...state,
        account: {
          ...state.account,
          user_id: action.payload,
        },
      };

    default:
      return state;
  }
}

function AccountModelReducer(account: AccountModel, action: Action) {
  switch (action.type) {
    case accountActionType.LOAD_FILES:
      return {
        ...account,
        loadingFiles: true,
      };

    case accountActionType.LOAD_FILES_SUCCESS:
      const files = action.payload.entities ? action.payload.entities.accountFiles : {};
      return {
        ...account,
        loadingFiles: false,
        Files: files,
      };

    case accountActionType.LOAD_FILES_FAILED:
      return {
        ...account,
        loadingFiles: false,
      };

    case accountActionType.DELETE_FILE_SUCCESS:
      return {
        ...account,
        Files: omit(account.Files, action.payload.attachmentId),
      };

    case accountActionType.SET_DEFAULT_PERMISSION_GROUP:
      return {
        ...account,
        group_id: action.payload,
      };

    case accountActionType.ADD_FILE_SUCCESS:
    case accountActionType.EDIT_FILE_SUCCESS:
      const newFiles = action.payload.entities ? action.payload.entities.accountFiles : {};

      return {
        ...account,
        Files: {
          ...account.Files,
          ...newFiles,
        },
      };

    default:
      return account;
  }
}
