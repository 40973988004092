import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { HolidayGroupModel } from './holiday-groups.model';

@Injectable()
export class HolidayGroupsApi {
  private endpoint = 'holidays/groups';

  public constructor(private gateway: ApiGateway) {}

  public add(holidayGroup: Partial<HolidayGroupModel>): Observable<HolidayGroupModel> {
    return this.gateway.post(this.endpoint, holidayGroup);
  }

  public loadAll(): Observable<HolidayGroupModel[]> {
    return this.gateway.get(this.endpoint);
  }

  public delete(groupId: string): Observable<void> {
    return this.gateway.delete(`${this.endpoint}/${groupId}`);
  }

  public edit(holidayGroup: Partial<HolidayGroupModel>): Observable<HolidayGroupModel> {
    return this.gateway.put(`${this.endpoint}/${holidayGroup.id}`, holidayGroup);
  }
}
