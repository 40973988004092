import { createReducer, on } from '@ngrx/store';

import * as Actions from './department-variation.action';
import {
  DepartmentVariationState,
  departmentVariationStateModelEntityAdapter,
  initialState,
} from './department-variation.state';

export const reducer = createReducer<DepartmentVariationState>(
  initialState,
  on(
    Actions.departmentVariationPutRequest,
    (state): DepartmentVariationState => ({
      ...state,
      isAdding: true,
    })
  ),
  on(Actions.departmentVariationPutSuccess, (state, { departmentVariation }) =>
    departmentVariationStateModelEntityAdapter.upsertOne<DepartmentVariationState>(departmentVariation, {
      ...state,
      isAdding: false,
    })
  ),
  on(
    Actions.departmentVariationPutFailed,
    (state): DepartmentVariationState => ({
      ...state,
      isAdding: false,
    })
  ),
  on(
    Actions.departmentVariationDeleteRequest,
    (state): DepartmentVariationState => ({
      ...state,
    })
  ),
  on(Actions.departmentVariationDeleteSuccess, (state, { departmentId }) =>
    departmentVariationStateModelEntityAdapter.removeOne(departmentId, state)
  ),
  on(
    Actions.departmentVariationDeleteFailed,
    (state): DepartmentVariationState => ({
      ...state,
    })
  ),
  on(Actions.departmentVariationLoadRequest, (state) =>
    departmentVariationStateModelEntityAdapter.setAll([], {
      ...state,
      isLoading: true,
    })
  ),
  on(Actions.departmentVariationLoadSuccess, (state, { departmentVariations }) =>
    departmentVariationStateModelEntityAdapter.setAll(departmentVariations, {
      ...state,
      isLoading: false,
    })
  ),
  on(Actions.departmentVariationLoadFailed, (state) => ({
    ...state,
    isLoading: false,
  }))
);
