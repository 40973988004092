import { ModuleWithProviders, NgModule } from '@angular/core';
import { SBRadioSelectorComponent } from './sb-radio-selector.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';

@NgModule({
  imports: [FormsModule, CommonModule, RadioButtonModule],
  declarations: [SBRadioSelectorComponent],
  exports: [SBRadioSelectorComponent],
})
export class SBRadioSelectorModule {
  public static forRoot(): ModuleWithProviders<SBRadioSelectorModule> {
    return {
      ngModule: SBRadioSelectorModule,
    };
  }
}
