import { UnsafeAction as Action } from '../interfaces';
import { PageParamsState, PageStateType } from './page-params-state.model';
import defaultsDeep from 'lodash-es/defaultsDeep';
import { format } from '../../shared/date.helper';
import { pageParamsActionType } from './page-params.action';

const INITIAL_STATE = '@ngrx/store/init';

const initialState: PageParamsState = {
  timesheet: {
    period: 'day',
    mode: 'team',
    date: format(new Date(), 'yyyy-MM-dd'),
  },
  schedule: {
    period: 'week',
    mode: 'employee',
    date: format(new Date(), 'yyyy-MM-dd'),
  },
  scheduleViewOpen: false,
};

export function PageParamsReducer(state: PageParamsState = initialState, action: Action): PageParamsState {
  const { type, payload } = action;

  switch (type) {
    //make sure that missing values from the localStorage sync are added;
    case INITIAL_STATE:
      const stateWithDefaults = defaultsDeep(state, initialState) as PageParamsState;
      //assign to new object because defaultsDeep mutates state;
      return { ...stateWithDefaults };

    case pageParamsActionType.SET_FILTER: {
      if (payload.pageType === PageStateType.SCHEDULE) {
        return { ...state, schedule: payload.state };
      } else {
        return { ...state, timesheet: payload.state };
      }
    }
    case pageParamsActionType.SET_SCHEDULE_VIEW_STATUS: {
      return {
        ...state,
        scheduleViewOpen: payload,
      };
    }
    default:
      return state;
  }
}
