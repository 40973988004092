import { UnsafeAction as Action } from '../../interfaces';
import { initialState, timesheetConflictModelEntityAdapter, TimesheetConflictState } from './timesheet-conflict.state';
import { timesheetConflictActionType } from './timesheet-conflict.action';

export function TimesheetConflictReducer(
  state: TimesheetConflictState = initialState,
  action: Action
): TimesheetConflictState {
  const { payload } = action;

  switch (action.type) {
    case timesheetConflictActionType.LOAD_SUCCESS:
      return timesheetConflictModelEntityAdapter.setAll(payload, state);

    default:
      return state;
  }
}
