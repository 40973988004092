import { UnsafeAction as Action } from '../../interfaces';
import { ClockIpAddressState } from './clock-ip-address.model';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { resetEntities, mergeEntities, removeEntity } from '../orm';
import { clockIpAddressActionType } from './clock-ip-address.action';

const entityType = 'clockIpAddress';

const initialState: ClockIpAddressState = {
  items: [],
  itemsById: {},
};

export function ClockIpAddressReducer(state: ClockIpAddressState = initialState, action: Action): ClockIpAddressState {
  let payload = action.payload;

  switch (action.type) {
    case clockIpAddressActionType.LOAD_SUCCESS:
      let entities = getEntities(action, 'clockIpAddress');
      return resetEntities(state, entities);

    case clockIpAddressActionType.REMOVE:
      return removeEntity(state, payload);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, 'clockIpAddress');
        return mergeEntities(state, entities);
      }

      return state;
  }
}
