import { UnsafeAction as Action } from '../../interfaces';
import { initialState, reportFavoriteEntityAdapter, ReportFavoriteState } from './report-favorite.state';
import { reportFavoriteActionType } from './report-favorite.action';

export function ReportFavoriteReducer(state: ReportFavoriteState = initialState, action: Action) {
  const payload = action.payload;
  switch (action.type) {
    case reportFavoriteActionType.LOAD:
    case reportFavoriteActionType.DELETE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case reportFavoriteActionType.LOAD_SUCCESS: {
      return reportFavoriteEntityAdapter.setAll(payload, { ...state, isLoading: false });
    }
    case reportFavoriteActionType.DELETE_SUCCESS: {
      return reportFavoriteEntityAdapter.removeOne(payload, { ...state, isLoading: false });
    }
    case reportFavoriteActionType.LOAD_FAILED:
    case reportFavoriteActionType.DELETE_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
    default:
      return state;
  }
}
