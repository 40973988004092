import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { FeatureService } from '../../../startup/feature.service';

@Injectable()
export class FeatureFlagModalRedirectGuard {
  public constructor(
    private featureService: FeatureService,
    private router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.featureService.featureClientReady.pipe(
      filter((ready) => ready),
      take(1),
      map(() => {
        if (!route.data?.['featureFlag'] || !route.data?.['redirectTo']) {
          return false;
        }
        const isActivated = this.featureService.isFeatureActivated(route.data['featureFlag']);
        if (isActivated) {
          const params = route.paramMap?.['params'] ? route.paramMap['params'] : {};
          this.router.navigate(['', { outlets: { modal: [route.data['redirectTo'], params] } }]);
          return false;
        }

        return true;
      }),
    );
  }

  public canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }
}
