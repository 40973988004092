import { UnsafeAction as Action } from '../../interfaces';
import { ShiftState } from './shift.model';
import { shiftActionType } from './shift.action';

import { containsEntity, getEntities } from '../../shared/entity.helper';
import { locationActionType } from '../location/location.action';
import { removeEntity, updateEntitiesById, mergeEntities } from '../orm';

const entityType = 'shifts';

const initialState: ShiftState = {
  items: [],
  itemsById: {},
};

function assignState(state, ...newState) {
  return Object.assign({}, state, ...newState);
}

export function ShiftReducer(state: ShiftState = initialState, action: Action): ShiftState {
  let payload = action.payload;

  switch (action.type) {
    case locationActionType.LOAD_SUCCESS:
      return mergeEntities(state, getEntities(action, entityType));

    case shiftActionType.REMOVE: {
      return updateEntitiesById(state, { deleted: true }, payload);
    }

    case shiftActionType.REMOVE_SUCCESS: {
      return removeEntity(state, payload);
    }

    case shiftActionType.REMOVE_FAILED: {
      return updateEntitiesById(state, { deleted: false }, payload);
    }

    default:
      if (containsEntity(action, entityType)) {
        return mergeEntities(state, getEntities(action, entityType));
      }

      return state;
  }
}
