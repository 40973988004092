import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of as observableOf } from 'rxjs';
import { combineLatest, filter, map, switchMap, switchMapTo, take, tap } from 'rxjs/operators';

import { accountLoaded } from '../reducers/account/account.service';
import { authState, isAuthenticated } from '../reducers/auth/auth.service';
import {
  getPermissionState,
  hasAllPermissions,
  hasAllPermissionsForReports,
  hasPermission,
  PermissionCheck,
} from '../reducers/auth/permission.helper';
import { AppState } from '../reducers/index';
import { MessageAction } from '../reducers/message/message.action';
import { PermissionOption } from '../reducers/orm/permission/permission.model';

@Injectable()
export class PermissionGuard {
  public constructor(
    private router: Router,
    private store: Store<AppState>,
    private translate: TranslateService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.checkRoute(route);
  }

  public canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.canActivate(route);
  }

  private checkRoute(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    //grab state from authstore
    return this.checkAuthenticated().pipe(
      switchMap((authenticated) => {
        if (!authenticated) {
          return observableOf(false);
        }

        const check = route.data['permissionCheck'] || 'any';

        return this.checkPermissions(route.data['permission'], check);
      }),
      tap((authenticated) => {
        // This fixes a bug when trying to access an unauthorized page on page load
        if (!authenticated && this.router.routerState.snapshot.url === '') {
          this.router.navigate(['/']);
        }
      }),
    );
  }

  private checkAuthenticated() {
    //get authenticated state but wait for loading state to be true
    return this.store.select(authState).pipe(
      map((state) => state.loading),
      filter((loading) => !loading),
      switchMapTo(this.store.select(isAuthenticated)),
    );
  }

  private checkPermissions(permissions: PermissionOption, check: string) {
    const permissionCheck: PermissionCheck = {
      permissions: permissions,
      userId: 'me',
      departments: 'any',
    };

    return this.waitForAccountLoaded().pipe(switchMap((id) => this.checkAccess(permissionCheck, check)));
  }

  private waitForAccountLoaded() {
    return this.store.select(accountLoaded).pipe(
      filter((loaded) => !!loaded),
      take(1),
    );
  }

  private checkAccess(permissionCheck: PermissionCheck, check: string) {
    return observableOf(permissionCheck).pipe(
      combineLatest(this.loadPermissionState(), (permission, permissionState) => {
        if (check === 'reports') {
          return hasAllPermissionsForReports(permission, permissionState);
        } else if (check === 'all') {
          return hasAllPermissions(permission, permissionState);
        }

        return hasPermission(permission, permissionState);
      }),
      tap((isAllowed) => {
        if (!isAllowed) {
          this.store.dispatch(
            MessageAction.error(this.translate.instant('You do not have permission to open that page')),
          );
        }
      }),
    );
  }

  private loadPermissionState() {
    return this.store.select(getPermissionState);
  }
}
