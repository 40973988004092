import { UnsafeAction as Action } from '../interfaces';
import { Message } from './message.model';
import { messageActionType } from './message.action';

const initialState: Message[] = [];

export function MessageReducer(state: Message[] = initialState, action: Action): Message[] {
  let payload = action.payload;

  switch (action.type) {
    case messageActionType.ADD_MESSAGE: {
      return [...state, payload];
    }

    case messageActionType.REMOVE_MESSAGE: {
      return state.filter((message: Message) => message.id !== payload);
    }

    default:
      return state;
  }
}
