import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { teamActionType } from '../team/team.action';
import { departmentActionType } from '../department/department.action';
import { locationActionType } from '../location/location.action';
import { employeeActionType } from '../employee/employee.action';
import { filter, switchMap } from 'rxjs/operators';
import { authActionType } from '../../auth/auth.action';
import { mfaState } from '../../mfa/mfa.service';
import { MfaState } from '../../mfa/mfa.model';
import { SubscriptionService } from '../../../+authenticated/+reports/shared/subscriptions/subscription.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../index';
import { TimesheetHelperService } from '../../../+authenticated/+timesheet/shared/timesheet-helper.service';
import { TimesheetConflictService } from './timesheet-conflict.service';
import { AbsenceActionType } from '../absence/absence.action';
import { of } from 'rxjs';
import { timesheetActionType } from '../timesheet/timesheet.action';

@Injectable()
export class TimesheetConflictEffect {
  updateConflicts = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          AbsenceActionType.ADD_SUCCESS,
          AbsenceActionType.UPDATE_SUCCESS,
          timesheetActionType.ADD_SUCCESS,
          timesheetActionType.UPDATE_SUCCESS
        ),
        switchMap(() => {
          const request = this.timesheetConflictService.previousLoadRequest.value;
          if (!request?.from || !request?.to) {
            return of(null);
          }
          return this.timesheetHelperService.loadConflicts(this.timesheetConflictService.previousLoadRequest.value);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions: Actions,
    private timesheetHelperService: TimesheetHelperService,
    private timesheetConflictService: TimesheetConflictService
  ) {}
}
