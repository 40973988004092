import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { resetEntities, mergeEntities } from '../orm';
import { ModuleState } from './module.model';
import { moduleActionType } from './module.action';

const entityType = 'modules';

const initialState: ModuleState = {
  items: [],
  itemsById: {},
};

export function ModuleReducer(state: ModuleState = initialState, action: Action): ModuleState {
  let payload = action.payload;

  switch (action.type) {
    case moduleActionType.LOAD_SUCCESS:
      let entities = getEntities(action, 'modules');
      return resetEntities(state, entities);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, 'modules');
        return mergeEntities(state, entities);
      }

      return state;
  }
}
