import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { UnsafeAction } from '../interfaces';
import { departmentActionType } from '../orm/department/department.action';
import { AuthAction, authActionType } from './auth.action';
import { AuthApi } from './auth.api';

@Injectable()
export class AuthEffect {
  public refresh = createEffect(
    () =>
      this.actions.pipe(
        ofType(authActionType.REFRESH, departmentActionType.ACTIVATE_SUCCESS, departmentActionType.DEACTIVATE_SUCCESS),
        debounceTime(1000),
        switchMap(() =>
          this.api.status().pipe(
            map((authResponse) => AuthAction.refreshSuccess(authResponse)),
            catchError(() => observableOf(AuthAction.refreshFailed())),
          ),
        ),
      ),
    { dispatch: true },
  );

  public constructor(
    private actions: Actions<UnsafeAction>,
    private api: AuthApi,
  ) {}
}
