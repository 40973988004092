import { UnsafeAction as Action } from '../../interfaces';
import { initialState, teamDataNAdapter, TeamDataNState } from './team-data.n.state';
import { teamDataNActionType } from './team-data.n.action';
import { scheduleNActionType } from '../schedule/schedule.n.action';
import { OpenShiftModel, OpenShiftUserStatus } from '../../orm/open-shift/open-shift.model';
import omit from 'lodash-es/omit';
import { convertArrayToObject, getArrayFromActionPayload, PayloadKeyType } from '../../shared/entity.helper';
import { openShiftActionType } from '../../orm/open-shift/open-shift.action';
import cloneDeep from 'lodash-es/cloneDeep';
import { getDayListWithoutTotals } from '../../../+authenticated/+schedule/employee/schedule-employee-period/schedule-employee-period.helper';
import { parseDate } from '../../../shared/date.helper';
import { TeamDataNModel } from './team-data.n.model';
import { scheduleActionType } from '../../orm/schedule/schedule.action';
import { ScheduleModel } from '../../orm/schedule/schedule.model';
import {
  addRequiredShift,
  addRequiredShifts,
  addSchedules,
  entityInPeriod,
  exchangeAccepted,
  getFilteredShifts,
  getScheduleIdsToDelete,
  removeRequiredShift,
  removeSchedule,
  updateRequiredShift,
} from '../schedule-store.helpers';
import { requiredShiftActionType } from '../../orm/required-shift/required-shift.action';
import { RequiredShiftModel } from '../../orm/required-shift/required-shift.model';
import groupBy from 'lodash-es/groupBy';
import { pageFilterActionType } from '../../page-filters/page-filters.action';
import { SCHEDULE_ROW_HEIGHT } from '../../../+authenticated/+schedule/employee/schedule-employee-n-period/schedule-constants';
import { calculateScheduleRowHeight, calculateSingleItemRowHeight } from '../schedule-shared.helper';
import { exchangeActionType } from '../../orm/exchange/exchange.action';
import { PageFiltersState } from '../../page-filters/page-filters.model';
import isString from 'lodash-es/isString';
import { enhanceOpenShiftsWithStats } from '../../orm/open-shift/open-shift.reducer';

export function teamDataReducer(state: TeamDataNState = initialState, action: Action) {
  if (
    action.type !== pageFilterActionType.LOAD_FILTERS &&
    action.type !== scheduleNActionType.SET_PERIOD &&
    !state.minDate
  ) {
    return state;
  }

  const payload = action.payload;
  switch (action.type) {
    case pageFilterActionType.LOAD_FILTERS: {
      const filters: PageFiltersState = isString(payload) ? JSON.parse(payload) : payload;
      return {
        ...state,
        showTypes: filters?.schedule?.showTypes,
        showOptions: filters?.schedule?.showOptions,
      };
    }
    case pageFilterActionType.SET_FILTER:
    case pageFilterActionType.SET_FILTERS: {
      return processScheduleFilters(payload, state);
    }
    case scheduleNActionType.SET_PERIOD: {
      return { ...state, minDate: action.payload.minDate, maxDate: action.payload.maxDate };
    }
    case scheduleActionType.UPDATE: {
      return removeSchedule(
        payload.occurrenceId,
        payload.scheduleData.Team,
        teamDataNAdapter,
        state,
        payload.recurring
      );
    }
    case scheduleNActionType.LOAD_SUCCESS: {
      if (!payload.teamData) {
        return state;
      }
      const updatedState = teamDataNAdapter.upsertMany(payload.teamData, state);
      return reprocessData(updatedState);
    }
    case teamDataNActionType.LOAD_SUCCESS: {
      const updatedState = teamDataNAdapter.upsertMany(action.payload, state);
      return reprocessData(updatedState);
    }
    case scheduleNActionType.RESET: {
      return teamDataNAdapter.removeAll({ ...state, minDate: '', maxDate: '' });
    }
    case openShiftActionType.LOAD_SUCCESS: {
      return loadOpenShifts(payload, state);
    }
    case openShiftActionType.TAKE_SHIFT: {
      if (!payload.id || !payload.teamId) {
        return state;
      }

      return removeOpenShift(payload.id, payload.teamId, state);
    }
    case openShiftActionType.MULTI_ASSIGN:
    case openShiftActionType.ASSIGN: {
      const teamId = payload.scheduleData?.Team?.id || payload.scheduleData?.team_id || payload.scheduleData.teamId;
      return removeOpenShift(
        payload.occurrenceId,
        teamId,
        state,
        false,
        false,
        payload.scheduleData?.employeeIds?.length ?? 1
      );
    }
    case scheduleActionType.ADD_SUCCESS:
    case openShiftActionType.TAKE_SHIFT_SUCCESS:
    case openShiftActionType.MULTI_ASSIGN_SUCCESS:
    case openShiftActionType.ASSIGN_SUCCESS: {
      const schedules: ScheduleModel[] = getArrayFromActionPayload(PayloadKeyType.SCHEDULES, action.payload.entities);

      const updatedState = addSchedules(
        schedules,
        schedules[0]?.team_id,
        teamDataNAdapter,
        state,
        createDefaultTeamData
      ) as TeamDataNState;

      if (action?.updateEvent) {
        const { occurrenceId, teamId, employeeIds } = action?.updateEvent;
        if (!occurrenceId || !teamId || !employeeIds?.length) {
          return updatedState;
        }
        return handleOpenShiftAssignSuccess(occurrenceId, teamId, employeeIds[0], updatedState);
      }

      return updatedState;
    }

    case scheduleActionType.UPDATE_SUCCESS: {
      const updatedState = handleOpenShiftChanges(payload, state);
      const schedules: ScheduleModel[] = getArrayFromActionPayload(PayloadKeyType.SCHEDULES, action.payload.entities);
      return addSchedules(schedules, schedules[0]?.team_id, teamDataNAdapter, updatedState, createDefaultTeamData);
    }

    case openShiftActionType.ADD: {
      return addOpenShift(action.payload.loadingId, action.payload.openShiftData, true, state);
    }

    case openShiftActionType.ADD_SUCCESS:
    case openShiftActionType.UPDATE_SUCCESS: {
      return handleOpenShiftChanges(payload, state);
    }

    case openShiftActionType.UPDATE: {
      const openShift: OpenShiftModel = action.payload.openShiftData;
      return updateOpenShift(action.payload.loadingId, action.payload.occurrenceId, openShift, state, true);
    }

    case requiredShiftActionType.ADD: {
      return addRequiredShift(
        payload.loadingId,
        payload.requiredShiftData,
        true,
        state,
        teamDataNAdapter,
        createDefaultTeamData
      );
    }
    case requiredShiftActionType.UPDATE: {
      if (payload.requiredShiftData?.Team && !payload.requiredShiftData.team_id) {
        return removeRequiredShift(
          payload.occurrenceId,
          payload.requiredShiftData.Team,
          state,
          teamDataNAdapter,
          false
        );
      }

      return updateRequiredShift(
        payload.loadingId,
        payload.occurrenceId,
        payload.requiredShiftData,
        state,
        true,
        teamDataNAdapter,
        createDefaultTeamData
      );
    }
    case requiredShiftActionType.ADD_SUCCESS:
    case requiredShiftActionType.UPDATE_SUCCESS: {
      return handleRequiredShiftChanges(payload, state);
    }
    case requiredShiftActionType.REMOVE: {
      return removeTeamRequiredShift(payload, state, payload.optimizedData?.recurring);
    }
    case scheduleActionType.REMOVE: {
      return removeSchedule(payload.occurrenceId, payload.optimizedData.teamId, teamDataNAdapter, state);
    }
    case openShiftActionType.REMOVE: {
      return removeOpenShift(
        payload.occurrenceId,
        payload?.optimizedData?.teamId,
        state,
        true,
        payload?.optimizedData?.recurring
      );
    }

    case exchangeActionType.ACCEPT_INCOMING_SUCCESS:
    case exchangeActionType.ACCEPTED_BY_SUPERVISOR_SUCCESS: {
      const exchange = payload.entities.exchanges[payload.result];
      const teamId = exchange.NewRoster.team_id;
      return exchangeAccepted(exchange, teamId, state, teamDataNAdapter, createDefaultTeamData);
    }

    default:
      return state;
  }
}

const processScheduleFilters = (payload, state: TeamDataNState): TeamDataNState => {
  if (payload.page !== 'schedule') {
    return state;
  }

  let departmentFilteredShiftIds = state.shiftFilters ?? [];

  if (payload?.filter?.includes('shifts')) {
    const filter = payload.filter.split('.');
    departmentFilteredShiftIds = {
      ...state.shiftFilters,
      [filter[1]]: payload.filterValue,
    };
  }

  const showTypes = payload?.filters?.showTypes;
  const showOptions = payload?.filters?.showOptions;
  const updatedState = {
    ...state,
    shiftFilters: departmentFilteredShiftIds,
    showTypes: showTypes || state.showTypes,
    showOptions: showOptions || state.showOptions,
  };

  return reprocessData(updatedState);
};

const reprocessData = (state: TeamDataNState): TeamDataNState => {
  const data = Object.values(state.entities);
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const teamsToBeUpdated: TeamDataNModel[] = [];
  data.forEach((scheduleData: TeamDataNModel) => {
    const openShifts = enhanceOpenShiftsWithStats(getFilteredShifts(scheduleData.openShifts, state.shiftFilters));
    const requiredShifts = getFilteredShifts(scheduleData.requiredShifts, state.shiftFilters);
    const schedules = getFilteredShifts(scheduleData.schedules, state.shiftFilters);

    teamsToBeUpdated.push({
      ...scheduleData,
      filteredOpenShifts: openShifts,
      filteredRequiredShifts: requiredShifts,
      filteredSchedules: schedules,
      requiredShiftsRowHeight: calculateSingleItemRowHeight(requiredShifts, totals),
      openShiftsRowHeight: calculateSingleItemRowHeight(openShifts, totals),
      scheduleRowHeight: calculateScheduleRowHeight(
        {
          schedules: schedules,
          absence: {},
          availabilities: {},
        },
        totals,
        state.showTypes,
        state.showOptions
      ),
    });
  });
  return teamDataNAdapter.setAll(teamsToBeUpdated, state);
};

const loadOpenShifts = (payload, state: TeamDataNState) => {
  const openShifts: OpenShiftModel[] = enhanceOpenShiftsWithStats(
    getArrayFromActionPayload(PayloadKeyType.OPENSHIFTS, payload.entities)
  );
  if (!openShifts?.length) {
    return state;
  }

  const groupedOpenShifts = groupBy(openShifts, 'team_id');

  let updatedState = state;
  for (const [key, val] of Object.entries(groupedOpenShifts)) {
    updatedState = addOpenShifts(key, val, updatedState);
  }

  return updatedState;
};

const removeTeamRequiredShift = (payload, state: TeamDataNState, recurring: boolean = false) => {
  if (!payload.optimizedData?.teamId) {
    return state;
  }
  return removeRequiredShift(payload.occurrenceId, payload.optimizedData.teamId, state, teamDataNAdapter, recurring);
};

const handleRequiredShiftChanges = (payload, state: TeamDataNState) => {
  const requiredShifts: RequiredShiftModel[] = getArrayFromActionPayload(
    PayloadKeyType.REQUIRED_SHIFTS,
    payload.entities
  );

  if (!requiredShifts[0].team_id) {
    return state;
  }

  let updatedState = state;
  if (payload.loadingId) {
    updatedState = <TeamDataNState>(
      removeRequiredShift(payload.loadingId, requiredShifts[0].team_id, state, teamDataNAdapter, payload.recurring)
    );
  }

  return addRequiredShifts(
    requiredShifts[0].team_id,
    requiredShifts,
    updatedState,
    teamDataNAdapter,
    createDefaultTeamData
  );
};

const handleOpenShiftChanges = (payload, state: TeamDataNState) => {
  const openShifts: OpenShiftModel[] = enhanceOpenShiftsWithStats(
    getArrayFromActionPayload(PayloadKeyType.OPENSHIFTS, payload.entities)
  );
  if (!openShifts[0]?.team_id || openShifts?.length === 0) {
    return state;
  }

  let updatedState = state;
  if (payload.loadingId) {
    const recurring = payload.recurring;
    updatedState = removeOpenShift(payload.loadingId, openShifts[0].team_id, state, true, recurring);
  }
  return addOpenShifts(openShifts[0].team_id, openShifts, updatedState);
};

const addOpenShifts = (teamId: string, shifts: OpenShiftModel[], state: TeamDataNState) => {
  const filteredOpenShifts = shifts.filter((openShift: OpenShiftModel) =>
    entityInPeriod(state.minDate, state.maxDate, openShift.date)
  );

  if (filteredOpenShifts?.length === 0) {
    return state;
  }

  const teamData = state.entities[teamId];
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const updatedData: TeamDataNModel = {
    ...(teamData ?? createDefaultTeamData(teamId)),
    id: teamId,
    openShifts: {
      ...teamData?.openShifts,
      ...convertArrayToObject('occurrence_id', filteredOpenShifts),
    },
  };
  updatedData.filteredOpenShifts = enhanceOpenShiftsWithStats(
    getFilteredShifts(updatedData.openShifts, state.shiftFilters)
  );
  updatedData.openShiftsRowHeight = calculateSingleItemRowHeight(updatedData.filteredOpenShifts, totals);
  updatedData.requiredShiftsRowHeight = calculateSingleItemRowHeight(updatedData.requiredShifts, totals);

  return teamDataNAdapter.setOne(updatedData, state);
};

const handleOpenShiftAssignSuccess = (
  occurrenceId: string,
  teamId: string,
  employeeId: string,
  state: TeamDataNState
) => {
  const teamData = cloneDeep(state.entities[teamId]);

  if (!teamData) {
    return state;
  }

  const openShiftToUpdate = teamData.openShifts[occurrenceId];

  if (!openShiftToUpdate) {
    return state;
  }

  openShiftToUpdate.EmployeeStatus = openShiftToUpdate.EmployeeStatus.map((empStatus) => ({
    ...empStatus,
    status: empStatus.employee_id === employeeId ? OpenShiftUserStatus.ASSIGNED : empStatus.status,
  }));

  return teamDataNAdapter.updateOne(
    {
      id: teamId,
      changes: {
        filteredOpenShifts: enhanceOpenShiftsWithStats(teamData.filteredOpenShifts),
      },
    },
    state
  );
};

const addOpenShift = (occurrenceId: string, shift: OpenShiftModel, loading: boolean, state: TeamDataNState) => {
  if (!entityInPeriod(state.minDate, state.maxDate, shift.date)) {
    return state;
  }

  const teamData = state.entities[shift.team_id];
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const updatedShift = {
    ...shift,
    occurrence_id: occurrenceId,
    instances_remaining: !shift.instances_remaining ? 1 : shift.instances_remaining,
    loading,
  };

  const updatedData: TeamDataNModel = {
    ...(teamData ?? createDefaultTeamData(shift.team_id)),
    id: shift.team_id,
    openShifts: {
      ...teamData?.openShifts,
      [occurrenceId]: updatedShift,
    },
  };

  updatedData.filteredOpenShifts = enhanceOpenShiftsWithStats(
    getFilteredShifts(updatedData.openShifts, state.shiftFilters)
  );
  updatedData.openShiftsRowHeight = calculateSingleItemRowHeight(updatedData.filteredOpenShifts, totals);
  updatedData.requiredShiftsRowHeight = calculateSingleItemRowHeight(updatedData.requiredShifts, totals);

  return teamDataNAdapter.setOne(updatedData, state);
};

const removeOpenShift = (
  occurrenceId: string,
  teamId: string,
  state: TeamDataNState,
  hardDelete: boolean = false,
  recurring: boolean = false,
  instancesToRemove = 1
) => {
  const teamData: TeamDataNModel = state.entities[teamId];
  if (!teamData || !teamData.openShifts[occurrenceId]) {
    return state;
  }
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const shiftToDelete = {
    ...teamData.openShifts[occurrenceId],
    instances_remaining: teamData.openShifts[occurrenceId].instances_remaining - instancesToRemove,
  };

  let updatedOpenShifts: {};
  if (hardDelete || shiftToDelete.instances_remaining <= 0) {
    const curSchedule = teamData.openShifts[occurrenceId];
    const idsToDelete = [occurrenceId];
    if (curSchedule && recurring) {
      idsToDelete.push(...getScheduleIdsToDelete(curSchedule, teamData.openShifts));
    }

    updatedOpenShifts = {
      ...omit(teamData.openShifts, idsToDelete),
    };
  } else {
    updatedOpenShifts = {
      ...teamData.openShifts,
      [occurrenceId]: shiftToDelete,
    };
  }
  const updatedData: TeamDataNModel = {
    ...teamData,
    openShifts: updatedOpenShifts,
  };
  updatedData.filteredOpenShifts = enhanceOpenShiftsWithStats(
    getFilteredShifts(updatedData.openShifts, state.shiftFilters)
  );
  updatedData.openShiftsRowHeight = calculateSingleItemRowHeight(updatedData.filteredOpenShifts, totals);
  return teamDataNAdapter.setOne(updatedData, state);
};

const updateOpenShift = (
  loadingId: string,
  occurrenceId: string,
  openShift: OpenShiftModel,
  state: TeamDataNState,
  loading: boolean = false
) => {
  let teamData = cloneDeep(state.entities[openShift.team_id]);
  if (!teamData) {
    teamData = createDefaultTeamData(openShift.team_id);
  }

  let oldShift = teamData.openShifts[occurrenceId];
  if (openShift.TeamJoin && openShift.team_id !== openShift.TeamJoin?.id) {
    oldShift = state.entities[openShift.TeamJoin?.id].openShifts[occurrenceId];

    state = removeOpenShift(occurrenceId, openShift.TeamJoin.id, state, true);
  }

  if (!entityInPeriod(state.minDate, state.maxDate, openShift.date)) {
    return state;
  }

  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });
  teamData.openShifts = omit(teamData.openShifts, occurrenceId);
  teamData.openShifts[loadingId] = { ...oldShift, ...openShift, loading };
  teamData.filteredOpenShifts = enhanceOpenShiftsWithStats(getFilteredShifts(teamData.openShifts, state.shiftFilters));
  teamData.openShiftsRowHeight = calculateSingleItemRowHeight(teamData.openShifts, totals);
  return teamDataNAdapter.setOne(teamData, state);
};

const createDefaultTeamData = (teamId: string): TeamDataNModel => ({
  id: teamId,
  openShifts: {},
  filteredOpenShifts: [],
  requiredShifts: {},
  filteredRequiredShifts: [],
  schedules: {},
  filteredSchedules: [],
  teamDays: {},
  openShiftsRowHeight: SCHEDULE_ROW_HEIGHT,
  requiredShiftsRowHeight: SCHEDULE_ROW_HEIGHT,
  scheduleRowHeight: SCHEDULE_ROW_HEIGHT,
});
