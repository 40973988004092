import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { resetEntities, mergeEntities } from '../orm';
import { PermissionState } from './permission.model';
import { permissionActionType } from './permission.action';

const entityType = 'permissions';

const initialState: PermissionState = {
  items: [],
  itemsById: {},
};

export function PermissionReducer(state: PermissionState = initialState, action: Action): PermissionState {
  let payload = action.payload;

  switch (action.type) {
    case permissionActionType.LOAD_SUCCESS:
      let entities = getEntities(action, entityType);
      return resetEntities(state, entities);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}
