import { authActionType } from './auth/auth.action';
import { ActionReducer } from '@ngrx/store';

/**
 * Reset application state
 * @param reducer
 * @returns {(state:any, action:any)=>(any|any)}
 */
export function reset(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    try {
      if (action.type === authActionType.CLEAR_SESSION) {
        const { messages } = state;
        state = { messages };
      }

      return reducer(state, action);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
}
