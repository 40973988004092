import { ActionReducerMap } from '@ngrx/store';
import { employabilityReducer } from '@reducers/orm/employability/employability.reducer';
import { EmployabilityState } from '@reducers/orm/employability/employability.state';

import { ReportApi } from '../../+authenticated/+reports/shared/api/ReportApi';
import { departmentDataReducer } from '../store/departmentdata/department-data.n.reducer';
import { DepartmentDataNState } from '../store/departmentdata/department-data.n.state';
import { scheduleNReducer } from '../store/schedule/schedule.n.reducer';
import { ScheduleNState } from '../store/schedule/schedule.n.state';
import { teamDataReducer } from '../store/teamdata/team-data.n.reducer';
import { TeamDataNState } from '../store/teamdata/team-data.n.state';
import * as AbsencePolicy from './absence-policy';
import { AbsenceRestrictionApi } from './absence-restriction/absence-restriction.api';
import { AbsenceRestrictionReducer } from './absence-restriction/absence-restriction.reducer';
import { AbsenceRestrictionService } from './absence-restriction/absence-restriction.service';
import { AbsenceRestrictionState } from './absence-restriction/absence-restriction.state';
import { AbsenceApi } from './absence/absence.api';
import { AbsenceState } from './absence/absence.model';
import { AbsenceReducer } from './absence/absence.reducer';
import { AbsenceService } from './absence/absence.service';
import { AbsenteeOptionApi } from './absentee-option/absentee-option.api';
import { AbsenteeOptionState } from './absentee-option/absentee-option.model';
import { AbsenteeOptionReducer } from './absentee-option/absentee-option.reducer';
import { AbsenteeOptionService } from './absentee-option/absentee-option.service';
import { AppTokenApi } from './app-token/app-token.api';
import { AppTokenState } from './app-token/app-token.model';
import { AppTokenReducer } from './app-token/app-token.reducer';
import { AppTokenService } from './app-token/app-token.service';
import { AttachmentApi } from './attachment.api';
import { AvailabilityApi } from './availability/availability.api';
import { AvailabilityModel } from './availability/availability.model';
import { AvailabilityReducer } from './availability/availability.reducer';
import { AvailabilityService } from './availability/availability.service';
import { BreakRuleApi } from './break-rule/break-rule.api';
import { BreakRuleState } from './break-rule/break-rule.model';
import { BreakRuleReducer } from './break-rule/break-rule.reducer';
import { BreakRuleService } from './break-rule/break-rule.service';
import { ClockIpAddressApi } from './clock-ip-address/clock-ip-address.api';
import { ClockIpAddressState } from './clock-ip-address/clock-ip-address.model';
import { ClockIpAddressReducer } from './clock-ip-address/clock-ip-address.reducer';
import { ClockIpAddressService } from './clock-ip-address/clock-ip-address.service';
import { ClockLocationApi } from './clock-location/clock-location.api';
import { ClockLocationState } from './clock-location/clock-location.model';
import { ClockLocationReducer } from './clock-location/clock-location.reducer';
import { ClockLocationService } from './clock-location/clock-location.service';
import { CommentApi } from './comment/comment.api';
import { CommentModel } from './comment/comment.model';
import { CommentReducer } from './comment/comment.reducer';
import { CommentService } from './comment/comment.service';
import { ContractTypeApi } from './contract-type/contract-type.api';
import { ContractTypeModel } from './contract-type/contract-type.model';
import { ContractTypeReducer } from './contract-type/contract-type.reducer';
import { ContractTypeService } from './contract-type/contract-type.service';
import { ContractApi } from './contract/contract.api';
import { ContractState } from './contract/contract.model';
import { ContractReducer } from './contract/contract.reducer';
import { ContractService } from './contract/contract.service';
import { CorrectionApi } from './correction/correction.api';
import { CorrectionModel } from './correction/correction.model';
import { CorrectionReducer } from './correction/correction.reducer';
import { CorrectionService } from './correction/correction.service';
import { CustomFieldsApi } from './custom-fields/custom-fields.api';
import { CustomFieldsReducer } from './custom-fields/custom-fields.reducer';
import { CustomFieldsService } from './custom-fields/custom-fields.service';
import { CustomFieldsState } from './custom-fields/custom-fields.state';
import { DaylogApi } from './daylog/daylog.api';
import { DaylogState } from './daylog/daylog.model';
import { DaylogReducer } from './daylog/daylog.reducer';
import { DaylogService } from './daylog/daylog.service';
import * as DepartmentVariation from './department-variation';
import { DepartmentApi } from './department/department.api';
import { DepartmentModel } from './department/department.model';
import { DepartmentReducer } from './department/department.reducer';
import { DepartmentService } from './department/department.service';
import { EmployeeApi } from './employee/employee.api';
import { EmployeeModel } from './employee/employee.model';
import { EmployeeReducer } from './employee/employee.reducer';
import { EmployeeService } from './employee/employee.service';
import { EventApi } from './event/event.api';
import { EventModel } from './event/event.model';
import { EventReducer } from './event/event.reducer';
import { EventService } from './event/event.service';
import { ExchangeApi } from './exchange/exchange.api';
import { ExchangeModel } from './exchange/exchange.model';
import { ExchangeReducer } from './exchange/exchange.reducer';
import { ExchangeService } from './exchange/exchange.service';
import { HolidayGroupsApi } from './holiday-groups/holiday-groups.api';
import { HolidayGroupsEffect } from './holiday-groups/holiday-groups.effect';
import { holidayGroupsReducer } from './holiday-groups/holiday-groups.reducer';
import { HolidayGroupsState } from './holiday-groups/holiday-groups.state';
import { HolidayApi } from './holiday/holiday.api';
import { HolidayModel } from './holiday/holiday.model';
import { HolidayReducer } from './holiday/holiday.reducer';
import { HolidayService } from './holiday/holiday.service';
import { IntegrationAppReducer } from './integration-apps/integration-app.reducer';
import { IntegrationAppState } from './integration-apps/integration-app.state';
import { IntegrationApi } from './integration/integration.api';
import { IntegrationState } from './integration/integration.model';
import { IntegrationReducer } from './integration/integration.reducer';
import { IntegrationService } from './integration/integration.service';
import { KioskApi } from './kiosk/kiosk.api';
import { KioskReducer } from './kiosk/kiosk.reducer';
import { KioskService } from './kiosk/kiosk.service';
import { KioskState } from './kiosk/kiosk.state';
import { LocationApi } from './location/location.api';
import { LocationModel } from './location/location.model';
import { LocationReducer } from './location/location.reducer';
import { LocationService } from './location/location.service';
import { ModuleApi } from './module/module.api';
import { ModuleState } from './module/module.model';
import { ModuleReducer } from './module/module.reducer';
import { ModuleService } from './module/module.service';
import { NewsItemApi } from './news-item/news-item.api';
import { NewsItemModel } from './news-item/news-item.model';
import { NewsItemReducer } from './news-item/news-item.reducer';
import { NewsItemService } from './news-item/news-item.service';
import { NotificationApi } from './notification/notification.api';
import { NotificationState } from './notification/notification.model';
import { NotificationReducer } from './notification/notification.reducer';
import { NotificationService } from './notification/notification.service';
import { OpenShiftApi } from './open-shift/open-shift.api';
import { OpenShiftReducer } from './open-shift/open-shift.reducer';
import { OpenShiftService } from './open-shift/open-shift.service';
import { OpenShiftState } from './open-shift/open-shift.state';
import { OvertimePolicyApi } from './overtime-policy/overtime-policy.api';
import { overtimePolicyReducer } from './overtime-policy/overtime-policy.reducer';
import { OvertimePolicyService } from './overtime-policy/overtime-policy.service';
import { OvertimePolicyState } from './overtime-policy/overtime-policy.state';
import { PermissionGroupReducer } from './permission-group/permision-group.reducer';
import { PermissionGroupApi } from './permission-group/permission-group.api';
import { PermissionGroupState } from './permission-group/permission-group.model';
import { PermissionGroupService } from './permission-group/permission-group.service';
import { PermissionApi } from './permission/permission.api';
import { PermissionState } from './permission/permission.model';
import { PermissionReducer } from './permission/permission.reducer';
import { PermissionService } from './permission/permission.service';
import { RateCardApi } from './rate-card/rate-card.api';
import { RateCardEffect } from './rate-card/rate-card.effect';
import { RateCardModel } from './rate-card/rate-card.model';
import { RateCardReducer } from './rate-card/rate-card.reducer';
import { RateCardService } from './rate-card/rate-card.service';
import { ReportFavoriteApi } from './report-favorites/report-favorite.api';
import { ReportFavoriteReducer } from './report-favorites/report-favorite.reducer';
import { ReportFavoriteService } from './report-favorites/report-favorite.service';
import { ReportFavoriteState } from './report-favorites/report-favorite.state';
import { ReportRecurringApi } from './report-recurring/report-recurring.api';
import { reportRecurringReducer } from './report-recurring/report-recurring.reducer';
import { ReportRecurringService } from './report-recurring/report-recurring.service';
import { ReportRecurringState } from './report-recurring/report-recurring.state';
import { ReportReducer } from './reports/report.reducer';
import { ReportService } from './reports/report.service';
import { ReportState } from './reports/report.state';
import { RequiredShiftFulfillmentApi } from './required-shift-fulfillment/required-shift-fulfillment-api.service';
import { RequiredShiftFulfillmentReducer } from './required-shift-fulfillment/required-shift-fulfillment.reducer';
import { RequiredShiftFulfillmentService } from './required-shift-fulfillment/required-shift-fulfillment.service';
import { RequiredShiftFulfillmentState } from './required-shift-fulfillment/required-shift-fulfillment.state';
import { RequiredShiftApi } from './required-shift/required-shift.api';
import { RequiredShiftModel } from './required-shift/required-shift.model';
import { RequiredShiftReducer } from './required-shift/required-shift.reducer';
import { RequiredShiftService } from './required-shift/required-shift.service';
import { ScheduleComplianceApi } from './schedule-compliance/schedule-compliance.api';
import { ScheduleComplianceReducer } from './schedule-compliance/schedule-compliance.reducer';
import { ScheduleComplianceService } from './schedule-compliance/schedule-compliance.service';
import { ScheduleComplianceState } from './schedule-compliance/schedule-compliance.state';
import { ScheduleConflictApi } from './schedule-conflict/schedule-conflict.api';
import { ScheduleConflictReducer } from './schedule-conflict/schedule-conflict.reducer';
import { ScheduleConflictService } from './schedule-conflict/schedule-conflict.service';
import { ScheduleConflictState } from './schedule-conflict/schedule-conflict.state';
import { RosterApi } from './schedule/roster.api';
import { ScheduleApi } from './schedule/schedule.api';
import { ScheduleModel } from './schedule/schedule.model';
import { ScheduleReducer } from './schedule/schedule.reducer';
import { ScheduleService } from './schedule/schedule.service';
import { SharedModelState } from './shared.model.state';
import { ShiftApi } from './shift/shift.api';
import { ShiftModel } from './shift/shift.model';
import { ShiftReducer } from './shift/shift.reducer';
import { ShiftService } from './shift/shift.service';
import { SkillGroupApi } from './skill-group/skill-group.api';
import { SkillGroupState } from './skill-group/skill-group.model';
import { SkillGroupReducer } from './skill-group/skill-group.reducer';
import { SkillGroupService } from './skill-group/skill-group.service';
import { SkillApi } from './skill/skill.api';
import { SkillState } from './skill/skill.model';
import { SkillReducer } from './skill/skill.reducer';
import { SkillService } from './skill/skill.service';
import { SurchargeApi } from './surcharge/surcharge.api';
import { SurchargeModel } from './surcharge/surcharge.model';
import { SurchargeReducer } from './surcharge/surcharge.reducer';
import { SurchargeService } from './surcharge/surcharge.service';
import { TeamDayApi } from './team-day/team-day.api';
import { TeamDayState } from './team-day/team-day.model';
import { TeamDayReducer } from './team-day/team-day.reducer';
import { TeamDayService } from './team-day/team-day.service';
import { TeamApi } from './team/team.api';
import { TeamModel } from './team/team.model';
import { TeamReducer } from './team/team.reducer';
import { TeamService } from './team/team.service';
import { TimeOffBalanceApi } from './time-off-balance/time-off-balance.api';
import { TimeOffBalanceReducer } from './time-off-balance/time-off-balance.reducer';
import { TimeOffBalanceService } from './time-off-balance/time-off-balance.service';
import { TimeOffBalanceState } from './time-off-balance/time-off-balance.state';
import { TimesheetConflictApi } from './timesheet-conflict/timesheet-conflict.api';
import { TimesheetConflictReducer } from './timesheet-conflict/timesheet-conflict.reducer';
import { TimesheetConflictService } from './timesheet-conflict/timesheet-conflict.service';
import { TimesheetConflictState } from './timesheet-conflict/timesheet-conflict.state';
import { TimesheetApi } from './timesheet/timesheet.api';
import { TimesheetModel } from './timesheet/timesheet.model';
import { TimesheetReducer } from './timesheet/timesheet.reducer';
import { TimesheetService } from './timesheet/timesheet.service';
import { UserGuideEffect, userGuideReducer, UserGuideState } from './user-guide';
import { WeatherState } from './weather/weather-state';
import { WeatherApi } from './weather/weather.api';
import { WeatherReducer } from './weather/weather.reducer';
import { WeatherService } from './weather/weather.service';

export interface ObjectMap<T> {
  [id: string]: T;
}

export interface OrmState {
  locations: SharedModelState<LocationModel>;
  departments: SharedModelState<DepartmentModel>;
  teams: SharedModelState<TeamModel>;
  events: SharedModelState<EventModel>;
  openshifts: OpenShiftState;
  requiredshifts: SharedModelState<RequiredShiftModel>;
  exchanges: SharedModelState<ExchangeModel>;
  shifts: SharedModelState<ShiftModel>;
  availabilities: SharedModelState<AvailabilityModel>;
  timesheets: SharedModelState<TimesheetModel>;
  schedules: SharedModelState<ScheduleModel>;
  employees: SharedModelState<EmployeeModel>;
  newsItems: SharedModelState<NewsItemModel>;
  comments: SharedModelState<CommentModel>;
  corrections: SharedModelState<CorrectionModel>;
  contracts: ContractState;
  contractTypes: SharedModelState<ContractTypeModel>;
  rateCards: SharedModelState<RateCardModel>;
  surcharges: SharedModelState<SurchargeModel>;
  holidays: SharedModelState<HolidayModel>;
  holidayGroups: HolidayGroupsState;
  modules: ModuleState;
  permissions: PermissionState;
  permissionGroups: PermissionGroupState;
  clockLocations: ClockLocationState;
  clockIpAddress: ClockIpAddressState;
  kioskEnvironments: KioskState;
  breakRules: BreakRuleState;
  notifications: NotificationState;
  absenteeOptions: AbsenteeOptionState;
  absence: AbsenceState;
  daylogs: DaylogState;
  teamDays: TeamDayState;
  integrations: IntegrationState;
  appToken: AppTokenState;
  skills: SkillState;
  skillGroups: SkillGroupState;
  weather: WeatherState;
  integrationApps: IntegrationAppState;
  customFields: CustomFieldsState;
  reports: ReportState;
  absenceRestrictions: AbsenceRestrictionState;
  absencePolicies: AbsencePolicy.AbsencePolicyState;
  overtimePolicies: OvertimePolicyState;
  timeOffBalances: TimeOffBalanceState;
  scheduleN: ScheduleNState;
  teamData: TeamDataNState;
  departmentData: DepartmentDataNState;
  scheduleCompliances: ScheduleComplianceState;
  requiredShiftFulfillment: RequiredShiftFulfillmentState;
  scheduleConflicts: ScheduleConflictState;
  reportFavorites: ReportFavoriteState;
  timesheetConflicts: TimesheetConflictState;
  reportRecurring: ReportRecurringState;
  employability: EmployabilityState;
  userGuides: UserGuideState;
  departmentVariations: DepartmentVariation.DepartmentVariationState;
}

export const OrmProviders = [
  AttachmentApi,
  LocationService,
  LocationApi,
  DepartmentService,
  DepartmentApi,
  EmployeeService,
  EmployeeApi,
  TeamService,
  TeamApi,
  EventService,
  EventApi,
  ShiftService,
  ShiftApi,
  ExchangeService,
  ExchangeApi,
  OpenShiftService,
  OpenShiftApi,
  RequiredShiftService,
  RequiredShiftApi,
  AvailabilityService,
  AvailabilityApi,
  ScheduleService,
  ScheduleApi,
  RosterApi,
  TimesheetService,
  TimesheetApi,
  ContractTypeService,
  ContractTypeApi,
  ContractService,
  ContractApi,
  NewsItemService,
  NewsItemApi,
  CommentService,
  CommentApi,
  CorrectionService,
  CorrectionApi,
  RateCardService,
  RateCardApi,
  HolidayService,
  HolidayApi,
  HolidayGroupsApi,
  SurchargeService,
  SurchargeApi,
  ModuleService,
  ModuleApi,
  PermissionApi,
  PermissionService,
  PermissionGroupApi,
  PermissionGroupService,
  ClockLocationApi,
  ClockLocationService,
  ClockIpAddressApi,
  ClockIpAddressService,
  KioskService,
  KioskApi,
  BreakRuleApi,
  BreakRuleService,
  NotificationApi,
  NotificationService,
  AbsenteeOptionApi,
  AbsenteeOptionService,
  AbsenceApi,
  AbsenceService,
  DaylogApi,
  DaylogService,
  TeamDayApi,
  TeamDayService,
  IntegrationService,
  IntegrationApi,
  AppTokenApi,
  AppTokenService,
  SkillApi,
  SkillService,
  SkillGroupApi,
  SkillGroupService,
  WeatherService,
  WeatherApi,
  CustomFieldsService,
  CustomFieldsApi,
  ReportService,
  ReportApi,
  ReportFavoriteService,
  ReportFavoriteApi,
  ReportRecurringService,
  ReportRecurringApi,
  AbsenceRestrictionService,
  AbsenceRestrictionApi,
  AbsencePolicy.AbsencePolicyApi,
  OvertimePolicyService,
  OvertimePolicyApi,
  TimeOffBalanceService,
  TimeOffBalanceApi,
  ScheduleComplianceService,
  ScheduleComplianceApi,
  RequiredShiftFulfillmentService,
  RequiredShiftFulfillmentApi,
  ScheduleConflictService,
  ScheduleConflictApi,
  TimesheetConflictService,
  TimesheetConflictApi,
  DepartmentVariation.DepartmentVariationApi,
];

export const ormReducers: ActionReducerMap<OrmState> = {
  locations: LocationReducer,
  departments: DepartmentReducer,
  teams: TeamReducer,
  events: EventReducer,
  openshifts: OpenShiftReducer,
  requiredshifts: RequiredShiftReducer,
  exchanges: ExchangeReducer,
  shifts: ShiftReducer,
  availabilities: AvailabilityReducer,
  employees: EmployeeReducer,
  schedules: ScheduleReducer,
  newsItems: NewsItemReducer,
  comments: CommentReducer,
  timesheets: TimesheetReducer,
  contractTypes: ContractTypeReducer,
  contracts: ContractReducer,
  corrections: CorrectionReducer,
  rateCards: RateCardReducer,
  holidays: HolidayReducer,
  holidayGroups: holidayGroupsReducer,
  surcharges: SurchargeReducer,
  modules: ModuleReducer,
  permissions: PermissionReducer,
  permissionGroups: PermissionGroupReducer,
  clockLocations: ClockLocationReducer,
  clockIpAddress: ClockIpAddressReducer,
  kioskEnvironments: KioskReducer,
  breakRules: BreakRuleReducer,
  notifications: NotificationReducer,
  absenteeOptions: AbsenteeOptionReducer,
  absence: AbsenceReducer,
  daylogs: DaylogReducer,
  teamDays: TeamDayReducer,
  integrations: IntegrationReducer,
  appToken: AppTokenReducer,
  skills: SkillReducer,
  skillGroups: SkillGroupReducer,
  weather: WeatherReducer,
  integrationApps: IntegrationAppReducer,
  customFields: CustomFieldsReducer,
  reports: ReportReducer,
  absenceRestrictions: AbsenceRestrictionReducer,
  [AbsencePolicy.featureKey]: AbsencePolicy.reducer,
  overtimePolicies: overtimePolicyReducer,
  timeOffBalances: TimeOffBalanceReducer,
  scheduleN: scheduleNReducer,
  teamData: teamDataReducer,
  departmentData: departmentDataReducer,
  scheduleCompliances: ScheduleComplianceReducer,
  requiredShiftFulfillment: RequiredShiftFulfillmentReducer,
  scheduleConflicts: ScheduleConflictReducer,
  reportFavorites: ReportFavoriteReducer,
  timesheetConflicts: TimesheetConflictReducer,
  reportRecurring: reportRecurringReducer,
  employability: employabilityReducer,
  userGuides: userGuideReducer,
  departmentVariations: DepartmentVariation.reducer,
};

export const ormEffects = [
  AbsencePolicy.AbsencePolicyEffect,
  DepartmentVariation.DepartmentVariationEffect,
  UserGuideEffect,
  HolidayGroupsEffect,
  RateCardEffect,
];
