import { Pipe, PipeTransform } from '@angular/core';
import { isColorDark } from '../contrast.helper';

@Pipe({
  name: 'isColorDark',
  pure: true,
  standalone: true,
})
export class IsColorDarkPipe implements PipeTransform {
  public transform(color: string): boolean {
    return isColorDark(color);
  }
}
