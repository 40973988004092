import forOwn from 'lodash-es/forOwn';
import keys from 'lodash-es/keys';

import { accountActionType } from '../account/account.action';
import { UnsafeAction as Action } from '../interfaces';
import { mfaActionType } from '../mfa/mfa.action';
import { departmentActionType } from '../orm/department/department.action';
import { locationActionType } from '../orm/location/location.action';
import { authActionType } from './auth.action';
import { AuthState } from './auth.model';

const initialState: AuthState = {
  authenticated: false,
  status: null,
  loading: false,
  error: null,
  accounts: null,
  user_id: null,
  account_id: null,
  refreshed_at: null,
  departments: [],
  permissions: {},
  userType: null,
  availablePermissions: [],
  email: null,
  refinerSignature: null,
  quotaActionRequired: false,
};

/**
 * turn permissions into {permission: DepartmentIds[]}
 * @param permissions
 */
function setPermissions(permissions: { [s: string]: { [s: string]: string } }) {
  const tempPermissions = {};

  forOwn(permissions, function (permissionList, department_id) {
    forOwn(permissionList, function (permissionId, permission) {
      if (typeof tempPermissions[permission] === 'undefined') {
        tempPermissions[permission] = [];
      }

      tempPermissions[permission].push(department_id);
    });
  });

  return tempPermissions;
}

// eslint-disable-next-line max-lines-per-function
export function AuthReducer(state: AuthState = initialState, action: Action): AuthState {
  const payload = action.payload;

  switch (action.type) {
    case authActionType.LOGOUT:
    case authActionType.CLEAR_SESSION:
    case authActionType.LOAD_FAILED:
      return initialState;

    case authActionType.LOAD:
    case authActionType.REFRESH:
    case authActionType.FORGOT_PASSWORD:
    case authActionType.CHANGE_EMAIL:
    case authActionType.CHANGE_PASSWORD:
    case mfaActionType.UPDATE_PROMOTE:
    case authActionType.LOGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case authActionType.REFRESH_SUCCESS:
    case authActionType.LOAD_SUCCESS:
    case authActionType.RESET_PASSWORD_SUCCESS:
    case authActionType.VERIFY_SIGN_UP_SUCCESS:
    case authActionType.SIGN_UP_NO_VERIFY_SUCCESS:
    case authActionType.LOGIN_SUCCESS: {
      const permissions = setPermissions(payload.permissions);
      const availablePermissions = Object.keys(permissions);
      return {
        ...state,
        authenticated: true,
        status: payload.AccountStatus ? payload.AccountStatus : null,
        loading: false,
        accounts: null,
        user_id: payload.User.id,
        // backwards compatible refreshed at check
        refreshed_at: payload.RequestedAt || payload.User.reset_permissions,
        account_id: payload.User.account_id,
        departments: keys(payload.departments),
        permissions,
        availablePermissions,
        userType: payload.userType,
        email: payload.User.email,
        verified: payload.User?.verified,
        refinerSignature: payload.refinerSignature || state.refinerSignature,
        quotaActionRequired: payload.quotaActionRequired,
      };
    }

    case authActionType.FORGOT_PASSWORD_FAILED:
    case authActionType.FORGOT_PASSWORD_SUCCESS:
    case authActionType.CHANGE_PASSWORD_FAILED:
    case authActionType.CHANGE_PASSWORD_SUCCESS:
    case authActionType.CHANGE_EMAIL_FAILED:
    case mfaActionType.UPDATE_PROMOTE_SUCCESS:
    case mfaActionType.UPDATE_PROMOTE_FAILED:
    case authActionType.LOGIN_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case authActionType.CHANGE_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        email: payload.email,
      };
    }

    case authActionType.LOGIN_CHOOSE_ACCOUNT: {
      return {
        ...state,
        loading: false,
        accounts: payload,
      };
    }

    case departmentActionType.ADD_SUCCESS: {
      return {
        ...state,
        departments: [...state.departments, payload.result],
      };
    }

    case locationActionType.ADD_SUCCESS: {
      if (payload.entities.departments) {
        const newDepartments = Object.keys(payload.entities.departments);
        return {
          ...state,
          departments: [...state.departments, ...newDepartments],
          loading: false,
        };
      } else {
        return {
          ...state,
          loading: false,
        };
      }
    }

    case accountActionType.PATCH_INVOICE_SETTINGS_SUCCEEDED: {
      return {
        ...state,
        status: 'ok',
      };
    }

    default:
      return state;
  }
}
