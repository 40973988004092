import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { HorizontalScrollService } from './horizontal-scroll.service';

@Directive({
  selector: '[HorizontalScrollable]',
})
export class HorizontalScrollableDirective implements OnDestroy, AfterViewInit {
  constructor(private element: ElementRef, private horizontalScrollService: HorizontalScrollService) {}

  ngAfterViewInit(): void {
    this.horizontalScrollService.register(this);
  }

  ngOnDestroy() {
    this.horizontalScrollService.deregister(this);
  }

  handleScroll(position: number) {
    const native = this.element.nativeElement;
    if (native.scrollLeft === position) {
      return;
    }
    native.scrollLeft = position;
  }

  viewPercentage() {
    const native = this.element.nativeElement;
    const maxScroll = native.scrollWidth - native.clientWidth;
    const canScroll = maxScroll > 0;

    return {
      scrollLeft: native.scrollLeft,
      maxScroll,
      canScroll,
    };
  }
}
