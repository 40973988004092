import { Injectable } from '@angular/core';
import { ReportFavoriteApi } from './report-favorite.api';
import { ReportFavoriteModel } from './report-favorite.model';
import { tap, throwError as observableThrowError } from 'rxjs';
import { AppState } from '../../index';
import { Store } from '@ngrx/store';
import { ReportFavoriteAction } from './report-favorite.action';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ReportFavoriteService {
  public constructor(private api: ReportFavoriteApi, private store: Store<AppState>) {}

  public getFavorites() {
    return this.api.getFavorites(ReportFavoriteAction.load()).pipe(
      tap((data) => {
        this.store.dispatch(ReportFavoriteAction.loadSuccess(data));
      }),
      catchError((response) => {
        this.store.dispatch(ReportFavoriteAction.loadFailed(response));
        return observableThrowError(response);
      })
    );
  }

  public saveFavorite(data: Partial<ReportFavoriteModel>) {
    return this.api.save(data);
  }

  public deleteFavorite(id: string) {
    return this.api.delete(id, ReportFavoriteAction.delete()).pipe(
      tap(() => {
        this.store.dispatch(ReportFavoriteAction.deleteSuccess(id));
      }),
      catchError((response) => {
        this.store.dispatch(ReportFavoriteAction.deleteFailed(response));
        return observableThrowError(response);
      })
    );
  }
}
