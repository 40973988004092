import { UnsafeAction as Action } from '../../interfaces';
import { AvailabilityState } from './availability.model';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { mergeEntities } from '../orm';
import { availabilityActionType } from './availability.action';
import { scheduleActionType } from '../schedule/schedule.action';

const entityType = 'availabilities';

const initialState: AvailabilityState = {
  items: [],
  itemsById: {},
  loading: false,
};

export function AvailabilityReducer(state: AvailabilityState = initialState, action: Action): AvailabilityState {
  switch (action.type) {
    case availabilityActionType.LOAD: {
      return {
        ...state,
        loading: true,
      };
    }

    case availabilityActionType.LOAD_FAILED:
      return {
        ...state,
        loading: false,
      };

    case scheduleActionType.LOAD_SCHEDULE_DATASET_SUCCESS:
    case availabilityActionType.LOAD_SUCCESS:
      return {
        ...mergeEntities(state, getEntities(action, entityType)),
        loading: false,
      };

    default:
      if (containsEntity(action, entityType)) {
        return mergeEntities(state, getEntities(action, entityType));
      }

      return state;
  }
}
