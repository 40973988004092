export function addToList(list: any[], value: any) {
  return [...list, value];
}

export function removeFromList(list: any[], value: any) {
  const index = list.indexOf(value);
  if (index === -1) {
    return list;
  }

  return [...list.slice(0, index), ...list.slice(index + 1)];
}

export function toggleListItem(list: any[], value: any) {
  if (list.indexOf(value) === -1) {
    return addToList(list, value);
  }

  return removeFromList(list, value);
}
