import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sidebar-left',
  template: '<ng-content></ng-content>',
  standalone: true,
})
export class SidebarLeftComponent {
  @Input()
  @HostBinding('class.page__sidebar__open')
  public opened: boolean;

  close() {
    this.opened = false;
  }

  open() {
    this.opened = true;
  }

  toggle() {
    this.opened = !this.opened;
  }
}
