import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import identity from 'lodash-es/identity';
import isEqual from 'lodash-es/isEqual';
import pickBy from 'lodash-es/pickBy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { ColumnFiltersType } from '../../enums';
import { AuthState } from '../auth/auth.model';
import { authState } from '../auth/auth.service';
import { AppState } from '../index';
import { UnsafeAction } from '../interfaces';
import { selectedDepartmentsActionType } from '../selected-departments/selected-departments.action';
import { getSelectedDepartmentIds } from '../selected-departments/selected-departments.service';
import { pageFilterActionType } from './page-filters.action';
import {
  filterPersistenceDebounceTime,
  getPageFiltersState,
  PageFilterRequest,
  PageFiltersService,
} from './page-filters.service';

@Injectable()
export class PageFiltersEffect {
  private saveFilterSubject = new BehaviorSubject(null);
  private saveDepartmentSubject = new BehaviorSubject(null);

  saveFilters$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(pageFilterActionType.SET_FILTERS, pageFilterActionType.SET_FILTER),
        switchMap(() => this.store.select(authState)),
        filter((authenticationState: AuthState) => authenticationState.authenticated),
        switchMap(() => this.store.pipe(select(getPageFiltersState))),
        map((data) => JSON.stringify(data, PageFiltersEffect.pageFilterReplacer)),
        tap((data) => {
          this.saveFilterSubject.next(data);
        }),
      ),
    { dispatch: false },
  );

  saveSelectedDepartments$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(selectedDepartmentsActionType.SELECT_DEPARTMENT, selectedDepartmentsActionType.SELECT_DEPARTMENTS),
        switchMap((action) => this.store.pipe(select(getSelectedDepartmentIds))),
        map((data) => JSON.stringify(data)),
        tap((data) => {
          this.saveDepartmentSubject.next(data);
        }),
      ),
    { dispatch: false },
  );

  private static pageFilterReplacer(this, key, value) {
    switch (key) {
      case 'period':
      case 'date':
        return undefined;
      case 'sidebarIsOpen':
        return true;
      case 'selectedPage':
        return 'overview';
      case 'selectedEmployee':
      case 'employeeId':
      case 'name':
      case 'employeeSearch':
      case 'search':
        return '';
      case 'report': {
        const reportFilters = {
          sidebarIsOpen: true,
        };
        Object.values(ColumnFiltersType).forEach((columns) => {
          reportFilters[columns] = value[columns];
        });
        return pickBy(reportFilters, identity);
      }
      case 'department': {
        if (typeof value === 'boolean') {
          return value;
        }
        return '{}';
      }
    }
    return value;
  }

  public constructor(
    private actions: Actions<UnsafeAction>,
    private store: Store<AppState>,
    private pageFilterService: PageFiltersService,
  ) {
    this.store
      .pipe(
        select(authState),
        filter(
          (authenticationState: AuthState) =>
            authenticationState.authenticated && authenticationState.userType === 'user',
        ),
        switchMap(() => this.saveFilter$()),
      )
      .subscribe();
  }

  private saveFilter$() {
    return combineLatest([this.saveFilterSubject, this.saveDepartmentSubject, this.store.select(authState)]).pipe(
      filter(
        ([filters, departments, authState]) =>
          (filters || departments) && authState.authenticated && authState.userType === 'user',
      ),
      debounceTime(filterPersistenceDebounceTime),
      distinctUntilChanged((previous, next) => isEqual(previous, next)),
      map(([filters, departments]) => {
        const data = {
          filters: filters ? filters : null,
          departments: departments ? departments : null,
        };
        return pickBy(data, identity);
      }),
      switchMap((data: PageFilterRequest) => this.pageFilterService.saveFilters(data)),
    );
  }
}
