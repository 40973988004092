import { Injectable } from '@angular/core';
import { Features } from '@app/enums';
import { FeatureService } from '@app/startup/feature.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { RefinerService } from './refiner.services';
import { trackEvent } from './tracking.service';

@Injectable()
export class TrackingEffect {
  public constructor(
    private actions$: Actions,
    private feature: FeatureService,
    private refiner: RefinerService,
  ) {}

  public trackEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trackEvent),
        tap(({ event }) => {
          if (this.feature.isFeatureActivated(Features.TMP_REFINER)) {
            this.refiner.trackEvent(event);
          }
        }),
      ),
    { dispatch: false },
  );
}
