import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../reducers';
import { PageFiltersService } from '../../../../reducers/page-filters/page-filters.service';

@Injectable()
export class ReportSidebarGuard {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private pageFilterService: PageFiltersService,
  ) {}

  canActivate(): boolean {
    this.pageFilterService.setFilter('report', 'sidebarIsOpen', true);
    return true;
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }
}
