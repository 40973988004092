import { UnsafeAction as Action } from '../../interfaces';
import { SkillState } from './skill.model';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { resetEntities, mergeEntities, updateEntitiesById, removeEntity } from '../orm';
import { SkillActionType } from './skill.action';

const entityType = 'skill';

const initialState: SkillState = {
  items: [],
  itemsById: {},
};

export function SkillReducer(state: SkillState = initialState, action: Action): SkillState {
  let payload = action.payload;

  switch (action.type) {
    case SkillActionType.LOAD_SUCCESS:
      let entities = getEntities(action, entityType);
      return resetEntities(state, entities);

    case SkillActionType.DELETE:
      return updateEntitiesById(state, { loading: true }, payload);

    case SkillActionType.DELETE_FAILED:
      return updateEntitiesById(state, { loading: false }, payload.id);

    case SkillActionType.DELETE_SUCCESS:
      return removeEntity(state, payload);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}
