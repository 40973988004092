import cloneDeep from 'lodash-es/cloneDeep';
import groupBy from 'lodash-es/groupBy';
import isArray from 'lodash-es/isArray';
import isString from 'lodash-es/isString';
import omit from 'lodash-es/omit';

import { getDayListWithoutTotals } from '../../../+authenticated/+schedule/employee/schedule-employee-period/schedule-employee-period.helper';
import { parseDate } from '../../../shared/date.helper';
import { UnsafeAction as Action } from '../../interfaces';
import { AbsenceActionType } from '../../orm/absence/absence.action';
import { AbsenceModel, AbsenceStatus, RosterActions } from '../../orm/absence/absence.model';
import { availabilityActionType } from '../../orm/availability/availability.action';
import { exchangeActionType } from '../../orm/exchange/exchange.action';
import { openShiftActionType } from '../../orm/open-shift/open-shift.action';
import { scheduleActionType } from '../../orm/schedule/schedule.action';
import { ScheduleModel } from '../../orm/schedule/schedule.model';
import { pageFilterActionType } from '../../page-filters/page-filters.action';
import { PageFiltersState } from '../../page-filters/page-filters.model';
import { convertArrayToObject, getArrayFromActionPayload, PayloadKeyType } from '../../shared/entity.helper';
import { calculateScheduleRowHeight } from '../schedule-shared.helper';
import { entityInPeriod, getFilteredSchedules, getScheduleIdsToDelete } from '../schedule-store.helpers';
import { scheduleNActionType } from './schedule.n.action';
import { ScheduleNModel } from './schedule.n.model';
import { initialState, scheduleNAdapter, ScheduleNState } from './schedule.n.state';

export function scheduleNReducer(state: ScheduleNState = initialState, action: Action): ScheduleNState {
  const payload = action.payload;

  if (
    action.type !== pageFilterActionType.LOAD_FILTERS &&
    action.type !== scheduleNActionType.SET_PERIOD &&
    !state.minDate
  ) {
    return state;
  }
  switch (action.type) {
    case scheduleNActionType.RESET: {
      return scheduleNAdapter.removeAll({ ...state, minDate: '', maxDate: '' });
    }

    case pageFilterActionType.LOAD_FILTERS: {
      const filters: PageFiltersState = isString(payload) ? JSON.parse(payload) : payload;
      return {
        ...state,
        showTypes: filters?.schedule?.showTypes,
        showOptions: filters?.schedule?.showOptions,
      };
    }
    case pageFilterActionType.SET_FILTER:
    case pageFilterActionType.SET_FILTERS: {
      return processScheduleFilters(payload, state);
    }

    case scheduleNActionType.SET_PERIOD: {
      return {
        ...state,
        minDate: action.payload.minDate,
        maxDate: action.payload.maxDate,
      };
    }

    case scheduleActionType.LOAD_SUCCESS: {
      const schedules: ScheduleModel[] = getArrayFromActionPayload(PayloadKeyType.SCHEDULES, payload.entities);
      const updatedState = removeScheduleForDate(
        action.requestData.minDate,
        action.requestData.maxDate,
        action.requestData.userId,
        state,
      );

      return addSchedules(action.requestData.userId, schedules, updatedState);
    }

    case scheduleNActionType.LOAD_SUCCESS: {
      if (!payload.scheduleData) {
        return state;
      }
      const updatedState = scheduleNAdapter.setAll(payload.scheduleData, state);
      return { ...reprocessData(updatedState), isLoading: false };
    }

    case scheduleActionType.UPDATE: {
      if (!payload.scheduleData.user_id) {
        return removeSchedule(payload.occurrenceId, payload.scheduleData.User, state, payload.recurring);
      }
      return transferSchedule(payload.loadingId, payload.occurrenceId, payload.scheduleData, state);
    }

    case scheduleActionType.UPDATE_SUCCESS: {
      const schedules: ScheduleModel[] = getArrayFromActionPayload(PayloadKeyType.SCHEDULES, payload.entities);
      const updatedState = removeSchedule(payload.loadingId, payload.userId, state, payload.recurring);
      if (!schedules || schedules.length === 0) {
        return updatedState;
      }
      return addSchedules(payload.userId, schedules, updatedState);
    }

    case openShiftActionType.ASSIGN: {
      return addSchedule({ ...payload.scheduleData, occurrence_id: payload.loadingId }, true, state);
    }

    case openShiftActionType.MULTI_ASSIGN_SUCCESS: {
      const schedules = getArrayFromActionPayload(PayloadKeyType.SCHEDULES, action.payload.entities);
      if (!schedules || schedules.length === 0) {
        return state;
      }

      const groupedSchedulesByUserId = groupBy(schedules, 'user_id');
      for (const [userId, schedulesForUser] of Object.entries(groupedSchedulesByUserId)) {
        state = addSchedules(userId, schedulesForUser, state);
      }
      return state;
    }

    case openShiftActionType.ASSIGN_SUCCESS: {
      const schedule: ScheduleModel = getArrayFromActionPayload(PayloadKeyType.SCHEDULES, payload.entities)[0];
      const updatedState = removeSchedule(payload.loadingId, schedule.user_id, state);
      return addSchedule({ ...schedule }, false, updatedState);
    }

    case scheduleActionType.ADD: {
      const schedule = action.payload.scheduleData;
      const employeeIds = isArray(schedule.user_id) ? [...schedule.user_id] : [schedule.user_id];

      let updatedState = state;
      employeeIds.forEach((id) => {
        updatedState = addSchedule(
          {
            ...schedule,
            user_id: id,
            occurrence_id: action.payload.loadingId,
          },
          true,
          updatedState,
        );
      });

      return updatedState;
    }

    case AbsenceActionType.ADD_SUCCESS:
    case AbsenceActionType.UPDATE_SUCCESS: {
      return addAbsences(payload, state);
    }

    case AbsenceActionType.REMOVE: {
      return removeAbsence(payload.id, payload.userId, state);
    }

    case scheduleActionType.ADD_SUCCESS:
    case openShiftActionType.TAKE_SHIFT_SUCCESS: {
      let updatedState = state;
      action?.updateEvent?.employeeIds.forEach((id) => {
        updatedState = action.payload.loadingId
          ? removeSchedule(action.payload.loadingId, id, updatedState)
          : updatedState;
      });

      const schedules = getArrayFromActionPayload(PayloadKeyType.SCHEDULES, action.payload.entities);
      if (!schedules || schedules.length === 0) {
        return updatedState;
      }

      const groupedSchedulesByUserId = groupBy(schedules, 'user_id');
      for (const [userId, schedulesForUser] of Object.entries(groupedSchedulesByUserId)) {
        updatedState = addSchedules(userId, schedulesForUser, updatedState);
      }
      return updatedState;
    }
    case scheduleActionType.REMOVE: {
      return removeSchedule(payload.occurrenceId, payload.optimizedData.userId, state, payload.optimizedData.recurring);
    }

    case availabilityActionType.ADD_SUCCESS: {
      return addAvailabilities(payload, state);
    }

    case exchangeActionType.ADD_SUCCESS: {
      return addExchange(payload, state);
    }

    case exchangeActionType.DELETE_EXCHANGE_SUCCESS: {
      return deleteExchange(payload, state);
    }

    case exchangeActionType.ACCEPT_INCOMING_SUCCESS:
    case exchangeActionType.ACCEPTED_BY_SUPERVISOR_SUCCESS: {
      const exchange = payload.entities.exchanges[payload.result];
      if (exchange?.status !== 'Approved') {
        return state;
      }
      state = removeSchedule(exchange.Roster, exchange.user_id, state);
      return addSchedules(exchange.accepted_by, [exchange.NewRoster], state);
    }

    default:
      return state;
  }
}

const getSchedule = (scheduleId: string, userId: string, state: ScheduleNState): ScheduleModel =>
  state.entities[userId]?.schedules[scheduleId];

const addExchange = (payload, state: ScheduleNState) => {
  const exchange = payload.entities.exchanges[payload.result];
  const schedule = getSchedule(exchange.Roster, exchange.user_id, state);
  if (!schedule) {
    return state;
  }
  const updatedSchedule = {
    ...schedule,
    Exchange: payload.result,
  };
  return addSchedules(schedule.user_id, [updatedSchedule], state);
};

const deleteExchange = (payload, state: ScheduleNState) => {
  const schedule: ScheduleModel = getSchedule(payload.Roster, payload.user_id, state);
  if (!schedule) {
    return state;
  }
  const updatedSchedule = {
    ...schedule,
    Exchange: null,
  };
  return addSchedules(updatedSchedule.user_id, [updatedSchedule], state);
};

const addAbsences = (payload, state: ScheduleNState): ScheduleNState => {
  const absences = getArrayFromActionPayload(PayloadKeyType.ABSENCE, payload.entities);
  const user: ScheduleNModel = state.entities[absences[0].user_id];
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const updatedData: ScheduleNModel = {
    ...user,
    id: absences[0].user_id,
    absence: {
      ...user?.absence,
      ...convertArrayToObject('id', absences),
    },
  };

  const moveToOpenShiftDates = absences
    .filter(
      (absence: AbsenceModel) =>
        absence.roster_action === RosterActions.MOVE_TO_OPEN_SHIFT && absence.status === AbsenceStatus.APPROVED,
    )
    .map((absence: AbsenceModel) => Object.keys(absence.AbsenteeDay))
    .flat(1);

  updatedData.schedules = user?.schedules ?? {};
  if (moveToOpenShiftDates?.length) {
    const filteredSchedules = Object.values(user?.schedules ?? {}).filter(
      (schedule: ScheduleModel) => !moveToOpenShiftDates.includes(schedule.date),
    );
    updatedData.schedules = convertArrayToObject('occurrence_id', filteredSchedules);
  }

  updatedData.filteredSchedules = getFilteredSchedules(updatedData.schedules, state, updatedData.absence);

  updatedData.rowHeight = calculateScheduleRowHeight(
    {
      schedules: updatedData.filteredSchedules,
      absence: updatedData.absence,
      availabilities: updatedData.availabilities,
    },
    totals,
    state.showTypes,
    state.showOptions,
  );

  return scheduleNAdapter.setOne(updatedData, state);
};

const addAvailabilities = (payload, state: ScheduleNState): ScheduleNState => {
  const availabilities = getArrayFromActionPayload(PayloadKeyType.AVAILABILITIES, payload.entities);
  const user: ScheduleNModel = state.entities[availabilities[0].user_id];
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const existingAvailabilities = !user ? {} : state.entities[user.id].availabilities;

  const updatedData: ScheduleNModel = {
    ...user,
    id: availabilities[0].user_id,
    availabilities: { ...existingAvailabilities, ...convertArrayToObject('date', availabilities) },
  };
  updatedData.rowHeight = calculateScheduleRowHeight(
    {
      schedules: updatedData.filteredSchedules,
      absence: updatedData.absence,
      availabilities: updatedData.availabilities,
    },
    totals,
    state.showTypes,
    state.showOptions,
  );

  return scheduleNAdapter.setOne(updatedData, state);
};

const processScheduleFilters = (payload, state: ScheduleNState): ScheduleNState => {
  if (payload.page !== 'schedule') {
    return state;
  }

  let departmentFilteredShiftIds = state.shiftFilters ?? [];

  if (payload?.filter?.includes('shifts')) {
    const filter = payload.filter.split('.');
    departmentFilteredShiftIds = {
      ...state.shiftFilters,
      [filter[1]]: payload.filterValue,
    };
  }

  const showTypes = payload?.filters?.showTypes;
  const showOptions = payload?.filters?.showOptions;
  const updatedState = {
    ...state,
    shiftFilters: departmentFilteredShiftIds,
    showTypes: showTypes || state.showTypes,
    showOptions: showOptions || state.showOptions,
  };

  return reprocessData(updatedState);
};

const reprocessData = (state: ScheduleNState): ScheduleNState => {
  const data = Object.values(state.entities);
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  const userToBeUpdated = [];
  data.forEach((scheduleData: ScheduleNModel) => {
    const filteredSchedules = getFilteredSchedules(scheduleData.schedules, state, scheduleData.absence);

    userToBeUpdated.push({
      ...scheduleData,
      filteredSchedules,
      rowHeight: calculateScheduleRowHeight(
        { ...scheduleData, schedules: filteredSchedules },
        totals,
        state.showTypes,
        state.showOptions,
      ),
    });
  });
  return scheduleNAdapter.setAll(userToBeUpdated, state);
};

const transferSchedule = (
  loadingId: string,
  occurrenceId: string,
  scheduleData: ScheduleModel,
  state: ScheduleNState,
) => {
  const oldSchedule = state.entities[scheduleData.User].schedules[occurrenceId];
  const updatedState = removeSchedule(occurrenceId, scheduleData.User, state);

  return addSchedule(
    {
      ...oldSchedule,
      ...scheduleData,
      occurrence_id: loadingId,
    },
    true,
    updatedState,
  );
};

const addSchedules = (userId: string, schedules: ScheduleModel[], state: ScheduleNState) => {
  const userData = state.entities[userId];
  const mappedSchedules = schedules
    .filter((schedule: ScheduleModel) => entityInPeriod(state.minDate, state.maxDate, schedule.date))
    .map((schedule: ScheduleModel) => ({
      ...schedule,
      department_id: schedule.department_id ?? schedule?.Department?.id,
    }));

  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });
  const updatedScheduleSet = {
    ...userData?.schedules,
    ...convertArrayToObject('occurrence_id', mappedSchedules),
  };
  const filteredSchedules = getFilteredSchedules(updatedScheduleSet, state, userData?.absence);
  const updatedUser: ScheduleNModel = {
    ...(userData ?? createDefaultScheduleData(userId)),
    id: userId,
    schedules: updatedScheduleSet,
    filteredSchedules,
    rowHeight: calculateScheduleRowHeight(
      {
        schedules: filteredSchedules,
        absence: userData?.absence,
        availabilities: userData?.availabilities,
      },
      totals,
      state.showTypes,
      state.showOptions,
    ),
  };
  updatedUser.scheduleTeams = getTeamsFromSchedules(Object.values(updatedScheduleSet));

  return scheduleNAdapter.setOne(updatedUser, state);
};

const addSchedule = (toSchedule: ScheduleModel, loading: boolean, state: ScheduleNState) => {
  if (!entityInPeriod(state.minDate, state.maxDate, toSchedule.date)) {
    return state;
  }
  const user: ScheduleNModel = state.entities[toSchedule.user_id];
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });

  if (!toSchedule.department_id) {
    toSchedule.department_id = toSchedule.Department?.id;
  }
  toSchedule.loading = loading;
  const updatedSchedules = {
    ...user?.schedules,
    [toSchedule.occurrence_id]: toSchedule,
  };

  const updatedData: ScheduleNModel = {
    ...(user ?? createDefaultScheduleData(toSchedule.user_id)),
    id: toSchedule.user_id,
    schedules: { ...updatedSchedules },
    filteredSchedules: getFilteredSchedules(updatedSchedules, state, user?.absence),
  };

  updatedData.scheduleTeams = getTeamsFromSchedules(Object.values(updatedSchedules));

  updatedData.rowHeight = calculateScheduleRowHeight(
    {
      schedules: updatedData?.filteredSchedules,
      absence: updatedData?.absence,
      availabilities: updatedData?.availabilities,
    },
    totals,
    state.showTypes,
    state.showOptions,
  );

  return scheduleNAdapter.setOne(updatedData, state);
};

const removeSchedule = (occurrenceId: string, userId: string, state: ScheduleNState, recurring = false) => {
  const fromUser: ScheduleNModel = cloneDeep(state.entities[userId]);
  if (!fromUser) {
    return state;
  }
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });
  const curSchedule = fromUser.schedules[occurrenceId];

  if (!fromUser.schedules[occurrenceId]) {
    return state;
  }

  const idsToDelete = [occurrenceId];
  if (curSchedule && recurring) {
    idsToDelete.push(...getScheduleIdsToDelete(curSchedule, fromUser.schedules));
  }
  fromUser.schedules = { ...omit(fromUser.schedules, idsToDelete) };
  fromUser.filteredSchedules = getFilteredSchedules(fromUser.schedules, state, fromUser.absence);
  fromUser.rowHeight = calculateScheduleRowHeight(
    { schedules: fromUser.filteredSchedules, absences: fromUser.absence },
    totals,
    state.showTypes,
    state.showOptions,
  );
  fromUser.scheduleTeams = getTeamsFromSchedules(Object.values(fromUser.schedules));

  return scheduleNAdapter.setOne(fromUser, state);
};

const removeScheduleForDate = (minDate: string, maxDate: string, userId: string, state: ScheduleNState) => {
  const fromUser: ScheduleNModel = cloneDeep(state.entities[userId]);
  if (!fromUser) {
    return state;
  }

  const idsToDelete = [];
  Object.values(fromUser.schedules).forEach((schedule: ScheduleModel) => {
    if (entityInPeriod(minDate, maxDate, schedule.date)) {
      idsToDelete.push(schedule.occurrence_id);
    }
  });

  fromUser.schedules = { ...omit(fromUser.schedules, idsToDelete) };

  return scheduleNAdapter.setOne(fromUser, state);
};

const removeAbsence = (id: string, userId: string, state: ScheduleNState) => {
  const fromUser: ScheduleNModel = cloneDeep(state.entities[userId]);
  if (!fromUser) {
    return state;
  }
  const totals = getDayListWithoutTotals({ start: parseDate(state.minDate), end: parseDate(state.maxDate) });
  fromUser.absence = { ...omit(fromUser.absence, id) };
  fromUser.filteredSchedules = getFilteredSchedules(fromUser.schedules, state, fromUser.absence);
  fromUser.rowHeight = calculateScheduleRowHeight(
    { schedules: fromUser.filteredSchedules, absences: fromUser.absence },
    totals,
    state.showTypes,
    state.showOptions,
  );
  fromUser.scheduleTeams = getTeamsFromSchedules(Object.values(fromUser.schedules));

  return scheduleNAdapter.setOne(fromUser, state);
};

const getTeamsFromSchedules = (schedules: ScheduleModel[]) => [
  ...new Set(schedules.map((schedule) => schedule.team_id)),
];

const createDefaultScheduleData = (teamId: string): ScheduleNModel => ({
  id: teamId,
  schedules: {},
  absence: {},
  availabilities: {},
});
