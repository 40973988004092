import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { appConfigureConfig } from '../../../+modals/integrations/configure/app-configure.model';
import { getAccountSubscription } from '../../account/account.service';
import { AppState } from '../../index';
import { getPermissionState } from '../permission/permission.service';
import { IntegrationAppTitle } from './integration-app.model';
import { getIntegrationAppByTitle } from './integration-app.selector';

@Injectable()
export class IntegrationAppParamGuard {
  public constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const appTitle = route.paramMap.get('app') as IntegrationAppTitle;
    return combineLatest([
      this.store.select(getAccountSubscription),
      this.store.select(getIntegrationAppByTitle(appTitle)),
      this.store.select(getPermissionState),
    ]).pipe(
      map(([subscription, integrationApp, permissionState]) => {
        if (!subscription || !integrationApp || !permissionState || !appConfigureConfig[appTitle]) {
          return false;
        }
        if (!integrationApp.enabled) {
          this.router.navigate(['/dashboard/my-overview']);
          return false;
        }

        return true;
      }),
    );
  }
}
