import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest as observableCombineLatest, of as observableOf } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { authState, isAuthenticated } from '../reducers/auth/auth.service';
import { AppState } from '../reducers/index';

@Injectable()
export class AnonymousGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.retrieveAuthenticatedStatus().pipe(
      switchMap(([authenticated, authState]) => {
        if (authenticated) {
          if (state.url === '/login/setup-mfa-backup') {
            return observableOf(true);
          }

          if (state.url === '/payment-reminder') {
            return observableOf(true);
          }

          this.router.navigate(['dashboard/my-overview']);
          return observableOf(false);
        } else {
          if (state.url === '/login/mfa-backup') {
            if (authState.authenticated) {
              return observableOf(true);
            }

            this.router.navigate(['login']);
            return observableOf(false);
          }

          return observableOf(true);
        }
      }),
    );
  }

  private retrieveAuthenticatedStatus() {
    return this.store.select(authState).pipe(
      map((state) => state.loading),
      filter((loading) => !loading),
      switchMap(() => observableCombineLatest(this.store.select(isAuthenticated), this.store.select(authState))),
    );
  }
}
