import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { CommentSchema } from '../../shared/schemas';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';
import { assignSchemaEntity } from '../../shared/assign';

@Injectable()
export class CommentApi {
  private endpoint = 'comments/';

  constructor(private gateway: ApiGateway) {}

  load(): Observable<any> {
    return this.gateway.get(this.endpoint).pipe(
      map((res) => reformatListResponse('Comment', res)),
      map((data) => normalize(data, arrayOf(CommentSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  add(commentData, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint, commentData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Comment', res)),
      map((data) => normalize(data, CommentSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  update(commentId, commentData): Observable<any> {
    return this.gateway.put(this.endpoint + commentId, commentData).pipe(
      map((res) => reformatEntityResponse('Comment', res)),
      map((data) => normalize(data, CommentSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  fetch(commentId): Observable<any> {
    return this.gateway.get(this.endpoint + '/' + commentId).pipe(
      map((res) => reformatEntityResponse('Comment', res)),
      map((data) => normalize(data, CommentSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  remove(commentId): Observable<any> {
    return this.gateway.delete(this.endpoint + '/' + commentId);
  }
}
