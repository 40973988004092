import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TimesheetConflictStoreModel } from './timesheet-conflict.model';

export interface TimesheetConflictState extends EntityState<TimesheetConflictStoreModel> {
  isLoading?: boolean;
  error?: any;
}

export const timesheetConflictModelEntityAdapter: EntityAdapter<TimesheetConflictStoreModel> = createEntityAdapter<
  TimesheetConflictStoreModel
>();

export const initialState: TimesheetConflictState = timesheetConflictModelEntityAdapter.getInitialState({
  isLoading: false,
  error: null,
});
