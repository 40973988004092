import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ScheduleFilterShowOptions, ScheduleFilterShowTypes } from '../../page-filters/page-filters.model';
import { ScheduleNModel } from './schedule.n.model';

export interface ScheduleNState extends EntityState<ScheduleNModel> {
  isLoading?: boolean;
  error?: any;
  minDate?: string;
  maxDate?: string;
  shiftFilters?: string[];
  showTypes?: ScheduleFilterShowTypes;
  showOptions?: ScheduleFilterShowOptions;
}

export const scheduleNAdapter: EntityAdapter<ScheduleNModel> = createEntityAdapter<ScheduleNModel>();

export const initialState: ScheduleNState = scheduleNAdapter.getInitialState({
  isLoading: true,
  error: null,
  minDate: '',
  maxDate: '',
  shiftFilters: [],
  showTypes: undefined,
  showOptions: undefined,
});
