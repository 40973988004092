import { Injectable } from '@angular/core';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { ReportFavoriteModel } from './report-favorite.model';
import { UnsafeAction as Action } from '../../interfaces';

@Injectable()
export class ReportFavoriteApi {
  private endpoint = 'reporting/favorites';

  constructor(private gateway: ApiGateway) {}

  public getFavorites(dispatchStart?: Action) {
    return this.gateway.get(this.endpoint, undefined, dispatchStart).pipe();
  }

  public save(data: Partial<ReportFavoriteModel>, dispatchStart?: Action) {
    return this.gateway.post(this.endpoint, data, undefined, dispatchStart);
  }

  public delete(id: string, dispatchStart?: Action) {
    return this.gateway.delete(this.endpoint + '/' + id, undefined, dispatchStart);
  }
}
