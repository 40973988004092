import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../index';
import { Message } from './message.model';
import { MessageAction } from './message.action';

@Injectable()
export class MessageService {
  public state: Observable<Message[]>;
  public lastMessage: Observable<Message>;

  constructor(private store: Store<AppState>) {
    this.state = store.select((state) => state.messages);

    this.lastMessage = this.state.pipe(map((messages: Message[]) => messages.slice(-1)[0]));
  }

  removeMessage(messageId) {
    this.store.dispatch(MessageAction.remove(messageId));
  }
}

export const getMessages = (state: AppState) => state.messages;
