import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../../../reducers/index';
import { ExchangeService, getExchange } from '../../../reducers/orm/exchange/exchange.service';
import { MessageAction } from '../../message/message.action';

@Injectable()
export class ExchangeLoadGuard {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private exchangeService: ExchangeService,
    private translateService: TranslateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.hasExchangeInStore(route.params['id']).pipe(
      tap((hasItem) => {
        if (!hasItem) {
          this.store.dispatch(
            MessageAction.error(this.translateService.instant('This exchange is no longer available.')),
          );
          this.router.navigate(['/dashboard/my-overview', { outlets: { modal: null } }]);
        }
      }),
    );
  }

  private hasExchangeInStore(itemId: string): Observable<boolean> {
    return this.waitForStore(itemId).pipe(
      switchMap((inStore) => {
        if (inStore) {
          return observableOf(true);
        }

        return this.hasExchangeInApi(itemId);
      }),
    );
  }

  private waitForStore(itemId: string): Observable<boolean> {
    return this.store.select(getExchange(itemId)).pipe(
      map((entity) => !!entity),
      take(1),
    );
  }

  private hasExchangeInApi(exchangeId: string): Observable<boolean> {
    return this.exchangeService.fetch(exchangeId).pipe(
      map((response) => !!response),
      catchError((err) => observableOf(false)),
    );
  }
}
