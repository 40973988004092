import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ReportFilters } from '../../+authenticated/+reports/shared/report-filters/report-filter.interfaces';

@Component({
  selector: 'report-filter-groupby',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './report-filter-groupby.component.html',
  styleUrls: ['report-filter-groupby.component.scss'],
})
export class ReportFilterGroupbyComponent {
  @Input()
  public icon?: string;

  @Input()
  public buttonText: string;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public reportFilters: ReportFilters;

  public positions = [
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 7),
  ];

  public isOpen = false;
}
