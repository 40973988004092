import { isBefore, isEqual, isValid } from 'date-fns';
import cloneDeep from 'lodash-es/cloneDeep';
import filter from 'lodash-es/filter';
import isArray from 'lodash-es/isArray';
import reduce from 'lodash-es/reduce';

import { format, parseDate, periodFilter } from '../../../shared/date.helper';
import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getArrayFromActionPayload, PayloadKeyType } from '../../shared/entity.helper';
import { scheduleActionType } from '../schedule/schedule.action';
import { openShiftActionType } from './open-shift.action';
import {
  LoadOpenShiftsSuccessAction,
  OpenShiftModel,
  OpenShiftsLoadRequest,
  OpenShiftUserStatus,
} from './open-shift.model';
import { initialState, openShiftAdapter, OpenShiftState } from './open-shift.state';

export function OpenShiftReducer(state: OpenShiftState = initialState, action: Action) {
  const payload = action.payload;
  switch (action.type) {
    case openShiftActionType.LOAD_SUCCESS:
    case scheduleActionType.LOAD_SCHEDULE_DATASET_SUCCESS: {
      return handleLoadSuccess(state, action);
    }
    case openShiftActionType.ADD:
      return optimisticHandleAdd(state, payload);
    case openShiftActionType.UPDATE:
      return optimisticHandleUpdate(state, payload);
    case openShiftActionType.REQUEST_SHIFT_SUCCESS:
    case openShiftActionType.REJECT_SHIFT_SUCCESS:
    case openShiftActionType.WITHDRAW_SUCCESS:
      return openShiftAdapter.upsertMany(
        enhanceOpenShiftsWithStats(getArrayFromActionPayload(PayloadKeyType.OPENSHIFTS, payload.entities)),
        state,
      );
    case openShiftActionType.REMOVE:
      return optimisticHandleDelete(state, payload);
    case openShiftActionType.ASSIGN:
      return optimisticHandleAssign(state, payload);
    case openShiftActionType.MULTI_ASSIGN:
      return optimisticHandleAssign(state, payload, payload.scheduleData.employeeIds.length);
    case openShiftActionType.UPDATE_SUCCESS:
    case openShiftActionType.ASSIGN_SUCCESS:
    case openShiftActionType.MULTI_ASSIGN_SUCCESS:
    case openShiftActionType.ADD_SUCCESS: {
      state = openShiftAdapter.removeOne(payload.loadingId, state);

      state = openShiftAdapter.upsertMany(
        enhanceOpenShiftsWithStats(getArrayFromActionPayload(PayloadKeyType.OPENSHIFTS, payload.entities)),
        state,
      );

      if (action?.updateEvent) {
        const { occurrenceId, employeeIds } = action?.updateEvent;
        if (!occurrenceId || !employeeIds?.length) {
          return state;
        }

        state = handleOpenShiftAssign(occurrenceId, employeeIds, state);
      }
      return state;
    }
    case openShiftActionType.TAKE_SHIFT_SUCCESS: {
      const openShift = state.entities[payload.occurrenceId];
      const instancesRemaining = openShift.instances_remaining - 1;
      return openShiftAdapter.updateOne(
        {
          id: openShift.occurrence_id,
          changes: {
            instances_remaining: instancesRemaining,
          },
        },
        state,
      );
    }
    default:
      if (containsEntity(action, PayloadKeyType.OPENSHIFTS)) {
        return openShiftAdapter.upsertMany(
          enhanceOpenShiftsWithStats(getArrayFromActionPayload(PayloadKeyType.OPENSHIFTS, payload.entities)),
          state,
        );
      } else {
        return state;
      }
  }
}

const handleOpenShiftAssign = (occurrenceId: string, employeeIds: string[], state: OpenShiftState) => {
  const openShiftToUpdate = cloneDeep(state.entities[occurrenceId]);

  if (!openShiftToUpdate || !employeeIds?.length) {
    return state;
  }
  openShiftToUpdate.EmployeeStatus = openShiftToUpdate.EmployeeStatus.map((empStatus) => ({
    ...empStatus,
    status: employeeIds.includes(empStatus.employee_id) ? OpenShiftUserStatus.ASSIGNED : empStatus.status,
  }));
  return openShiftAdapter.upsertOne(enhanceOpenShiftWithStats(openShiftToUpdate), state);
};

function optimisticHandleAdd(state, payload) {
  const { openShiftData, loadingId } = payload;

  // Create a new open shift which is in the loading state.
  const openShift = {
    ...openShiftData,
    EmployeeStatus: [],
    occurrence_id: loadingId,
    loading: true,

    // Never displayed due to loading state, but need to make it into the view.
    instances_remaining: 1,
  };

  // And add the new 'loading' schedule.
  return openShiftAdapter.addOne(openShift, state);
}

function optimisticHandleAssign(state: OpenShiftState, payload, amountToRemove = 1) {
  const { occurrenceId } = payload;

  let openShift = state.entities[occurrenceId];

  if (!openShift) {
    return state;
  }

  let instancesRemaining = openShift.instances_remaining - amountToRemove;

  if (instancesRemaining <= 0) {
    instancesRemaining = 0;
  }

  openShift = {
    ...openShift,
    instances_remaining: instancesRemaining,
  };

  if (openShift.instances_remaining === 0) {
    return openShiftAdapter.removeOne(openShift.occurrence_id, state);
  } else {
    return openShiftAdapter.upsertOne(openShift, state);
  }
}

function optimisticHandleUpdate(state, payload) {
  const { openShiftData, occurrenceId, scope, loadingId } = payload;

  // Create a new openshift which is in the loading state.
  let openShift = state.entities[occurrenceId];
  if (!openShift) {
    return state;
  }
  openShift = {
    ...openShift,
    ...openShiftData,
    occurrence_id: loadingId,
    loading: true,
  };

  /*
    When 'original' and 'sequence' remove all future occurrences, but
    keep the past ones intact.

    The back-end will respond with the new future occurrences eventually.
  */
  if (scope !== 'occurrence') {
    state = removeFutureOccurrences(state, openShift);
  }

  // Finally remove the dragged openshift
  state = openShiftAdapter.removeOne(occurrenceId, state);

  // And add the new 'loading' openshift.
  return openShiftAdapter.addOne(openShift, state);
}

function optimisticHandleDelete(state: OpenShiftState, payload) {
  const { occurrenceId, scope } = payload;

  const openShift = state.entities[occurrenceId];
  if (!openShift) {
    return state;
  }

  if (scope !== 'occurrence') {
    return removeFutureOccurrences(state, openShift);
  } else {
    return openShiftAdapter.removeOne(occurrenceId, state);
  }
}

function removeFutureOccurrences(state: OpenShiftState, openShift: OpenShiftModel) {
  const date = parseDate(openShift.date);

  const shiftIds = reduce(
    state.entities,
    (ids, openShiftCheck: OpenShiftModel) => {
      const isSameOrBefore = isValid(parseDate(openShiftCheck.date))
        ? isBefore(date, parseDate(openShiftCheck.date)) || isEqual(date, parseDate(openShiftCheck.date))
        : false;

      if (openShiftCheck.id === openShift.id && isSameOrBefore) {
        ids.push(openShiftCheck.occurrence_id);
      }
      return ids;
    },
    [],
  );

  return openShiftAdapter.removeMany(shiftIds, state);
}

/**
 * if schedules are deleted outside of the users tab,
 * we need to remove deleted schedules.
 * We do this by removing everything that matches the requested data
 * after this we merge in the loaded schedules
 * @param state
 * @param {UnsafeAction | LoadSchedulesSuccessAction} action
 * @returns {SharedModelState<any>}
 */
function handleLoadSuccess(state: OpenShiftState, action: Action | LoadOpenShiftsSuccessAction) {
  const requestData = action.requestData || ({} as OpenShiftsLoadRequest);
  const today = format(new Date(), 'yyyy-MM-dd');
  const minDate = requestData.minDate || today;
  const maxDate = requestData.maxDate || today;
  const inPeriod = periodFilter(minDate, maxDate);

  const filterFn = (openShift: OpenShiftModel) => {
    //only remove existing schedule if it's not in the payload
    if (
      action.payload.result &&
      isArray(action.payload.result) &&
      action.payload.result.includes(openShift.occurrence_id)
    ) {
      return false;
    }

    //When the active user is invited but not available filter based on userId could wrongly remove an open shift
    if (requestData.userId) {
      return false;
    }

    //only filter the schedules for the given department
    const departmentId = openShift.department_id;
    if (requestData.departmentId && departmentId && departmentId !== requestData.departmentId) {
      return false;
    }

    return inPeriod(openShift);
  };

  const removeIds = filter(state.entities, filterFn).map((openShift: OpenShiftModel) => openShift.occurrence_id);

  state = openShiftAdapter.removeMany(removeIds, state);
  return openShiftAdapter.upsertMany(
    enhanceOpenShiftsWithStats(getArrayFromActionPayload(PayloadKeyType.OPENSHIFTS, action.payload.entities)),
    state,
  );
}

export const enhanceOpenShiftsWithStats = (openShifts: OpenShiftModel[]) => {
  if (!openShifts?.length) {
    return [];
  }

  return openShifts.map((openShift: OpenShiftModel) => enhanceOpenShiftWithStats(openShift));
};

export const enhanceOpenShiftWithStats = (openShift: OpenShiftModel) => {
  const stats = openShift.EmployeeStatus?.reduce(
    (acc, cur) => ({
      pending: acc.pending + (cur.status === OpenShiftUserStatus.PENDING ? 1 : 0),
      requested: acc.requested + (cur.status === OpenShiftUserStatus.REQUESTED ? 1 : 0),
      declined: acc.declined + (cur.status === OpenShiftUserStatus.DECLINED ? 1 : 0),
    }),
    {
      pending: 0,
      requested: 0,
      declined: 0,
    },
  );

  return {
    ...openShift,
    stats,
  };
};
