import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureService } from '@app/startup/feature.service';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { AppState } from '../reducers';
import { authState, isAuthenticated } from '../reducers/auth/auth.service';

@Injectable()
export class SignupGuard {
  public constructor(
    private store: Store<AppState>,
    public router: Router,
    public featureService: FeatureService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.retrieveAuthenticatedStatus().pipe(
      switchMap(([authenticated, authState]) => {
        if (!authenticated) {
          return observableOf(true);
        }

        if (state.url.includes('verify') && !authState?.verified) {
          return observableOf(true);
        }

        this.router.navigate(['/']);
        return observableOf(false);
      }),
    );
  }

  private retrieveAuthenticatedStatus() {
    return this.store.select(authState).pipe(
      map((state) => state.loading),
      filter((loading) => !loading),
      switchMap(() => combineLatest([this.store.select(isAuthenticated), this.store.select(authState)])),
    );
  }
}
