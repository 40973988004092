import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, switchMap } from 'rxjs/operators';
import { teamActionType } from '../orm/team/team.action';
import { departmentActionType } from '../orm/department/department.action';
import { SubscriptionService } from '../../+authenticated/+reports/shared/subscriptions/subscription.service';
import { employeeActionType } from '../orm/employee/employee.action';
import { Store } from '@ngrx/store';
import { AppState } from '../index';
import { locationActionType } from '../orm/location/location.action';
import { authActionType } from '../auth/auth.action';
import { mfaState } from '../mfa/mfa.service';
import { MfaState } from '../mfa/mfa.model';
import { authState } from '@reducers/auth/auth.service';
import { AuthState } from '@reducers/auth/auth.model';

@Injectable()
export class AccountEffect {
  updateCurrentSubscription$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(
          teamActionType.ADD_SUCCESS,
          teamActionType.UPDATE_SUCCESS,
          teamActionType.REMOVE_SUCCESS,
          departmentActionType.ADD_SUCCESS,
          departmentActionType.ACTIVATE_SUCCESS,
          departmentActionType.DEACTIVATE_SUCCESS,
          locationActionType.ADD_SUCCESS,
          locationActionType.ACTIVATE_SUCCESS,
          locationActionType.DEACTIVATE_SUCCESS,
          employeeActionType.ADD_SUCCESS,
          employeeActionType.REMOVE_SUCCESS,
          employeeActionType.MULTI_SAVE_SUCCESS,
          employeeActionType.ACTIVATE_SUCCESS
        ),
        switchMap(() => this.store.select(authState)),
        filter((authenticationState: AuthState) => authenticationState.authenticated),
        switchMap(() => this.subscriptionService.getSubscription())
      ),
    { dispatch: false }
  );

  updateCurrentSubscriptionOnAuthRefresh$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(authActionType.REFRESH_SUCCESS),
        switchMap(() => this.store.select(mfaState)),
        filter(
          (state: MfaState) =>
            (!!state.mfa_provider && state.mfa_logged_in) || (!state.mfa_provider && !state.mfa_enforced)
        ),
        switchMap(() => this.store.select(authState)),
        filter((authenticationState: AuthState) => authenticationState.authenticated),
        switchMap(() => this.subscriptionService.getSubscription())
      ),
    { dispatch: false }
  );

  public constructor(
    private actions: Actions,
    private subscriptionService: SubscriptionService,
    private store: Store<AppState>
  ) {}
}
