import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TimeLineDepartment } from '@app/+authenticated/+schedule/shared/schedule.interfaces';
import { Features } from '@app/enums';
import { FeatureService } from '@app/startup/feature.service';
import {
  BehaviorSubject,
  Observable,
  fromEvent as observableFromEvent,
  merge as observableMerge,
  of as observableOf,
  Subscription,
} from 'rxjs';
import { debounceTime, distinctUntilChanged, map, share, switchMap } from 'rxjs/operators';

import { ScrollWindowDirective } from './scroll-window.directive';
import { WindowRef } from './window';

@Directive({
  selector: '[FixedHeader]',
})
export class FixedHeaderDirective implements AfterViewInit, OnDestroy, OnInit, OnChanges, AfterViewChecked {
  private static onWindowScroll$;
  private static forceScroll$;
  private static zone: NgZone;

  private _appHeaderHeight = 104;

  @Input()
  public set appHeaderHeight(height: number) {
    if (height) {
      this._appHeaderHeight = height;
    }
  }

  public get appHeaderHeight() {
    return this._appHeaderHeight;
  }

  @Input()
  public data: TimeLineDepartment;

  private shouldUpdateOffset = false;

  @Input()
  public debounceTime = 10;
  @Input()
  public debounced = true;
  private offset: number;
  private clonedHeader;
  private headerDisplay;
  private fixedContent;

  private state: 'static' | 'dynamic' = 'static';

  private dataSubs = new Subscription();

  public constructor(
    private _element: ElementRef,
    private _window: WindowRef,
    private scrollWindow: ScrollWindowDirective,
    private zone: NgZone,
    private featureService: FeatureService,
  ) {}

  private static makeOnWindowScroll(_window, zone, debounced, time) {
    if (!FixedHeaderDirective.onWindowScroll$) {
      FixedHeaderDirective.zone = zone;

      const scrollEvent$ = observableFromEvent(_window.nativeWindow, 'scroll', {
        capture: true,
        passive: true,
      });

      const start$ = scrollEvent$.pipe(map(() => true));
      const stop$ = scrollEvent$.pipe(map(() => false));

      FixedHeaderDirective.forceScroll$ = new BehaviorSubject<boolean>(false);

      let force$: Observable<boolean> = FixedHeaderDirective.forceScroll$;
      force$ = force$.pipe(switchMap(() => observableOf(true, false)));
      if (!debounced) {
        FixedHeaderDirective.onWindowScroll$ = observableMerge(start$, stop$, force$).pipe(
          distinctUntilChanged(),
          share(),
        );
      } else {
        FixedHeaderDirective.onWindowScroll$ = observableMerge(start$, stop$, force$).pipe(
          distinctUntilChanged(),
          debounceTime(time),
          share(),
        );
      }
    }
  }

  public static forceScroll() {
    if (FixedHeaderDirective.onWindowScroll$) {
      FixedHeaderDirective.zone.runOutsideAngular(() => {
        setTimeout(() => {
          FixedHeaderDirective.forceScroll$?.next();
        }, 200);
      });
    }
  }

  private startListening() {
    let firstTime = true;

    this.dataSubs.add(
      FixedHeaderDirective.onWindowScroll$.subscribe(() => {
        // When scrolling for the first time clone the element.
        // This prevents cloning to cause the page to scroll.
        if (firstTime) {
          const headerEl = this._element.nativeElement;
          this.headerDisplay = headerEl.style.display;
          // Clone the header to avoid spacing issues
          this.clonedHeader = headerEl.cloneNode(true);
          // Add the clone before the original element
          headerEl.parentNode.insertBefore(this.clonedHeader, headerEl.nextSibling);
          this.clonedHeader.style.setProperty('display', 'none', 'important'); // Hide the header by default

          this.offset = this.getOffset();

          firstTime = false;
        }

        this.handleScrollEvent();
      }),
    );
  }

  private handleScrollEvent() {
    const headerEl = this._element.nativeElement;
    const boundingEl = this.scrollWindow.element.nativeElement;

    const boundingElRect = boundingEl.getBoundingClientRect();

    const scrollTop = boundingElRect.top;

    const startPos = scrollTop - (this.appHeaderHeight - this.offset);

    const boundingBottom = boundingElRect.bottom - (this.appHeaderHeight + 36);

    if (startPos < 0 && boundingBottom > 0) {
      if (this.state !== 'dynamic') {
        this.setAsDynamic();
      }

      if (headerEl.classList.contains('hours-overview')) {
        this.setTranslate(`
            translateY(${scrollTop * -1 - this.offset - 2}px)
            translateZ(0)
          `);
      }
    } else if (this.state !== 'static') {
      this.setAsStatic();
    }
  }

  private setTranslate(value) {
    this._element.nativeElement.style.transform = value;
  }

  private showFixedContent(show: boolean) {
    if (this.fixedContent) {
      const display = show ? 'block' : 'none';

      for (let i = 0, len = this.fixedContent.length; i < len; i++) {
        this.fixedContent[i].style.setProperty('display', display, 'important');
      }
    }
  }

  private setAsStatic() {
    const headerEl = this._element.nativeElement;
    this.state = 'static';

    headerEl.style.display = null;
    headerEl.style.position = null;
    headerEl.style.left = null;
    headerEl.style.right = null;
    headerEl.style.zIndex = null;

    headerEl.classList.remove('fixed-element', 'fixed-header-element');

    this.showFixedContent(false);

    this.setTranslate('');

    this.clonedHeader.style.setProperty('display', 'none', 'important');
  }

  private setAsDynamic() {
    const headerEl = this._element.nativeElement;
    const boundingEl = this.scrollWindow.element.nativeElement;

    boundingEl.style.position = 'relative';

    this.clonedHeader.style.setProperty('display', this.headerDisplay, 'important');
    this.showFixedContent(true);

    headerEl.style.display = '';
    headerEl.style.left = 0;
    headerEl.style.right = 0;
    headerEl.style.zIndex = 1000;
    headerEl.style.top = this.appHeaderHeight + 'px';
    if (headerEl.classList.contains('hours-overview')) {
      headerEl.classList.add('fixed-element');
    } else {
      headerEl.classList.add('fixed-header-element');
    }

    this.state = 'dynamic';
  }

  private getOffset() {
    const headerEl = this._element.nativeElement;
    const boundingEl = this.scrollWindow.element.nativeElement;

    return headerEl.getBoundingClientRect().top - boundingEl.getBoundingClientRect().top;
  }

  private updateOffset() {
    // Unfix the header to get the actual offset
    // If still needs to be fixed,
    // it will be fixed with the handleScrollEvent call
    if (this.state === 'dynamic') {
      this.setAsStatic();
    }

    const newOffset = this.getOffset();

    if (newOffset !== this.offset) {
      this.offset = newOffset;
    }

    this.handleScrollEvent();
  }

  public ngOnInit() {
    this.fixedContent = this._element.nativeElement.querySelectorAll('[show-fixed]');

    this.showFixedContent(false);
  }

  public ngOnDestroy() {
    // For some reason unsubscribing immediately causes the browser to start scrolling.
    // My best guess is that is we get caught in the middle of a 'handleScrollEvent' somehow.
    setTimeout(() => {
      this.dataSubs.unsubscribe();
      FixedHeaderDirective.forceScroll$ = undefined;
      FixedHeaderDirective.onWindowScroll$ = undefined;
    }, 1000);
  }

  public ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      FixedHeaderDirective.makeOnWindowScroll(this._window, this.zone, this.debounced, this.debounceTime);
      this.startListening();
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && !this.shouldUpdateOffset) {
      // Update offset on data change
      this.shouldUpdateOffset = true;
    }
  }

  public ngAfterViewChecked() {
    // Wait for the view to be rendered before updating the offset
    if (this.shouldUpdateOffset) {
      setTimeout(() => {
        this.updateOffset();
      }, 50);
      this.shouldUpdateOffset = false;
    }
  }
}
