import { Injectable } from '@angular/core';
import { AppState } from '@app/reducers';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  absencePolicyAddFailed,
  absencePolicyAddRequest,
  absencePolicyAddSuccess,
  absencePolicyDeleteFailed,
  absencePolicyDeleteRequest,
  absencePolicyDeleteSuccess,
  absencePolicyLoadAllFailed,
  absencePolicyLoadAllRequest,
  absencePolicyLoadAllSuccess,
  absencePolicyLoadFailed,
  absencePolicyLoadRequest,
  absencePolicyLoadSuccess,
  absencePolicyRemoveConfigurationRequest,
  absencePolicyUpdateConfigurationRequest,
  absencePolicyUpdateFailed,
  absencePolicyUpdateManyFailed,
  absencePolicyUpdateManyRequest,
  absencePolicyUpdateManySuccess,
  absencePolicyUpdateRequest,
  absencePolicyUpdateSuccess,
} from './absence-policy.action';
import { AbsencePolicyApi } from './absence-policy.api';
import { AbsencePolicyModel } from './absence-policy.model';
import { selectEntities, selectPolicyById } from './absence-policy.selectors';

const policiesCache = new Map<
  string,
  {
    policies: AbsencePolicyModel[];
  }
>();

@Injectable()
export class AbsencePolicyEffect {
  public addRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(absencePolicyAddRequest),
      switchMap(({ policy }) =>
        this.api.add(policy).pipe(
          map((policy) => absencePolicyAddSuccess({ policy })),
          catchError(() => of(absencePolicyAddFailed()))
        )
      )
    )
  );

  public loadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(absencePolicyLoadRequest),
      switchMap(({ policyId }) =>
        this.api.load(policyId).pipe(
          map((policy) => absencePolicyLoadSuccess({ policy })),
          catchError(() => of(absencePolicyLoadFailed({ policyId })))
        )
      )
    )
  );

  public updateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(absencePolicyUpdateRequest),
      concatLatestFrom(({ policy }) => this.store$.select(selectPolicyById(policy.id))),
      switchMap(([{ policy }, storePolicy]) =>
        this.api.update({ ...storePolicy, ...policy }).pipe(
          map((policy) => absencePolicyUpdateSuccess({ policy })),
          catchError(() => of(absencePolicyUpdateFailed({ policyId: policy.id })))
        )
      )
    )
  );

  public loadAllRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(absencePolicyLoadAllRequest),
      switchMap((action) => {
        const serialAction = JSON.stringify(action);
        if (!policiesCache.has(serialAction)) {
          return this.api.loadAll().pipe(
            map((policies) => {
              policiesCache.set(serialAction, { policies });
              return absencePolicyLoadAllSuccess({ policies });
            }),
            catchError(() => of(absencePolicyLoadAllFailed()))
          );
        }
        return of(absencePolicyLoadAllSuccess({ policies: policiesCache.get(serialAction).policies }));
      })
    )
  );

  public deleteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(absencePolicyDeleteRequest),
      switchMap(({ policyId }) =>
        this.api.delete(policyId).pipe(
          map(() => absencePolicyDeleteSuccess({ policyId })),
          catchError(() => of(absencePolicyDeleteFailed({ policyId })))
        )
      )
    )
  );

  public updateConfigurationRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(absencePolicyUpdateConfigurationRequest),
      concatLatestFrom(({ policyId }) => this.store$.select(selectPolicyById(policyId))),
      map(([{ configuration }, storePolicy]) => ({
        ...storePolicy,
        configuration: [
          ...(storePolicy.configuration?.filter(
            (currConfig) => currConfig.absenceTypeId !== configuration.absenceTypeId
          ) || []),
          configuration,
        ],
      })),
      switchMap((policy) =>
        this.api.update(policy).pipe(
          map((policy) => absencePolicyUpdateSuccess({ policy })),
          catchError(() => of(absencePolicyUpdateFailed({ policyId: policy.id })))
        )
      )
    )
  );

  public absencePolicyUpdateManyRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(absencePolicyUpdateManyRequest),
      concatLatestFrom(() => this.store$.select(selectEntities)),
      map(([{ policies }, entries]) =>
        policies
          .filter((policy) => policy.activated)
          .map((policy) => {
            {
              const storePolicy = entries[policy.policyId];
              return {
                ...storePolicy,
                configuration: [
                  ...(storePolicy.configuration?.filter(
                    (currConfig) => currConfig.absenceTypeId !== policy.configuration.absenceTypeId
                  ) || []),
                  policy.configuration,
                ],
              };
            }
          })
      ),
      switchMap((policies) =>
        this.api.updateMany(policies).pipe(
          map((policies) => absencePolicyUpdateManySuccess({ policies })),
          catchError(() => of(absencePolicyUpdateManyFailed()))
        )
      )
    )
  );

  public removeConfigurationRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(absencePolicyRemoveConfigurationRequest),
      concatLatestFrom(({ policyId }) => this.store$.select(selectPolicyById(policyId))),
      map(([{ absenceTypeId }, policy]) => ({
        ...policy,
        configuration: policy.configuration.filter((config) => config.absenceTypeId !== absenceTypeId),
      })),
      switchMap((policy) =>
        this.api.update(policy).pipe(
          map((policy) => absencePolicyUpdateSuccess({ policy })),
          catchError(() => of(absencePolicyUpdateFailed({ policyId: policy.id })))
        )
      )
    )
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
    private readonly api: AbsencePolicyApi
  ) {}
}
