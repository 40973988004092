import { isBefore, isEqual, isValid, toDate } from 'date-fns';
import filter from 'lodash-es/filter';
import isArray from 'lodash-es/isArray';
import omit from 'lodash-es/omit';
import reduce from 'lodash-es/reduce';

import { format, parseDate, periodFilter } from '../../../shared/date.helper';
import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { exchangeActionType } from '../exchange/exchange.action';
import { openShiftActionType } from '../open-shift/open-shift.action';
import { addEntity, getEntity, mergeEntities, removeEntities, removeEntity, updateEntitiesById } from '../orm';
import { scheduleActionType } from './schedule.action';
import { LoadSchedulesSuccessAction, ScheduleModel, SchedulesLoadRequest, ScheduleState } from './schedule.model';

const entityType = 'schedules';

const initialState: ScheduleState = {
  items: [],
  itemsById: {},
};

export function ScheduleReducer(
  state: ScheduleState = initialState,
  action: Action | LoadSchedulesSuccessAction,
): ScheduleState {
  const payload = action.payload;

  switch (action.type) {
    case scheduleActionType.LOAD_SUCCESS:
    case scheduleActionType.LOAD_SCHEDULE_DATASET_SUCCESS:
      return handleLoadSuccess(state, action);

    case scheduleActionType.REMOVE:
      return optimisticHandleDelete(state, payload);

    case scheduleActionType.ADD:
    case openShiftActionType.ASSIGN:
      return optimisticHandleAdd(state, payload);

    case scheduleActionType.ADD_SUCCESS: {
      state = removeEntity(state, payload.loadingId);

      return mergeEntities(state, payload.entities[entityType]);
    }

    case scheduleActionType.UPDATE:
      return optimisticHandleUpdate(state, payload);

    case scheduleActionType.UPDATE_SUCCESS:
    case openShiftActionType.ASSIGN_SUCCESS: {
      state = removeEntity(state, payload.loadingId);

      return mergeEntities(state, payload.entities[entityType]);
    }

    case exchangeActionType.ADD_SUCCESS:
      const newExchange = payload.entities.exchanges[payload.result];
      const currentRoster = getEntity(state, newExchange.Roster);
      const newRoster = {
        ...currentRoster,
        Exchange: payload.result,
      };

      state = removeEntity(state, newExchange.Roster);
      return addEntity(state, newRoster, 'occurrence_id');

    case exchangeActionType.DELETE_EXCHANGE_SUCCESS:
      const roster = getEntity(state, payload.Roster);
      const updatedRoster = {
        ...roster,
        Exchange: null,
      };

      state = removeEntity(state, payload.Roster);
      return addEntity(state, updatedRoster, 'occurrence_id');

    case exchangeActionType.ACCEPTED_BY_SUPERVISOR_SUCCESS:
      const exchange = payload.entities.exchanges[payload.result];
      state = removeEntity(state, exchange.Roster);
      return addEntity(state, exchange.NewRoster, 'occurrence_id');

    default:
      if (containsEntity(action, entityType)) {
        return mergeEntities(state, getEntities(action, entityType));
      }

      return state;
  }
}

function optimisticHandleAdd(state, payload) {
  const { scheduleData, loadingId } = payload;

  // Create a new schedule which is in the loading state.
  const schedule = {
    ...scheduleData,
    occurrence_id: loadingId,
    loading: true,
  };

  // And add the new 'loading' schedule.
  return addEntity(state, schedule, 'occurrence_id');
}

function optimisticHandleUpdate(state, payload) {
  const { scheduleData, occurrenceId, scope, loadingId } = payload;

  // Create a new schedule which is in the loading state.
  let schedule = getEntity(state, occurrenceId);
  schedule = {
    ...schedule,
    ...scheduleData,
    occurrence_id: loadingId,
    loading: true,
  };

  /*
    Omit all properties which are used to calculate totals, we do not want
    to display totals when we are loading the current schedule.
  */
  schedule = omit(schedule, 'wage', 'salary', 'total');

  /*
    When 'original' and 'sequence' remove all future occurrences, but
    keep the past ones intact.

    The back-end will respond with the new future occurrences eventually.
  */
  if (scope !== 'occurrence') {
    state = removeFutureOccurrences(state, schedule);
  }

  // Finally remove the dragged schedule
  state = removeEntity(state, occurrenceId);

  /*
    It is possible to use the update schedule method to change the schedule
    to an Open Shift. In this case, the aforementioned remove methods should be
    called, but the following addEntity method should not be called. The Open Shift
    reducer will take care of adding the new Open Shifts.
  */
  if (schedule.user_id) {
    // And add the new 'loading' schedule.
    return addEntity(state, schedule, 'occurrence_id');
  } else {
    // Don't add the new Schedule, since it is now an Open Shift
    return state;
  }
}

function optimisticHandleDelete(state, payload) {
  const { occurrenceId, scope } = payload;

  const schedule = getEntity(state, occurrenceId);
  if (!schedule) {
    return state;
  }

  if (scope !== 'occurrence') {
    return removeFutureOccurrences(state, schedule);
  } else {
    return removeEntity(state, occurrenceId);
  }
}

function removeFutureOccurrences(state, schedule: ScheduleModel) {
  const date = parseDate(schedule.date);

  const ids = reduce(
    state.itemsById,
    (ids, scheduleCheck: ScheduleModel) => {
      const isSameOrBefore = isValid(parseDate(scheduleCheck.date))
        ? isBefore(date, parseDate(scheduleCheck.date)) || isEqual(date, parseDate(scheduleCheck.date))
        : false;

      if (scheduleCheck.id === schedule.id && isSameOrBefore) {
        ids.push(scheduleCheck.occurrence_id);
      }
      return ids;
    },
    [],
  );

  return removeEntities(state, ids);
}

/**
 * if schedules are deleted outside of the users tab,
 * we need to remove deleted schedules.
 * We do this by removing everything that matches the requested data
 * after this we merge in the loaded schedules
 * @param state
 * @param {UnsafeAction | LoadSchedulesSuccessAction} action
 * @returns {SharedModelState<any>}
 */
function handleLoadSuccess(state: ScheduleState, action: Action | LoadSchedulesSuccessAction) {
  const requestData = action.requestData || ({} as SchedulesLoadRequest);
  const today = format(new Date(), 'yyyy-MM-dd');
  const minDate = requestData.minDate || today;
  const maxDate = requestData.maxDate || today;
  const inPeriod = periodFilter(minDate, maxDate);

  const filterFn = (schedule: ScheduleModel) => {
    //only remove existing schedule if it's not in the payload
    if (
      action.payload.result &&
      isArray(action.payload.result) &&
      action.payload.result.includes(schedule.occurrence_id)
    ) {
      return false;
    }

    //only filter the schedules for the given user
    if (requestData.userId && schedule.user_id !== requestData.userId) {
      return false;
    }

    //only filter the schedules for the given department
    const departmentId = schedule.department_id;

    if (requestData.departmentId && departmentId && departmentId !== requestData.departmentId) {
      return false;
    }

    return inPeriod(schedule);
  };

  const removeIds = filter(state.itemsById, filterFn).map((schedule: ScheduleModel) => schedule.occurrence_id);

  return mergeEntities(removeEntities(state, removeIds), getEntities(action, entityType));
}
