import { createReducer, on } from '@ngrx/store';
import { userGuideLoadSuccess, userGuideUpdateSuccess } from '@reducers/orm/user-guide/user-guide.action';
import { initialState, userGuideModelEntityAdapter, UserGuideState } from '@reducers/orm/user-guide/user-guide.state';

export const userGuideReducer = createReducer(
  initialState,
  on(userGuideLoadSuccess, (state, action) =>
    userGuideModelEntityAdapter.upsertMany<UserGuideState>(action.userGuides, { ...state, loaded: true }),
  ),
  on(userGuideUpdateSuccess, (state, action) =>
    userGuideModelEntityAdapter.upsertOne<UserGuideState>(action.userGuide, state),
  ),
);
