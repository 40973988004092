import { auditTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HorizontalScrollableDirective } from './horizontal-scrollable.directive';
import { HorizontalScrollbarComponent } from './horizontal-scrollbar.component';
import maxBy from 'lodash-es/maxBy';
import { Subject } from 'rxjs';

const scrollInPixels = 10;

@Injectable()
export class HorizontalScrollService {
  // We need to keep track of the current scroll position, so when
  // a new HorizontalScrollableDirective registers we can give
  // it the correct scroll position.
  private scrollPosition = 0;
  private maxScroll = 0;

  private determineSubject: Subject<any> = new Subject();

  private scrollables: HorizontalScrollableDirective[] = [];
  private scrollbar: HorizontalScrollbarComponent | undefined;

  constructor() {
    this.determineSubject.pipe(auditTime(200)).subscribe(() => {
      this.setScrollbarWidth();
    });
  }

  reset(scrollbar: HorizontalScrollbarComponent | undefined) {
    this.scrollbar = scrollbar;

    this.scrollPosition = 0;
    this.maxScroll = 0;
    if (scrollbar === undefined) {
      this.scrollables = [];
    }
  }

  register(scrollable: HorizontalScrollableDirective) {
    this.scrollables.push(scrollable);

    /**
     * Set the scrollable position to the current scroll position
     */
    scrollable.handleScroll(this.scrollPosition);
    this.determineScrollbarWidth();
  }

  deregister(scrollable: HorizontalScrollableDirective) {
    this.scrollables = this.scrollables.filter((s) => s !== scrollable);
  }

  determineScrollbarWidth() {
    this.determineSubject.next(null);
  }

  setScrollbarWidth() {
    if (!this.scrollbar || this.scrollables.length === 0) {
      return;
    }

    const scrollable = maxBy(this.scrollables, (scrollableItem) => scrollableItem.viewPercentage().maxScroll);

    if (!scrollable) {
      this.resetScroll();
      return;
    }

    const view = scrollable.viewPercentage();

    this.setScrollPosition(view.scrollLeft);
    this.maxScroll = view.maxScroll;
    const canScroll = view.canScroll;

    if (!canScroll) {
      this.scrollbar.setVisiblity(false);
      this.resetScroll();
    } else {
      this.scrollbar.setMaxValue(this.maxScroll);
      this.scrollbar.setVisiblity(true);
    }
  }

  private resetScroll() {
    this.scrollPosition = 0;
    this.maxScroll = 0;
    if (this.scrollbar) {
      this.scrollbar.setPosition(0);
    }
  }

  public scrollTriggered(position: number) {
    this.setScrollPosition(position);
  }

  private syncScrollPositions() {
    this.scrollables.forEach((s) => s.handleScroll(this.scrollPosition));
  }

  private setScrollPosition(position: number) {
    if (position < 0) {
      position = 0;
    } else if (position >= this.maxScroll) {
      position = this.maxScroll;
    }
    if (this.scrollPosition === position) {
      return;
    }
    this.scrollPosition = position;
    if (this.scrollbar) {
      this.scrollbar.setPosition(position);
    }

    this.syncScrollPositions();
  }

  private setScrollLeft(position: number) {
    this.setScrollPosition(position);
  }

  private setScrollRight(position: number) {
    this.setScrollPosition(position);
  }

  scrollLeft(pixels: number = scrollInPixels) {
    const nextPosition = this.scrollPosition - pixels;
    this.setScrollLeft(nextPosition);
  }

  scrollRight(pixels: number = scrollInPixels) {
    const nextPosition = this.scrollPosition + pixels;
    this.setScrollRight(nextPosition);
  }
}
