import filter from 'lodash-es/filter';
import isArray from 'lodash-es/isArray';

import { format, periodFilter } from '../../../shared/date.helper';
import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities, getResultEntity } from '../../shared/entity.helper';
import { addEntity, mergeEntities, removeEntities, removeEntity, updateEntitiesById } from '../orm';
import { timesheetActionType } from './timesheet.action';
import { LoadTimesheetsSuccessAction, TimesheetModel, TimesheetsLoadRequest, TimesheetState } from './timesheet.model';

const entityType = 'timesheets';

const initialState: TimesheetState = {
  items: [],
  itemsById: {},
  clockState: {},
};

export function TimesheetReducer(
  state: TimesheetState = initialState,
  action: Action | LoadTimesheetsSuccessAction,
): TimesheetState {
  const { type, payload } = action;

  switch (type) {
    case timesheetActionType.LOAD_SUCCESS: {
      return handleLoadSuccess(state, action);
    }

    case timesheetActionType.ADD_SUCCESS: {
      if (!payload?.entities[entityType]) {
        return state;
      }
      return mergeEntities(state, payload.entities[entityType]);
    }

    case timesheetActionType.UPDATE: {
      return updateEntitiesById(state, { saving: true }, payload.id);
    }

    case timesheetActionType.UPDATE_FAILED: {
      return updateEntitiesById(state, { saving: false }, payload.id);
    }

    case timesheetActionType.UPDATE_SUCCESS: {
      const updatedState = updateEntitiesById(
        mergeEntities(state, getEntities(action, entityType)),
        { saving: false },
        payload.result,
      );

      const updatedTimesheet = getResultEntity(action, entityType);
      if (state.clockState.Timesheet && updatedTimesheet) {
        const hasClockStateChanged = state.clockState.Timesheet.active_clock !== updatedTimesheet.active_clock;

        if (hasClockStateChanged) {
          return {
            ...updatedState,
            clockState: {
              Timesheet: updatedTimesheet,
            },
          };
        }
      }

      return updatedState;
    }

    case timesheetActionType.CLOCK_STATE_SUCCESS: {
      if (payload.Timesheet) {
        const timesheet = {
          ...payload.Timesheet,
        };

        if (payload.ClockBreak) {
          timesheet.ClockBreak = payload.ClockBreak.filter(
            (clockBreak) => clockBreak.timesheet_id === payload.Timesheet.id,
          );
        }

        const updatedState = removeEntity(state, timesheet.id);
        return { ...addEntity(updatedState, timesheet), clockState: payload };
      }
      return { ...state, clockState: payload };
    }

    case timesheetActionType.CLOCK_STATE_UPDATE_SUCCESS: {
      return { ...state, clockState: payload[1] };
    }

    case timesheetActionType.CLOCK_STATE_FAILED: {
      return { ...state, clockState: {} };
    }

    default:
      if (containsEntity(action, entityType)) {
        return mergeEntities(state, getEntities(action, entityType));
      }

      return state;
  }
}

/**
 * if schedules are deleted outside of the users tab,
 * we need to remove deleted schedules.
 * We do this by removing everything that matches the requested data
 * after this we merge in the loaded schedules
 * @param state
 * @param {UnsafeAction | LoadSchedulesSuccessAction} action
 * @returns {SharedModelState<any>}
 */
function handleLoadSuccess(state: TimesheetState, action: Action | LoadTimesheetsSuccessAction) {
  const requestData = action.requestData || ({} as TimesheetsLoadRequest);
  const today = format(new Date(), 'yyyy-MM-dd');
  const minDate = requestData.minDate || today;
  const maxDate = requestData.maxDate || today;
  const inPeriod = periodFilter(minDate, maxDate);

  const filterFn = (timesheet: TimesheetModel) => {
    //only remove existing timesheet if it's not in the payload
    if (action.payload.result && isArray(action.payload.result) && action.payload.result.includes(timesheet.id)) {
      return false;
    }

    //only filter the schedules for the given user
    if (requestData.userId && timesheet.user_id !== requestData.userId) {
      return false;
    }

    //only filter the schedules for the given department
    const departmentId = timesheet.department_id || timesheet.Department ? timesheet.Department.id : null;
    if (requestData.departmentId && departmentId && departmentId !== requestData.departmentId) {
      return false;
    }

    if (requestData.status && timesheet.status !== requestData.status) {
      return false;
    }

    return inPeriod(timesheet);
  };

  const removeIds = filter(state.itemsById, filterFn).map((timesheet: TimesheetModel) => timesheet.id);

  return mergeEntities(removeEntities(state, removeIds), getEntities(action, entityType));
}
