import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import u from 'updeep';

import { AppState } from '../../index';
import { mapEntities, mapEntity } from '../../shared/entity.helper';
import { CommentAction } from './comment.action';
import { CommentApi } from './comment.api';
import { CommentState } from './comment.model';

@Injectable()
export class CommentService {
  constructor(
    private store: Store<AppState>,
    private api: CommentApi,
  ) {}

  // load() {
  //     this.store.dispatch( CommentAction.load() );
  //
  //     return this.api.load()
  //         .map( response => {
  //             this.store.dispatch( CommentAction.loadSuccess( response ) );
  //             return response;
  //         } )
  //         .catch( response => {
  //             this.store.dispatch( CommentAction.loadFailed( response ) );
  //             return Observable.throw( response );
  //         } )
  // }

  add(commentData): Observable<any> {
    return this.api.add(commentData, CommentAction.add(commentData.news_item_id, commentData)).pipe(
      map((response) => {
        this.store.dispatch(CommentAction.addSuccess(commentData.news_item_id, response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(CommentAction.addFailed(commentData.news_item_id, response));
        return observableThrowError(response);
      }),
    );
  }

  update(id, commentData) {
    this.store.dispatch(CommentAction.update(commentData.news_item_id, commentData));

    return this.api.update(id, commentData).pipe(
      map((response) => {
        this.store.dispatch(CommentAction.updateSuccess(commentData.news_item_id, response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(CommentAction.updateFailed(commentData.news_item_id, response));
        return observableThrowError(response);
      }),
    );
  }

  fetch(id) {
    this.store.dispatch(CommentAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(CommentAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(CommentAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  remove(commentData) {
    this.store.dispatch(CommentAction.remove(commentData.news_item_id, commentData));

    return this.api.remove(commentData.id).pipe(
      map((response) => {
        this.store.dispatch(CommentAction.removeSuccess(commentData.news_item_id, commentData.id));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(CommentAction.removeFailed(commentData.news_item_id, response));
        return observableThrowError(response);
      }),
    );
  }

  save(commentData) {
    if (commentData.id) {
      return this.update(commentData.id, commentData);
    }

    commentData = u.omit('id', commentData);

    return this.add(commentData);
  }
}

export const getCommentState = (appState: AppState): CommentState => appState.orm.comments;

export const getCommentIds = compose((state) => state.items, getCommentState);
export const getCommentEntities = createSelector(getCommentState, (state) => state.itemsById);
export const getComments = createSelector(getCommentIds, getCommentEntities, mapEntities);

export const getComment = (id: string) => createSelector(getCommentEntities, (entities) => mapEntity(id, entities));
