import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  pure: true,
  standalone: true,
})
export class SearchPipe implements PipeTransform {
  public transform(
    items: any[],
    callback: (item: any, query: string) => boolean,
    query: string
  ): { items: any; query: string; isEmpty: boolean } {
    if (query === '') {
      return { items, query, isEmpty: false };
    }

    const filtered = items.filter((item) => callback(item, query));
    const isEmpty = filtered.length === 0;

    return { items: filtered, query, isEmpty };
  }
}
