import { AfterViewInit, Directive, ElementRef, NgZone, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  fromEvent as observableFromEvent,
  merge as observableMerge,
  of as observableOf,
} from 'rxjs';
import { debounceTime, distinctUntilChanged, map, share, switchMap, tap } from 'rxjs/operators';

import { ScrollWindowDirective } from './scroll-window.directive';
import { WindowRef } from './window';

@Directive({
  selector: '[FixedContent]',
})
export class FixedContentDirective implements AfterViewInit, OnDestroy {
  private offset: number;
  private onScroll;

  private state: 'static' | 'dynamic' = 'static';

  private static onWindowScroll$;
  private static forceScroll$;
  private static zone: NgZone;

  public constructor(
    private _element: ElementRef,
    private _window: WindowRef,
    private scrollWindow: ScrollWindowDirective,
    private zone: NgZone,
  ) {}

  public ngOnDestroy() {
    // For some reason unsubscribing immediately causes the browser to start scrolling.
    // My best guess is that is we get caught in the middle of a 'handleScrollEvent' somehow.
    setTimeout(() => {
      if (this.onScroll) {
        this.onScroll.unsubscribe();
      }
    }, 1000);
  }

  public ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      FixedContentDirective.makeOnWindowScroll(this._window, this.zone);
      this.startListening();
    });
  }

  private static makeOnWindowScroll(_window, zone) {
    if (!FixedContentDirective.onWindowScroll$) {
      FixedContentDirective.zone = zone;

      const scrollEvent$ = observableFromEvent(_window.nativeWindow, 'scroll', {
        capture: true,
        passive: true,
      });

      const start$ = scrollEvent$.pipe(map(() => true));

      FixedContentDirective.forceScroll$ = new BehaviorSubject<boolean>(false);

      let force$: Observable<boolean> = FixedContentDirective.forceScroll$;
      force$ = force$.pipe(switchMap(() => observableOf(true)));

      FixedContentDirective.onWindowScroll$ = observableMerge(start$, force$).pipe(
        distinctUntilChanged(),
        debounceTime(10),
        share(),
      );
    }
  }

  private startListening() {
    let firstTime = true;

    this.onScroll = FixedContentDirective.onWindowScroll$.subscribe(() => {
      if (firstTime) {
        const contentEl = this._element.nativeElement;
        const boundingEl = this.scrollWindow.element.nativeElement;

        this.offset = contentEl.getBoundingClientRect().top - boundingEl.getBoundingClientRect().top;

        firstTime = false;
      }

      this.handleScrollEvent();
    });
  }

  private handleScrollEvent() {
    const appHeaderHeight = 104;
    const headerHeight = 55;

    const contentEl = this._element.nativeElement;
    const boundingEl = this.scrollWindow.element.nativeElement;

    const contentElRect = contentEl.getBoundingClientRect();
    const boundingElRect = boundingEl.getBoundingClientRect();

    /*
      Do not do anything when the content fits the bounds perfectly,
      because scrolling then solves nothing, and just creates a wobbily
      UI.
    */
    if (contentElRect.height === boundingElRect.height) {
      this.makeStatic();
      return;
    }

    const scrollTop = boundingElRect.top;

    const startPos = scrollTop - (appHeaderHeight - this.offset);

    const boundingBottom = boundingElRect.bottom - (appHeaderHeight + headerHeight);

    if (startPos < 0 && boundingBottom > 0) {
      if (this.state !== 'dynamic') {
        boundingEl.style.position = 'relative';

        contentEl.classList.add('fixed-element');
        contentEl.style.display = '';
        contentEl.style.position = 'absolute';
        contentEl.style.left = 0;
        contentEl.style.right = 0;
        contentEl.style.zIndex = 1000;
        contentEl.style.opacity = 1;
        contentEl.style.perspective = 1000;
        contentEl.style.backfaceVisibility = 'hidden';
      }

      this.state = 'dynamic';

      // Calculate the new y position.
      let y = scrollTop * -1 + appHeaderHeight - this.offset - 2;

      /// If y goes out of bounds set y the last position before it goes out of bounds.
      if (y + contentEl.clientHeight > boundingElRect.height) {
        y = boundingElRect.height - contentEl.clientHeight;
      }

      this.setTranslate(`
        translateY(${y}px)
        translateZ(0)
      `);
    } else if (this.state !== 'static') {
      this.makeStatic();
    }
  }

  private makeStatic() {
    if (this.state === 'static') {
      return;
    }

    const contentEl = this._element.nativeElement;

    this.state = 'static';

    contentEl.style.display = null;
    contentEl.style.position = null;
    contentEl.style.left = null;
    contentEl.style.right = null;
    contentEl.style.zIndex = null;
    contentEl.style.opacity = 1;
    contentEl.classList.remove('fixed-element');

    this.setTranslate('');
  }

  private setTranslate(value) {
    this._element.nativeElement.style.transform = value;
  }

  public static forceScroll() {
    if (FixedContentDirective.onWindowScroll$) {
      FixedContentDirective.zone.runOutsideAngular(() => {
        setTimeout(() => {
          FixedContentDirective.forceScroll$.next();
        }, 200);
      });
    }
  }
}
