import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { resetEntities, mergeEntities } from '../orm';
import { NotificationState } from './notification.model';
import { notificationActionType } from './notification.action';

const entityType = 'notification';

const initialState: NotificationState = {
  items: [],
  itemsById: {},
};

export function NotificationReducer(state: NotificationState = initialState, action: Action): NotificationState {
  let payload = action.payload;

  switch (action.type) {
    case notificationActionType.LOAD_SUCCESS:
      let entities = getEntities(action, entityType);
      return resetEntities(state, entities);

    case notificationActionType.BATCH_UPDATE_SUCCESS:
      return mergeEntities(state, payload);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}
