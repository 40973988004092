import { SchedulesLoadRequest } from '../../orm/schedule/schedule.model';

const ACTION_PREFIX = '[TeamDataN]';

export const teamDataNActionType = {
  LOAD_SUCCESS: `${ACTION_PREFIX} LOAD SUCCESS`,
};

export class TeamDataNAction {
  static loadSuccess(payload, requestData: SchedulesLoadRequest) {
    return {
      type: teamDataNActionType.LOAD_SUCCESS,
      payload,
      departmentId: requestData.departmentId,
    };
  }
}
