import { of as observableOf, throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { createSelector } from 'reselect';
import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { AppState } from '../../index';
import { mapEntities, mapEntity } from '../../shared/entity.helper';
import groupBy from 'lodash-es/groupBy';
import keyBy from 'lodash-es/keyBy';
import mapValues from 'lodash-es/mapValues';
import { NotificationApi } from './notification.api';
import { NotificationAction } from './notification.action';
import { NotificationModel, NotificationState } from './notification.model';

@Injectable()
export class NotificationService {
  constructor(private store: Store<AppState>, private api: NotificationApi) {}

  load() {
    this.store.dispatch(NotificationAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(NotificationAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(NotificationAction.loadFailed(response));
        return observableThrowError(response);
      })
    );
  }

  batchUpdate(batch, ignoreMetaMessage: boolean = false) {
    this.store.dispatch(NotificationAction.batchUpdate(batch));

    return this.api.batchUpdate({ Notification: batch }, ignoreMetaMessage).pipe(
      map((response) => {
        this.store.dispatch(NotificationAction.batchUpdateSuccess(batch));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(NotificationAction.batchUpdateFailed(response));
        return observableThrowError(response);
      })
    );
  }
}

export const getNotificationState = (appState: AppState): NotificationState => appState.orm.notifications;

export const getNotificationIds = compose((state) => state.items, getNotificationState);
export const getNotificationEntities = compose((state) => state.itemsById, getNotificationState);
export const getNotifications = createSelector(getNotificationIds, getNotificationEntities, mapEntities);

export const getNotification = (id: string) =>
  createSelector(getNotificationEntities, (entities) => mapEntity(id, entities));

export const getNotificationsForForm = createSelector(getNotifications, (notifications: NotificationModel[]) =>
  mapValues(groupBy(notifications, 'department_id'), (group) => keyBy(group, 'message'))
);
