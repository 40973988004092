import { CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { ReportColumn } from '../../+authenticated/+reports/shared/api/ReportApi';

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000,
};

@Component({
  selector: 'report-columns',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './report-columns.component.html',
  styleUrls: ['report-columns.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
})
export class ReportColumnsComponent implements OnChanges {
  @Input()
  public icon?: string;

  @Input()
  public buttonText: string;

  @Input()
  public columns: ReportColumn[];
  public isOpen = false;

  @Output()
  public columnsChanged = new EventEmitter<ReportColumn[]>();

  public filteredColumns: ReportColumn[];
  public filterText = '';

  public allSelected: boolean;
  public positions = [
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 7),
  ];

  public ngOnChanges() {
    this.allSelected = this.columns.every((col) => col.checked);
    this.filteredColumns = this.columns;
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.columnsChanged.emit(this.columns);
  }

  public checkedStateChanged() {
    this.columnsChanged.emit(this.columns);
    this.allSelected = this.columns.every((col) => col.checked);
  }

  public selectAll() {
    if (this.allSelected) {
      this.columns.forEach((col) => (col.checked = true));
      this.columnsChanged.emit(this.columns);
      return;
    }

    this.columns.forEach((col) => (col.checked = false));
    this.columnsChanged.emit(this.columns);
  }

  public onSearchChange(value: string) {
    if (!value) {
      this.filteredColumns = this.columns;
      return;
    }

    this.filteredColumns = this.columns.filter((column: ReportColumn) =>
      column.label.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
    );
  }
}
