import { Pipe, PipeTransform } from '@angular/core';
import { isValid, endOfYear } from 'date-fns';
import { format, parseDate } from '../shared/date.helper';

@Pipe({
  name: 'dateEndOfYear',
  pure: true,
  standalone: true,
})
export class DateEndOfYearPipe implements PipeTransform {
  public transform(value: string | Date, formatting = 'P'): string {
    if (!value) {
      return '';
    }

    value = parseDate(value);

    if (!isValid(value)) {
      return format(endOfYear(new Date()), formatting);
    }

    return format(endOfYear(value), formatting);
  }

  public parse(value: string): string {
    const date = parseDate(value);

    if (!isValid(date)) {
      return '';
    }

    return format(date, 'yyyy-MM-dd');
  }
}
