import { UnsafeAction as Action } from '../../interfaces';
import { ExchangeState } from './exchange.model';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { mergeEntities, resetEntities, removeEntity, mergeEntity } from '../orm';
import { exchangeActionType } from './exchange.action';

const entityType = 'exchanges';

const initialState: ExchangeState = {
  items: [],
  itemsById: {},
  loading: false,
};

export function ExchangeReducer(state: ExchangeState = initialState, action: Action): ExchangeState {
  let payload = action.payload;

  switch (action.type) {
    case exchangeActionType.LOAD_INCOMING_SUCCESS:
      return mergeEntities(state, getEntities(action, entityType));

    case exchangeActionType.LOAD_OUTGOING_SUCCESS:
      return mergeEntities(state, getEntities(action, entityType));

    case exchangeActionType.ACCEPT_INCOMING_SUCCESS:
      return mergeEntities(state, getEntities(action, entityType));

    case exchangeActionType.ACCEPTED_BY_SUPERVISOR_SUCCESS:
      return mergeEntities(state, getEntities(action, entityType));

    case exchangeActionType.RETRACT_OUTGOING_SUCCESS:
      return removeEntity(state, payload);

    case exchangeActionType.DELETE_EXCHANGE_SUCCESS:
      return removeEntity(state, payload.id);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}
