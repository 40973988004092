import { Pipe, PipeTransform } from '@angular/core';
import { TreeviewItem } from '../shared/ngx-treeview/treeview-item';

@Pipe({
  name: 'multiselect',
  pure: true,
})
export class MultiselectPipe implements PipeTransform {
  transform(objects: any[], textField: string, valueField: string): TreeviewItem[] {
    return objects.map(
      (object) => new TreeviewItem({ text: object[textField], value: object[valueField], checked: false })
    );
  }
}
