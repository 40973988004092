import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { mergeEntities, resetEntities, updateEntitiesByField, updateEntitiesById } from '../orm';
import { contractTypeActionType } from './contract-type.action';
import { ContractTypeState } from './contract-type.model';

const entityType = 'contractTypes';

const initialState: ContractTypeState = {
  items: [],
  itemsById: {},
};

export function ContractTypeReducer(state: ContractTypeState = initialState, action: Action): ContractTypeState {
  let payload = action.payload;

  switch (action.type) {
    case contractTypeActionType.LOAD_SUCCESS:
      let entities = getEntities(action, 'contractTypes');
      return resetEntities(state, entities);
    case contractTypeActionType.ACTIVATE:
    case contractTypeActionType.DEACTIVATE:
      return updateEntitiesById(state, { loading: true }, payload);
    case contractTypeActionType.ACTIVATE_SUCCESS:
      return updateEntitiesByField(state, { deleted: false, deleted_date: null, loading: false }, 'id', payload);
    case contractTypeActionType.DEACTIVATE_SUCCESS:
      return updateEntitiesByField(state, { deleted: true, deleted_date: new Date(), loading: false }, 'id', payload);
    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, 'contractTypes');
        return mergeEntities(state, entities);
      }

      return state;
  }
}
