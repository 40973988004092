import filter from 'lodash-es/filter';
import isArray from 'lodash-es/isArray';
import mapValues from 'lodash-es/mapValues';

import { format } from '../../../shared/date.helper';
import { UnsafeAction as Action } from '../../interfaces';
import { containsEntity, getEntities, getResultEntity } from '../../shared/entity.helper';
import { mergeEntities, removeEntities, removeEntity, replaceEntity, updateEntitiesById } from '../orm';
import { scheduleActionType } from '../schedule/schedule.action';
import { AbsenceActionType } from './absence.action';
import { absenceInPeriod } from './absence.helper';
import { AbsenceLoadRequest, AbsenceModel, AbsenceState, LoadAbsenceSuccessAction } from './absence.model';
import { mapAbsenteeDays } from './absence.service';

const entityType = 'absence';

const initialState: AbsenceState = {
  items: [],
  itemsById: {},
};

export function AbsenceReducer(
  state: AbsenceState = initialState,
  action: Action | LoadAbsenceSuccessAction,
): AbsenceState {
  const payload = action.payload;

  switch (action.type) {
    case scheduleActionType.LOAD_SCHEDULE_DATASET_SUCCESS:
    case AbsenceActionType.LOAD_SUCCESS: {
      return handleLoadSuccess(state, action);
    }

    case AbsenceActionType.REMOVE: {
      return updateEntitiesById(state, { deleted: true }, payload.id);
    }

    case AbsenceActionType.REMOVE_FAILED: {
      return updateEntitiesById(state, { deleted: false }, payload.id);
    }

    case AbsenceActionType.REMOVE_SUCCESS: {
      return removeEntity(state, payload);
    }

    case AbsenceActionType.UPDATE: {
      return updateEntitiesById(state, { saving: true }, payload.id);
    }

    case AbsenceActionType.UPDATE_FAILED: {
      return updateEntitiesById(state, { saving: false }, payload.id);
    }

    case AbsenceActionType.UPDATE_SUCCESS: {
      const entity = {
        ...mapAbsenteeDays(getResultEntity(action, entityType)),
        saving: false,
      };

      return replaceEntity(state, entity);
    }

    case AbsenceActionType.ADD_SUCCESS:
    case AbsenceActionType.FETCH_SUCCESS: {
      const entities = getEntities(action, entityType);

      const mappedEntities = mapValues(entities, (absence) => mapAbsenteeDays(absence));

      return mergeEntities(state, mappedEntities);
    }

    default:
      if (containsEntity(action, entityType)) {
        const entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}

function handleLoadSuccess(state: AbsenceState, action: Action | LoadAbsenceSuccessAction) {
  const requestData = (action.requestData || {}) as AbsenceLoadRequest;
  const today = format(new Date(), 'yyyy-MM-dd');
  const minDate = requestData.minDate || today;
  const maxDate = requestData.maxDate || today;
  const inPeriod = absenceInPeriod(minDate, maxDate);

  const filterFn = (absence: AbsenceModel) => {
    //only remove existing absence if it's not in the payload
    if (action.payload.result && isArray(action.payload.result) && action.payload.result.includes(absence.id)) {
      return false;
    }

    //only filter the absence for the given user
    if (requestData.userId && absence.user_id !== requestData.userId) {
      return false;
    }

    if (requestData.status && absence.status !== requestData.status) {
      return false;
    }

    if (requestData.only_open_ended && !absence.open_ended) {
      return false;
    }

    return inPeriod(absence);
  };

  const removeIds = filter(state.itemsById, filterFn).map((absence: AbsenceModel) => absence.id);
  const entities = getEntities(action, entityType);

  const mappedEntities = mapValues(entities, (absence) => mapAbsenteeDays(absence));

  return mergeEntities(removeEntities(state, removeIds), mappedEntities);
}
