<label class="sb-radio-label">{{ label }}</label>
<div class="sb-radio-group">
  <p-radioButton
    [label]="item.value"
    *ngFor="let item of items"
    [value]="item.key"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
  ></p-radioButton>
</div>
