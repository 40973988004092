import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../../../reducers/index';
import { ExchangeService, getExchange } from '../../../reducers/orm/exchange/exchange.service';
import { ScheduleModel } from '../../../reducers/orm/schedule/schedule.model';
import { getEnhancedSchedule } from '../../../reducers/orm/schedule/schedule.service';

@Injectable()
export class ScheduleExchangeLoadGuard {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private exchangeService: ExchangeService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.hasScheduleInStore(route.params['id']).pipe(
      tap((hasItem) => {
        if (!hasItem) {
          this.router.navigate(['/404']);
        }
      }),
    );
  }

  private hasScheduleInStore(itemId: string): Observable<boolean> {
    return this.waitForStore(itemId).pipe(
      switchMap((schedule) => {
        if (!schedule.Exchange) {
          return observableOf(true);
        }

        //check for Exchange in store
        return this.hasExchangeInStore(schedule.Exchange);
      }),
    );
  }

  private waitForStore(itemId: string): Observable<ScheduleModel> {
    return this.store.select(getEnhancedSchedule(itemId)).pipe(
      filter((entity) => !!entity),
      take(1),
    );
  }

  private hasExchangeInStore(exchangeId): Observable<boolean> {
    return this.store.select(getExchange(exchangeId)).pipe(
      switchMap((exchange) => {
        if (exchange.User) {
          return observableOf(true);
        }

        return this.hasExchangeInApi(exchange.id);
      }),
    );
  }

  private hasExchangeInApi(exchangeId: string): Observable<boolean> {
    return this.exchangeService.fetch(exchangeId).pipe(
      map((response) => !!response),
      catchError((err) => observableOf(false)),
    );
  }
}
