import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import filter from 'lodash-es/filter';
import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import u from 'updeep';

import { AppState } from '../../index';
import { mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { BreakRuleAction } from './break-rule.action';
import { BreakRuleApi } from './break-rule.api';
import { BreakRuleModel, BreakRuleState } from './break-rule.model';

@Injectable()
export class BreakRuleService {
  constructor(
    private store: Store<AppState>,
    private api: BreakRuleApi,
  ) {}

  load() {
    this.store.dispatch(BreakRuleAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(BreakRuleAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(BreakRuleAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  add(breakRuleData): Observable<any> {
    this.store.dispatch(BreakRuleAction.add(breakRuleData));

    return this.api.add(breakRuleData).pipe(
      map((response) => {
        this.store.dispatch(BreakRuleAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(BreakRuleAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  batch(breakRuleData): Observable<any> {
    this.store.dispatch(BreakRuleAction.batch(breakRuleData));

    return this.api.batch(breakRuleData).pipe(
      map((response) => {
        this.store.dispatch(
          BreakRuleAction.batchSuccess(
            response,
            breakRuleData.map((b) => b.id),
          ),
        );
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(BreakRuleAction.batchFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  update(id, breakRuleData) {
    this.store.dispatch(BreakRuleAction.update(breakRuleData));

    return this.api.update(id, breakRuleData).pipe(
      map((response) => {
        this.store.dispatch(BreakRuleAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(BreakRuleAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  fetch(id) {
    this.store.dispatch(BreakRuleAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(BreakRuleAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(BreakRuleAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  remove(id) {
    this.store.dispatch(BreakRuleAction.removeSuccess(id));
  }

  save(breakRuleData) {
    if (breakRuleData.id) {
      return this.update(breakRuleData.id, breakRuleData);
    }

    breakRuleData = u.omit('id', breakRuleData);
    return this.add(breakRuleData);
  }
}

export const sortBreakRules = (breakRules: BreakRuleModel[]): BreakRuleModel[] => sortBy(breakRules, ['min_time']);
export const mapAndSortBreakRules = mapAndSortEntities(sortBreakRules);

export const getBreakRuleState = (appState: AppState): BreakRuleState => appState.orm.breakRules;

export const getBreakRuleIds = compose((state) => state.items, getBreakRuleState);
export const getBreakRuleEntities = createSelector(getBreakRuleState, (state) => state.itemsById);
export const getBreakRules = createSelector(getBreakRuleIds, getBreakRuleEntities, mapAndSortBreakRules);
export const getBreakRulesLoadStatus = createSelector(getBreakRuleState, (state) => state.loaded);

export const groupBreakRulesByDepartment = (rules) => groupBy(rules, 'department_id');
export const getBreakRulesByDepartment = createSelector(getBreakRules, groupBreakRulesByDepartment);

export const getBreakRulesForDepartment = (departmentId) =>
  createSelector(getBreakRules, (entities: BreakRuleModel[]) =>
    filter(entities, (entity) => entity.department_id === departmentId),
  );

export const getBreakRule = (id: string) => createSelector(getBreakRuleEntities, (entities) => mapEntity(id, entities));
