import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, fromEvent as observableFromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { HorizontalScrollService } from './horizontal-scroll.service';

@Component({
  selector: 'horizontal-scrollbar',
  template: `
    <input
      *ngIf="show"
      #range
      type="range"
      class="horizontal-scrollbar"
      [step]="1"
      [min]="0"
      [max]="maxValue"
      [ngClass]="thumbWidthClass"
      [(ngModel)]="position"
      (ngModelChange)="scrollTo()"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalScrollbarComponent implements OnInit, OnDestroy {
  public show = false;
  public position = 0;

  public readonly steps = 20;
  private step: number;
  public maxValue: number;

  private wheelSubscription: Subscription;

  private dataSubs = new Subscription();

  // On some pages the scrollbar is rendered through the content.
  // By putting it on the `off-left` mode the scrollbar will be
  // rendered a couple pixels to the left where it normally is rendered.
  @Input()
  public mode: 'normal' | 'off-left' = 'normal';

  public constructor(
    private horizontalScrollService: HorizontalScrollService,
    private zone: NgZone,
    private cd: ChangeDetectorRef,
  ) {
    this.cd.detach();
  }

  public ngOnInit() {
    this.horizontalScrollService.reset(this);

    this.handleWheelEvent();

    this.zone.runOutsideAngular(() => {
      this.dataSubs.add(
        fromEvent(window, 'resize')
          .pipe(debounceTime(25))
          .subscribe(() => {
            this.horizontalScrollService.determineScrollbarWidth();
          }),
      );

      this.dataSubs.add(
        fromEvent(document, 'keydown').subscribe((event: KeyboardEvent) => {
          if (event.keyCode === 37) {
            // Left arrow
            this.horizontalScrollService.scrollLeft();
          } else if (event.keyCode === 39) {
            // Right arrow
            this.horizontalScrollService.scrollRight();
          }
        }),
      );
    });
    this.horizontalScrollService.determineScrollbarWidth();
  }

  public ngOnDestroy() {
    this.horizontalScrollService.reset(undefined);
    this.wheelSubscription.unsubscribe();
    this.dataSubs.unsubscribe();
  }

  public handleWheelEvent() {
    this.zone.runOutsideAngular(() => {
      this.wheelSubscription = observableFromEvent(document, 'wheel', { passive: true })
        .pipe(debounceTime(30))
        .subscribe((event: WheelEvent) => {
          const x = event.deltaX;
          if (x > 0) {
            this.horizontalScrollService.scrollRight(x);
          } else if (x < 0) {
            this.horizontalScrollService.scrollLeft(x * -1);
          }
        });
    });
  }

  public setPosition(position: number) {
    this.position = position;
    this.cd.detectChanges();
  }

  public scrollTo() {
    this.horizontalScrollService.scrollTriggered(this.position);
  }

  public setMaxValue(value) {
    this.maxValue = value;
    this.setThumbWidth(value);
    this.cd.detectChanges();
  }

  public setVisiblity(visible: boolean) {
    this.show = visible;
    this.cd.detectChanges();
  }

  private setThumbWidth(value: number) {
    this.step = Math.round((this.steps / value) * 100 + 5);
    if (this.step > this.steps) {
      this.step = 18;
    }
  }

  public get thumbWidthClass() {
    const step = Math.max(1, this.step);

    return `horizontal-scrollbar--${step} horizontal-scrollbar--${this.mode}`;
  }
}
