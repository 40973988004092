import { Pipe, PipeTransform } from '@angular/core';

import { calculateTimelineHeight, HeightOptions } from '../+authenticated/+schedule/shared/timeline.service';

@Pipe({
  name: 'calculateHeight',
  pure: true,
})
export class CalculateHeightPipe implements PipeTransform {
  public transform(maxPosition: number, heightOptions: HeightOptions): number {
    return calculateTimelineHeight(maxPosition, heightOptions);
  }
}
