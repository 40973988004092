import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  Observable,
  concat as observableConcat,
  interval as observableInterval,
  of as observableOf,
  Subscription,
} from 'rxjs';
import { auditTime, delay, filter, repeat, scan, switchMapTo, take, takeUntil } from 'rxjs/operators';

import { ApiGateway } from '../api/ApiGateway.service';

@Component({
  selector: 'loading-bar',
  template: `
    <div id="loading-bar-spinner" #loadingBarSpinner>
      <div class="spinner-icon"></div>
    </div>
    <div id="loading-bar" #loadingBarContainer>
      <div class="bar bg-primary-500" #loadingBar>
        <div class="peg"></div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingBarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('loadingBarSpinner', { static: true })
  public _spinner: any;
  @ViewChild('loadingBarContainer', { static: true })
  public _loadingBarContainer: any;
  @ViewChild('loadingBar', { static: true })
  public _loadingBar: any;

  @Input()
  public includeSpinner = false;

  private pendingRequests: Observable<number>;
  private subscription: Subscription;

  public constructor(
    private _renderer: Renderer2,
    private gateway: ApiGateway,
    private cdr: ChangeDetectorRef,
  ) {
    this.pendingRequests = gateway.getPendingGetRequestCounter();
  }

  public ngAfterViewInit() {
    this.cdr.detach();

    this.hide(this._loadingBarContainer);
    this.hide(this._spinner);

    //start loadingbar after a request
    const startEvent = this.pendingRequests.pipe(
      filter(function (value) {
        return value > 0;
      }),
      take(1),
    );

    const stopEvent = this.pendingRequests.pipe(
      auditTime(200),
      filter((value) => value === 0),
    );

    const stop = observableOf('stop').pipe(delay(500));

    const incrementer = observableInterval(250).pipe(
      scan((acc) => acc + this.inc(acc), 0.02),
      takeUntil(stopEvent),
    );

    const sequence = observableConcat(observableOf('start'), incrementer, observableOf(1), stop);

    this.subscription = startEvent.pipe(switchMapTo(sequence), repeat()).subscribe((status) => {
      if (status === 'start') {
        this.start();
        return;
      }

      if (status === 'stop') {
        this.stop();
        return;
      }

      this.set(status);
    });
  }

  /**
   * Inserts the loading bar element into the dom, and sets it to 2%
   */
  private start(): void {
    this.show(this._loadingBarContainer);

    if (this.includeSpinner) {
      this.show(this._spinner);
    }

    this.set(0.02);
  }

  private stop(): void {
    this.hide(this._loadingBarContainer);
    this.hide(this._spinner);
  }

  /**
   * Set the loading bar's width to a certain percent.
   *
   * @param n any value between 0 and 1
   */
  private set(n): void {
    const pct = n * 100 + '%';
    this.setElementStyle(this._loadingBar, 'width', pct);
  }

  /**
   * Increments the loading bar by a random amount
   * but slows down as it progresses
   */
  private inc(status): number {
    if (status >= 1) {
      return 0;
    }

    let rnd = 0;

    if (status >= 0 && status < 0.25) {
      // Start out between 3 - 6% increments
      rnd = (Math.random() * (5 - 3 + 1) + 3) / 100;
    } else if (status >= 0.25 && status < 0.65) {
      // increment between 0 - 3%
      rnd = (Math.random() * 3) / 100;
    } else if (status >= 0.65 && status < 0.9) {
      // increment between 0 - 2%
      rnd = (Math.random() * 2) / 100;
    } else if (status >= 0.9 && status < 0.99) {
      // finally, increment it .5 %
      rnd = 0.005;
    } else {
      // after 99%, don't increment:
      rnd = 0;
    }

    return rnd;
  }

  private show(el: any): void {
    this.setElementStyle(el, 'display', 'block');
    this.cdr.detectChanges();
  }

  private hide(el: any): void {
    this.setElementStyle(el, 'display', 'none');
    this.cdr.detectChanges();
  }

  private setElementStyle(el: any, styleName: string, styleValue: string): void {
    this._renderer.setStyle(el.nativeElement, styleName, styleValue);
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
