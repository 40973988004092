import { UnsafeAction as Action } from '../../interfaces';
import { initialState, reportModelEntityAdapter, ReportState } from './report.state';
import { reportActionType } from './report.action';

export function ReportReducer(state: ReportState = initialState, action: Action) {
  const payload = action.payload;
  switch (action.type) {
    case reportActionType.LOAD:
    case reportActionType.ADD:
    case reportActionType.UPSERT:
      return { ...state, isLoading: true, error: null };
    case reportActionType.UPDATE:
    case reportActionType.UPDATE_SUCCESS:
    case reportActionType.UPDATE_FAILED:
      return reportModelEntityAdapter.updateOne(payload, { ...state, isLoading: false, error: null });
    case reportActionType.ADD_SUCCESS:
      return reportModelEntityAdapter.addOne(payload, {
        ...state,
        isLoading: false,
        error: null,
      });
    case reportActionType.UPSERT_SUCCESS:
      return reportModelEntityAdapter.upsertMany(payload, {
        ...state,
        isLoading: false,
        error: null,
      });
    case reportActionType.LOAD_SUCCESS:
      return reportModelEntityAdapter.setAll(payload, {
        ...state,
        isLoading: false,
        error: null,
      });
    case reportActionType.LOAD_FAILED:
    case reportActionType.ADD_FAILED:
    case reportActionType.UPSERT_FAILED:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
