import { UnsafeAction as Action } from '../../interfaces';
import { TeamDayState } from './team-day.model';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { mergeEntities, removeEntity } from '../orm';
import { teamDayActionType } from './team-day.action';
import { scheduleActionType } from '../schedule/schedule.action';

const entityType = 'teamDays';

const initialState: TeamDayState = {
  items: [],
  itemsById: {},
  loading: false,
};

export function TeamDayReducer(state: TeamDayState = initialState, action: Action): TeamDayState {
  const payload = action.payload;

  switch (action.type) {
    case teamDayActionType.LOAD: {
      return {
        ...state,
        loading: true,
      };
    }

    case teamDayActionType.LOAD_FAILED:
      return {
        ...state,
        loading: false,
      };

    case scheduleActionType.LOAD_SCHEDULE_DATASET_SUCCESS:
    case teamDayActionType.LOAD_SUCCESS:
      return {
        ...mergeEntities(state, getEntities(action, entityType)),
        loading: false,
      };

    case teamDayActionType.REMOVE:
      return removeEntity(state, payload);

    default:
      if (containsEntity(action, entityType)) {
        const entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}
