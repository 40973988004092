import { fromEvent as observableFromEvent, Observable, Subject } from 'rxjs';

import { share } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';

@Injectable()
export class ElementInViewportService {
  private scroll$: Observable<any>;
  private viewChangesOutsideScope = new Subject();

  constructor(private zone: NgZone) {
    this.zone.runOutsideAngular(() => {
      this.scroll$ = observableFromEvent(window, 'scroll', { passive: true }).pipe(share());
    });
  }

  public triggerOutsideScopeChanges() {
    this.viewChangesOutsideScope.next(null);
  }

  public getOutSideScopeSubject() {
    return this.viewChangesOutsideScope;
  }
}
