import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasOwn',
  standalone: true,
})
export class HasOwnPipe implements PipeTransform {
  transform(value: object, prop: string): boolean {
    return Object.hasOwn ? Object.hasOwn(value, prop) : value.hasOwnProperty(prop);
  }
}
