import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'default',
  pure: true,
})
export class DefaultPipe implements PipeTransform {
  transform(value: any, defaultValue: string = '-'): string {
    if (typeof value === 'string') {
      value = value.trim();
    }

    if (!!value) {
      return value;
    }

    return defaultValue;
  }
}
