import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { AppState } from '../../index';
import { AbsenceService, getEnhancedAbsenceById } from './absence.service';

@Injectable()
export class AbsenceExistsGuard {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private absenceService: AbsenceService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.hasItem(route.params['id']).pipe(
      tap((hasItem) => {
        if (!hasItem) {
          this.router.navigate(['/404']);
        }
      }),
    );
  }

  private hasItem(itemId: string): Observable<boolean> {
    return this.hasItemInApi(itemId);
  }

  private hasItemInStore(itemId: string): Observable<boolean> {
    return this.store.select(getEnhancedAbsenceById(itemId)).pipe(
      map((entity) => !!entity),
      take(1),
    );
  }

  private hasItemInApi(itemId: string): Observable<boolean> {
    return this.absenceService.fetch(itemId).pipe(
      map((response) => !!response),
      catchError((err) => {
        this.router.navigate(['/dashboard/my-absence']);
        return observableOf(false);
      }),
    );
  }
}
