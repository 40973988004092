import { UnsafeAction as Action } from '../../interfaces';
import { NewsItemModel, NewsItemState } from './news-item.model';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { getEntity, mergeEntities, removeEntity, resetEntities, updateEntitiesById } from '../orm';
import { newsItemActionType } from './news-item.action';
import { commentActionType } from '../comment/comment.action';

const entityType = 'newsItem';

const initialState: NewsItemState = {
  items: [],
  itemsById: {},
  loading: false,
  currentPage: 0,
  pageCount: 1,
};

export function NewsItemReducer(state: NewsItemState = initialState, action: Action): NewsItemState {
  const payload = action.payload;

  switch (action.type) {
    case commentActionType.ADD_SUCCESS:
    case commentActionType.UPDATE_SUCCESS:
    case commentActionType.REMOVE_SUCCESS:
    case newsItemActionType.REMOVE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        itemsById: {
          ...state.itemsById,
          [payload.newsItemId]: SingleNewsItemReducer(state.itemsById[payload.newsItemId], action),
        },
      };

    case newsItemActionType.LOAD:
    case newsItemActionType.GET: {
      return {
        ...state,
        loading: true,
      };
    }

    case newsItemActionType.LOAD_FAILED:
    case newsItemActionType.GET_FAILED:
      return {
        ...state,
        loading: false,
        currentPage: 0,
        pageCount: 1,
      };

    case newsItemActionType.LOAD_SUCCESS:
      return {
        ...resetEntities(state, getEntities(action, entityType)),
        loading: false,
      };

    case newsItemActionType.GET_SUCCESS:
      return {
        ...mergeEntities(state, getEntities(action, entityType)),
        loading: false,
        currentPage: payload.meta && payload.meta.page ? parseInt(payload.meta.page, 10) : initialState.currentPage,
        pageCount: payload.meta && payload.meta.pages ? parseInt(payload.meta.pages, 10) : initialState.pageCount,
      };

    case newsItemActionType.REMOVE: {
      return updateEntitiesById(state, { deleted: true }, payload);
    }

    case newsItemActionType.REMOVE_FAILED: {
      return updateEntitiesById(state, { deleted: false }, payload.id);
    }

    case newsItemActionType.REMOVE_SUCCESS: {
      return removeEntity(state, payload);
    }

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}

const addComment = (action: Action, newsItem: NewsItemModel) => {
  const commentId = action.payload.response.result;
  const comment = action.payload.response.entities.comment[commentId];

  return {
    ...newsItem,
    Comment: [...(newsItem.Comment || []), comment],
  };
};

const updateComment = (action: Action, newsItem: NewsItemModel) => {
  const commentId = action.payload.response.result;
  const updatedComment = action.payload.response.entities.comment[commentId];

  const updatedComments = newsItem.Comment.map((checkComment) => {
    if (commentId !== checkComment.id) {
      return checkComment;
    }

    return updatedComment;
  });

  return {
    ...newsItem,
    Comment: updatedComments,
  };
};

const removeComment = (action: Action, newsItem: NewsItemModel) => {
  const commentId = action.payload.commentId;

  const updatedComments = newsItem.Comment.filter((checkComment) => commentId !== checkComment.id);

  return {
    ...newsItem,
    Comment: updatedComments,
  };
};

const removeAttachment = (action: Action, newsItem: NewsItemModel) => {
  const attachmentID = action.payload.attachmentId;

  const updatedAttachments = newsItem.Attachment.filter((checkAttachment) => attachmentID !== checkAttachment.id);

  return {
    ...newsItem,
    Attachment: updatedAttachments,
  };
};

function SingleNewsItemReducer(newsItem: NewsItemModel, action: Action) {
  switch (action.type) {
    default:
      return newsItem;

    case commentActionType.ADD_SUCCESS:
      return addComment(action, newsItem);

    case commentActionType.UPDATE_SUCCESS:
      return updateComment(action, newsItem);

    case commentActionType.REMOVE_SUCCESS:
      return removeComment(action, newsItem);

    case newsItemActionType.REMOVE_ATTACHMENT_SUCCESS:
      return removeAttachment(action, newsItem);
  }
}
