import { Pipe, PipeTransform } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { toDate, isValid } from 'date-fns';
import { format } from '../shared/date.helper';
import { EmployeeModel } from '../reducers/orm/employee/employee.model';
import { calculateTimelineHeight } from '../+authenticated/+schedule/shared/timeline.service';

const CELL_HEIGHT = 36;

@Pipe({
  name: 'calculateTimesheetHeight',
  pure: true,
})
export class CalculateTimesheetHeightPipe implements PipeTransform {
  transform(employeeData, defaultValue: number = CELL_HEIGHT): number {
    return employeeData.days.reduce((acc, day) => Math.max(acc, day.rows.length * CELL_HEIGHT), CELL_HEIGHT);
  }
}
