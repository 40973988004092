import { UnsafeAction as Action } from '../interfaces';
import intersection from 'lodash-es/intersection';
import { toggleListItem } from '../shared/immutable.helper';
import { authActionType } from '../auth/auth.action';
import { selectedDepartmentsActionType } from './selected-departments.action';
import reduce from 'lodash-es/reduce';

const initialState: string[] = [];

const updateSelectedDepartments = (state: string[], departmentIds: string[]) => {
  if (!state || state.length === 0) {
    return departmentIds;
  }

  const selectedDepartmentsWithinDepartmentIds = intersection(state, departmentIds);

  if (selectedDepartmentsWithinDepartmentIds.length === 0) {
    return departmentIds;
  }

  return selectedDepartmentsWithinDepartmentIds;
};

export function SelectedDepartmentsReducer(state: string[] = initialState, action: Action): string[] {
  const payload = action.payload;

  switch (action.type) {
    case authActionType.REFRESH_SUCCESS:
    case authActionType.LOAD_SUCCESS:
      return updateSelectedDepartments(state, Object.keys(payload.departments));

    case authActionType.LOGIN_SUCCESS:
    case authActionType.VERIFY_SIGN_UP_SUCCESS:
    case authActionType.SIGN_UP_NO_VERIFY_SUCCESS:
      return [];

    case selectedDepartmentsActionType.TOGGLE_DEPARTMENT:
      return toggleListItem(state, payload);

    case selectedDepartmentsActionType.TOGGLE_DEPARTMENTS:
      return reduce(payload, (acc, item) => toggleListItem(acc, item), state);

    case selectedDepartmentsActionType.LOAD_DEPARTMENTS:
      return payload;
    case selectedDepartmentsActionType.SELECT_DEPARTMENT:
      return [payload];

    case selectedDepartmentsActionType.SELECT_DEPARTMENTS:
      return payload;

    default:
      return state;
  }
}
