<div class="dropdown d-inline-block">
  <button
    (click)="isOpen = !isOpen"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    class="flex h-[34px] items-center border-grey-200 text-word-mark-800"
    [ngClass]="{
      'btn btn-default btn-large': true
    }"
  >
    <icon *ngIf="icon !== undefined" [svgIcon]="icon"></icon>&nbsp; {{ buttonText | translate }}&nbsp;
    <icon class="ml-[10px]" svgIcon="chevron-down-solid"></icon>
  </button>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    (backdropClick)="isOpen = !isOpen"
  >
    <div class="column-dropdown-container">
      <div class="sb-radio-container">
        <div *ngIf="form.get('groupByLocation').enabled">
          <sb-radio-selector
            [formControl]="form.get('groupByLocation')"
            [label]="'Location' | translate"
            [items]="reportFilters?.groupByLocation?.options"
          ></sb-radio-selector>
        </div>

        <div *ngIf="form.get('groupByLocationWithTeam').enabled">
          <sb-radio-selector
            [formControl]="form.get('groupByLocationWithTeam')"
            [label]="'Location' | translate"
            [items]="reportFilters?.groupByLocationWithTeam?.options"
          ></sb-radio-selector>
        </div>

        <div *ngIf="form.get('groupByPeriod').enabled">
          <sb-radio-selector
            [formControl]="form.get('groupByPeriod')"
            [label]="'Period' | translate"
            [items]="reportFilters?.groupByPeriod?.options"
          ></sb-radio-selector>
        </div>

        <div *ngIf="form.get('groupDayStats').enabled">
          <sb-radio-selector
            [formControl]="form.get('groupDayStats')"
            [label]="'Period' | translate"
            [items]="reportFilters?.groupDayStats?.options"
          ></sb-radio-selector>
        </div>
      </div>
    </div>
  </ng-template>
</div>
