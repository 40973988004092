import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { clsx } from 'clsx';
import { differenceInMilliseconds } from 'date-fns';
import { fromEvent, Subscription } from 'rxjs';

import { ElementInViewportService } from '../../../shared/element-in-viewport.service';
import { FixedHeaderDirective } from '../../../shared/fixed/fixed-header.directive';
import { SBHeaderService } from '../sb-header/sb-header.service';

const dontCloseSidebarClass = 'js-dont-close-sidebar';

@Component({
  selector: 'sidebar',
  standalone: true,
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnChanges, OnDestroy {
  private static activeSidebar: SidebarComponent = null;

  @HostBinding('class')
  public get class() {
    // eslint-disable-next-line tailwindcss/no-custom-classname
    return clsx('bg-grey-25', this.opened && 'shadow-xl', this.position === 'start' && 'left ');
  }

  @Input()
  @HostBinding('class.opened')
  public opened = false;

  @Input()
  public position: 'start' | 'end' = 'end';

  @Input()
  public allowMultipleOpen = false;

  private timeOfOpening;
  private dataSubs = new Subscription();

  private static setActiveSidebar(sidebar: SidebarComponent) {
    if (SidebarComponent.activeSidebar) {
      SidebarComponent.activeSidebar.close();
    }
    SidebarComponent.activeSidebar = sidebar;
  }

  public constructor(
    private _elementRef: ElementRef,
    private zone: NgZone,
    private cd: ChangeDetectorRef,
    private elementInViewportService: ElementInViewportService,
    private headerService: SBHeaderService,
  ) {
    this.cd.detach();
    this.zone.runOutsideAngular(() => {
      this.dataSubs.add(
        fromEvent(document, 'click').subscribe((event) => {
          if (this.opened) {
            // When a user clicks outside the sidebar 2 functions are called:
            // First the this.open() function is called, resulting in this.opened to be true
            // Second this onClick() function is called, which will close this sidebar immediately
            // Since this is not the desired effect, this.timeOfOpening is introduced.
            // When this property is greater than 200, we know that the user intends to close the
            // sidebar, and thus the sidebar is closed.
            const diff = differenceInMilliseconds(new Date(), this.timeOfOpening);

            // The node.contains method checks whether the element the user clicks on
            // is contained in the greater sidebar element (referenced by this._elementRef).
            // This function, however, fails when there are many subcomponents inside the
            // sidebar. Therefore, the 'js-dont-close-sidebar' class was introduced. If the element
            // the user clicks on OR one of its parents contains this class, the sidebar
            // must NOT be closed. The user is clicking in the sidebar itself and so the
            // sidebar must remain closed.
            if (
              !this.allowMultipleOpen &&
              diff > 200 &&
              !this.shouldIgnoreClick(this._elementRef.nativeElement, event.target)
            ) {
              this.zone.run(() => {
                this.close();
              });
            }
          }
        }),
      );
    });
  }

  public ngOnChanges(): void {
    this.cd.detectChanges();
  }

  private shouldIgnoreClick(parent, child) {
    if (parent.contains(child)) {
      return true;
    } else {
      let node = child;
      while (node !== null) {
        if (node.classList && node.classList.contains(dontCloseSidebarClass)) {
          return true;
        } else {
          node = node.parentNode;
        }
      }
      return false;
    }
  }

  public close() {
    if (!this.allowMultipleOpen) {
      if (SidebarComponent.activeSidebar === this) {
        SidebarComponent.activeSidebar = null;
      }
    }
    this.opened = false;

    this.cd.markForCheck();
    this.elementInViewportService.triggerOutsideScopeChanges();
  }

  public open() {
    this.timeOfOpening = new Date();

    if (!this.allowMultipleOpen) {
      SidebarComponent.setActiveSidebar(this);
    }
    this.opened = true;

    FixedHeaderDirective.forceScroll();
    this.cd.detectChanges();
    this.elementInViewportService.triggerOutsideScopeChanges();
  }

  public toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }

    this.cd.detectChanges();
    this.elementInViewportService.triggerOutsideScopeChanges();
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }
}
