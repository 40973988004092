import { UnsafeAction as Action } from '../../interfaces';
import { RateCardState } from './rate-card.model';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { getEntity, mergeEntities, removeEntity, resetEntities } from '../orm';
import { rateCardActionType } from './rate-card.action';
import { format } from '../../../shared/date.helper';

const entityType = 'rateCards';

const initialState: RateCardState = {
  items: [],
  itemsById: {},
};

export function RateCardReducer(state: RateCardState = initialState, action: Action): RateCardState {
  let payload = action.payload;

  switch (action.type) {
    case rateCardActionType.LOAD_SUCCESS:
      let entities = getEntities(action, entityType);
      return resetEntities(state, entities);

    case rateCardActionType.UPDATE_SUCCESS:
      const createdEntities = payload['rateCardResponse']['entities'][entityType];
      const originalEntity = getEntity(state, payload.original);

      return mergeEntities(state, {
        ...createdEntities,
        [payload.original]: {
          ...originalEntity,
          deleted: true,
          deleted_date: format(new Date(), 'yyyy-MM-dd'),
        },
      });
    case rateCardActionType.REMOVE_SUCCESS:
      return removeEntity(state, payload);

    default:
      if (containsEntity(action, entityType)) {
        let entities = getEntities(action, entityType);
        return mergeEntities(state, entities);
      }

      return state;
  }
}
