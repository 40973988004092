import { UnsafeAction as Action } from '../../interfaces';
import { ReportFavoriteModel } from './report-favorite.model';

const ACTION_PREFIX = '[ReportFavorite]';

export const reportFavoriteActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  DELETE: `${ACTION_PREFIX} Delete`,
  DELETE_SUCCESS: `${ACTION_PREFIX} Delete Success`,
  DELETE_FAILED: `${ACTION_PREFIX} Delete Failed`,
};

export class ReportFavoriteAction {
  static load(): Action {
    return {
      type: reportFavoriteActionType.LOAD,
    };
  }

  static loadSuccess(data: ReportFavoriteModel[]): Action {
    return {
      type: reportFavoriteActionType.LOAD_SUCCESS,
      payload: data,
    };
  }

  static loadFailed(err): Action {
    return {
      type: reportFavoriteActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static delete(): Action {
    return {
      type: reportFavoriteActionType.DELETE,
    };
  }

  static deleteSuccess(id: string): Action {
    return {
      type: reportFavoriteActionType.DELETE_SUCCESS,
      payload: id,
    };
  }

  static deleteFailed(err): Action {
    return {
      type: reportFavoriteActionType.DELETE_FAILED,
      payload: err,
    };
  }
}
