import { UnsafeAction as Action } from '../../interfaces';
import {
  initialState,
  scheduleComplianceModelEntityAdapter,
  ScheduleComplianceState,
} from './schedule-compliance.state';
import { scheduleComplianceActionType } from './schedule-compliance.action';
import isArray from 'lodash-es/isArray';
import { scheduleNActionType } from '../../store/schedule/schedule.n.action';
import { ScheduleComplianceModel } from './schedule-compliance.model';

export function ScheduleComplianceReducer(
  state: ScheduleComplianceState = initialState,
  action: Action
): ScheduleComplianceState {
  const payload = action.payload;

  switch (action.type) {
    case scheduleNActionType.RESET: {
      return scheduleComplianceModelEntityAdapter.removeAll({ ...state, isLoading: false });
    }
    case scheduleComplianceActionType.LOAD:
      return { ...state, isLoading: true };
    case scheduleComplianceActionType.UPDATE:
      const compliances: ScheduleComplianceModel[] = payload.map((employeeId: string) => ({
          userId: employeeId,
          timestamp: action.timestamp,
          violations: {},
        }));
      return scheduleComplianceModelEntityAdapter.upsertMany(compliances, { ...state, isLoading: true });
    case scheduleComplianceActionType.LOAD_SUCCESS:
      if (!isArray(payload)) {
        return state;
      }
      return scheduleComplianceModelEntityAdapter.setAll(payload, { ...state, isLoading: false });
    case scheduleComplianceActionType.UPDATE_SUCCESS:
      if (!isArray(payload)) {
        return state;
      }

      const filteredCompliance: ScheduleComplianceModel[] = payload.filter((compliance: ScheduleComplianceModel) => {
        const existingCompliance = state.entities[compliance.userId];
        return !existingCompliance || existingCompliance?.timestamp === action.timestamp;
      });

      return scheduleComplianceModelEntityAdapter.upsertMany(filteredCompliance, { ...state, isLoading: false });
    case scheduleComplianceActionType.LOAD_FAILED:
    case scheduleComplianceActionType.UPDATE_FAILED:
      return { ...state, isLoading: false, error: payload.err };
    case scheduleComplianceActionType.RESET: {
      return scheduleComplianceModelEntityAdapter.removeAll({ ...state, isLoading: false });
    }
    default:
      return state;
  }
}
