import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ReportModel } from './report.model';

export interface ReportState extends EntityState<ReportModel> {
  isLoading?: boolean;
  error?: any;
}

export const reportModelEntityAdapter: EntityAdapter<ReportModel> = createEntityAdapter<ReportModel>({
  sortComparer: (a, b) => b.requested.localeCompare(a.requested),
});

export const initialState: ReportState = reportModelEntityAdapter.getInitialState({
  isLoading: false,
  error: null,
});
