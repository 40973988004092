import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';

import { userGuideUpdateRequest, userGuideUpdateSuccess } from './user-guide.action';
import { UserGuideApi } from './user-guide.api';

@Injectable()
export class UserGuideEffect {
  public updateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userGuideUpdateRequest),
      exhaustMap(({ userGuide }) =>
        this.api.track(userGuide).pipe(map((userGuide) => userGuideUpdateSuccess({ userGuide }))),
      ),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly api: UserGuideApi,
  ) {}
}
