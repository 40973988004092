import { UnsafeAction as Action } from '../interfaces';
import { MfaState } from './mfa.model';
import { mfaActionType } from './mfa.action';
import { authActionType } from '../auth/auth.action';
import { accountActionType } from '../account/account.action';

const initialState: MfaState = {
  mfa_backup_email: '',
  mfa_backup_code: '',
  mfa_enforced: false,
  mfa_logged_in: false,
  mfa_provider: null,
  mfa_promote: true,
};

function assignState(state, ...newState) {
  return Object.assign({}, state, ...newState);
}

export function MfaReducer(state: MfaState, action: Action): MfaState {
  const payload = action.payload;

  switch (action.type) {
    case authActionType.REFRESH_SUCCESS:
    case authActionType.LOAD_SUCCESS:
    case authActionType.RESET_PASSWORD_SUCCESS:
    case authActionType.VERIFY_SIGN_UP_SUCCESS:
    case authActionType.SIGN_UP_NO_VERIFY_SUCCESS:
    case authActionType.LOGIN_SUCCESS: {
      return {
        ...state,
        ...payload.MFAState,
      };
    }

    case authActionType.LOGOUT:
    case authActionType.CLEAR_SESSION:
    case authActionType.LOAD_FAILED:
      return initialState;

    case mfaActionType.ENABLE_SUCCESS:
      return {
        ...state,
        mfa_provider: payload,
        mfa_logged_in: true,
      };

    case mfaActionType.UPDATE_PROMOTE_SUCCESS:
      return {
        ...state,
        mfa_promote: false,
      };

    case mfaActionType.DISABLE_SUCCESS:
      return {
        ...state,
        mfa_provider: null,
        mfa_logged_in: false,
        mfa_backup_code: '',
        mfa_backup_email: '',
        mfa_promote: true,
      };

    case mfaActionType.LOGIN_WITH_OTP_SUCCESS:
    case mfaActionType.LOGIN_SUCCESS: {
      return {
        ...state,
        mfa_logged_in: true,
      };
    }

    case mfaActionType.VERIFY_BACKUP_CODE_SUCCESS: {
      return {
        ...state,
        mfa_provider: null,
      };
    }

    case mfaActionType.VERIFY_BACKUP_EMAIL_SUCCESS: {
      return {
        ...state,
        mfa_backup_email: payload.backup_email,
      };
    }

    case accountActionType.PATCH_SUCCEEDED: {
      return {
        ...state,
        mfa_enforced: payload.Account.enforce_mfa,
      };
    }

    default:
      return state;
  }
}
