import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UserGuideService } from '@reducers/orm/user-guide/user-guide.service';
import { combineLatest, forkJoin, forkJoin as observableForkJoin, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { PlanType, SubscriptionModel } from '../+authenticated/+reports/shared/subscriptions/subscription.model';
import { SubscriptionService } from '../+authenticated/+reports/shared/subscriptions/subscription.service';
import { AppState } from '../reducers';
import { AccountService, getAccountSubscription } from '../reducers/account/account.service';
import { PermissionState } from '../reducers/auth/auth.model';
import { getPermissionState, hasPermission } from '../reducers/auth/permission.helper';
import { AbsenteeOptionService } from '../reducers/orm/absentee-option/absentee-option.service';
import { ContractTypeService } from '../reducers/orm/contract-type/contract-type.service';
import { CustomFieldsService } from '../reducers/orm/custom-fields/custom-fields.service';
import { EmployeeService } from '../reducers/orm/employee/employee.service';
import { HolidayService } from '../reducers/orm/holiday/holiday.service';
import { IntegrationService } from '../reducers/orm/integration/integration.service';
import { KioskService } from '../reducers/orm/kiosk/kiosk.service';
import { getFirstDepartmentId, LocationService } from '../reducers/orm/location/location.service';
import { PermissionGroupService } from '../reducers/orm/permission-group/permission-group.service';
import { RateCardService } from '../reducers/orm/rate-card/rate-card.service';
import { TimeOffBalanceService } from '../reducers/orm/time-off-balance/time-off-balance.service';
import { PageFiltersService } from '../reducers/page-filters/page-filters.service';
import { SelectedDepartmentsAction } from '../reducers/selected-departments/selected-departments.action';
import { getSelectedDepartmentIds } from '../reducers/selected-departments/selected-departments.service';
import { hasAtleastSubscriptionPlan } from '../shared/subscription-plan/subscription-plan.directive';
import { SkillGroupService } from './../reducers/orm/skill-group/skill-group.service';
import { SkillService } from './../reducers/orm/skill/skill.service';
import { SurchargeService } from './../reducers/orm/surcharge/surcharge.service';
import { FeatureService } from './feature.service';

@Injectable()
export class DataStartup {
  public constructor(
    private accountService: AccountService,
    private locationService: LocationService,
    private employeeService: EmployeeService,
    private absenteeOptionService: AbsenteeOptionService,
    private surchargeService: SurchargeService,
    private rateCardService: RateCardService,
    private contractTypeService: ContractTypeService,
    private permissionGroupService: PermissionGroupService,
    private holidayService: HolidayService,
    private featureService: FeatureService,
    private skillService: SkillService,
    private skillGroupService: SkillGroupService,
    private pageFilterService: PageFiltersService,
    private subscriptionService: SubscriptionService,
    private integrationService: IntegrationService,
    private customFieldsService: CustomFieldsService,
    private timeOffBalanceService: TimeOffBalanceService,
    private kioskService: KioskService,
    private store: Store<AppState>,
    private userGuideService: UserGuideService,
  ) {}

  public requiredLoader() {
    return observableForkJoin(
      this.accountService.load(),
      this.subscriptionService.getSubscription(),
      this.pageFilterService.loadFilters(),
      this.locationService.load(),
      this.employeeService.load(),

      this.loadDataBasedOnPlanType(),
      this.loadBackgroundData(),
      this.loadOptionalApps(),
    ).pipe(
      tap(() => {
        // This is a special case which doesn't need to be unsubscribed
        // eslint-disable-next-line rxjs/no-ignored-subscription
        this.optionalLoader().subscribe();
      }),
      switchMap(() =>
        combineLatest(this.store.select(getSelectedDepartmentIds), this.store.select(getFirstDepartmentId)).pipe(
          take(1),
        ),
      ),
      map(([selectedDepartmentIds, departmentId]) => {
        if (selectedDepartmentIds.length === 0) {
          this.store.dispatch(SelectedDepartmentsAction.selectDepartment(departmentId));
        }

        return true;
      }),
      catchError((response) => throwError(response)),
    );
  }

  private optionalLoader() {
    return forkJoin([this.userGuideService.getUserGuides()]);
  }

  //Allowed to fail
  private loadOptionalApps() {
    return this.store.pipe(
      select(getPermissionState),
      filter((permissionState: PermissionState) => !!permissionState),
      take(1),
      switchMap((permissionState: PermissionState) => {
        if (
          !hasPermission(
            {
              permissions: ['Manage API tokens', 'Manage payroll apps', 'Manage non payroll apps'],
              userId: 'me',
              departments: 'any',
            },
            permissionState,
          )
        ) {
          return of(0);
        }
        return observableForkJoin([this.integrationService.loadApps()]).pipe(catchError((error) => of(0)));
      }),
    );
  }

  loadBackgroundData() {
    return observableForkJoin(
      this.absenteeOptionService.load(),
      this.surchargeService.load(),
      this.rateCardService.load(),
      this.contractTypeService.load(),
      this.permissionGroupService.load(),
      this.holidayService.load(),
    ).pipe(take(1));
  }

  private loadDataBasedOnPlanType() {
    return this.store.pipe(
      select(getAccountSubscription),
      filter((subscription: SubscriptionModel) => !!subscription),
      take(1),
      switchMap((subscription: SubscriptionModel) => {
        const apiCalls = [];
        if (hasAtleastSubscriptionPlan(PlanType.BASIC, subscription)) {
          apiCalls.push(this.timeOffBalanceService.load());
        }
        if (hasAtleastSubscriptionPlan(PlanType.EARLY_ADOPTER, subscription)) {
          apiCalls.push(this.skillService.load(), this.skillGroupService.load());
        }
        if (hasAtleastSubscriptionPlan(PlanType.PREMIUM, subscription)) {
          apiCalls.push(this.customFieldsService.load());
        }

        if (apiCalls.length === 0) {
          return of(0);
        }
        return observableForkJoin(apiCalls).pipe(catchError((err) => of(0)));
      }),
    );
  }
}
